import { createReducer } from '@reduxjs/toolkit';
import { FeesSchedule } from '@qwealth/qdata';
import { putAllFeesSchedules, putSchedule } from '../actions/feesSchedules';

interface IFeesSchedulesState {
  initialized: boolean;
  feesScheduleList: Array<FeesSchedule>;
}

const initialState: IFeesSchedulesState = {
  initialized: false,
  feesScheduleList: [],
};

export default createReducer(initialState, builder => {
  builder.addCase(putAllFeesSchedules, (state, action) => ({
    ...state,
    initialized: true,
    feesScheduleList: action.payload
  }));

  builder.addCase(putSchedule, (state, action) => {
    const feesSchedule = action.payload;
    const scheduleList = [...state.feesScheduleList];
    const replacedScheduleIndex = scheduleList.findIndex(s => s.id === feesSchedule.id);
    if (replacedScheduleIndex >= 0) {
      scheduleList[replacedScheduleIndex] = feesSchedule;
    } else {
      scheduleList.push(feesSchedule);
    }
    return { ...state, feesScheduleList: scheduleList };
  });
});

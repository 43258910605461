import moment from 'moment';
import {
  CallLogFilter,
  dateFormat,
  getAllTasks,
  getEndDate,
  isBetweenDates,
  QLog,
  QMessageMetadata,
  QTask,
  QTaskFilter,
  TaskPriority,
  TaskStatus,
  TaskType
} from '@qwealth/qdata';
import {
  ActivityFilters,
  ALL_TASK_TYPES,
  defaultFilter,
  ACTIVITY_FILTER_KEY,
  ActivityFiltersWithoutQuery,
  ALL_LOG_SUBJECTS,
  QFeedActivityFilters,
} from 'data/models/ActivityFilters';
import { DateRange } from 'data/models/DateRange';
import { flattenList } from './DataUtil';
import { caseInsensitiveFuzzySearch } from './search';
import { ENG_DATE_FORMAT } from 'data/refData/time';
import { CalendarEvent } from 'data/data-layer/event';
import { SortDirection } from 'data/models/SortDirection';
import { Activity } from 'data/models/Activity';
import { ALL_DATE_RANGE, ASSIGN_TO_ME_RESPONSIBILITY, CREATED_BY_ME_RESPONSIBILITY } from 'data/refData/acitivites';

export const getSavedFilters = (): ActivityFilters => {
  const qFeedFilters = localStorage.getItem(ACTIVITY_FILTER_KEY);
  if (qFeedFilters) {
    const preference = JSON.parse(qFeedFilters);
    return {
      ...preference,
      filterDateRange: {
        fromDate: moment(preference.filterDateRange.fromDate),
        toDate: moment(preference.filterDateRange.toDate),
      },
    };
  }

  return defaultFilter;
};

type TaskFilterParam = {
  sortDirection: SortDirection;
  filterDateRange?: DateRange;
  filterTaskStatus: Array<TaskStatus>;
  filterTaskType: TaskType | 'All Types';
  filterTaskPriorities: Array<TaskPriority>;
  filterDateValue: string;
  householdQID?: string;
}

export const getTaskFilter = (filterParam: TaskFilterParam): QTaskFilter => {
  const {
    filterTaskStatus,
    filterTaskType,
    filterTaskPriorities,
    filterDateValue,
    filterDateRange,
    householdQID,
    sortDirection
  } = filterParam;

  const filters: QTaskFilter = {
    status: filterTaskStatus.toString(),
    priority: filterTaskPriorities.toString(),
  };

  if (filterTaskType !== ALL_TASK_TYPES) {
    filters.taskType = filterTaskType;
  }

  if (filterDateValue !== ALL_DATE_RANGE) {
    filters['dueDate[dateafter]'] = filterDateRange?.fromDate.format(dateFormat);
    filters['dueDate[datebefore]'] = filterDateRange?.toDate.format(dateFormat);
  }

  if (householdQID) {
    filters.householdQID = householdQID;
  }

  filters['dueDate[orderby]'] = sortDirection;
  filters['limit'] = 250;

  return filters;
};

const filterDuplicateTasks = (taskList: QTask[]): QTask[] => {
  const taskIds: Array<number> = [];
  const filteredTasks: QTask[] = [];
  taskList.forEach(task => {
    if (task.id && !taskIds.includes(task.id)) {
      taskIds.push(task.id);
      filteredTasks.push(task);
    }
  });
  return filteredTasks;
}

/*
  Retrieving createdBy tasks needs to be in a separate API call than assignee tasks.
  Joining the two it's possible to get duplicate tasks (a task created and assigned to a user), hence the filterDuplicateTasks
 */
export const getTasksByExtendedFilters = (activityFiltersAndEmail: QFeedActivityFilters): Promise<Array<QTask>> => {
  const promises: Array<Promise<Array<QTask>>> = [];

  const taskFilter = getTaskFilter(activityFiltersAndEmail);
  const { userEmail } = activityFiltersAndEmail;

  const assignee: Array<string> = [];
  activityFiltersAndEmail.filterTaskResponsibilities.forEach(responsibility => {
    if (responsibility === CREATED_BY_ME_RESPONSIBILITY.value) {
      promises.push(getAllTasks({ ...taskFilter, createdBy: userEmail }));
    } else if (responsibility === ASSIGN_TO_ME_RESPONSIBILITY.value) {
      assignee.push(userEmail);
    } else {
      assignee.push(responsibility)
    }
  });

  if (assignee.length > 0) {
    promises.push(getAllTasks({ ...taskFilter, assignee: assignee.toString() }));
  }

  return Promise
    .all(promises)
    .then(flattenList)
    .then(filterDuplicateTasks);
}

type LogFilterParam = {
  filterDateRange: DateRange;
  filterDateValue: string;
  filterLogSubject: string;
  householdQID?: string;
  sortDirection: SortDirection;
}

export const getCallLogFilter = (filterParam: LogFilterParam): CallLogFilter => {
  const {
    filterDateValue,
    filterDateRange,
    filterLogSubject,
    householdQID,
    sortDirection
  } = filterParam;
  const filters: CallLogFilter = {};
  if (filterDateValue !== ALL_DATE_RANGE) {
    filters['callDate[dateafter]'] = filterDateRange.fromDate.format(dateFormat);
    filters['callDate[datebefore]'] = filterDateRange.toDate.format(dateFormat);
  }
  if (filterLogSubject !== ALL_LOG_SUBJECTS) {
    filters.title = filterLogSubject;
  }
  if (householdQID) {
    filters.householdQID = householdQID;
  }

  filters['callDate[orderby]'] = sortDirection;
  filters['limit'] = 250;

  return filters;
}

export const getMessageFilter = (activityFilters: ActivityFiltersWithoutQuery) => (messages: Array<QMessageMetadata>): Array<QMessageMetadata> =>
  messages.filter(message => activityFilters.filterDateValue === ALL_DATE_RANGE
    || isBetweenDates(activityFilters.filterDateRange, moment(message.date)));

export const qtaskSearch = (query: string, task: QTask): boolean =>
  caseInsensitiveFuzzySearch(query, [
    task.title,
    task.details,
    task.householdName,
    task.householdQID,
    task.dueDate,
    moment(task.dueDate).format(ENG_DATE_FORMAT),
    `${task.householdName ?? ''} ${task.taskType}`]);

export const callLogSearch = (query: string, callLog: QLog): boolean =>
  caseInsensitiveFuzzySearch(query, [
    callLog.title,
    callLog.details,
    callLog.householdName,
    callLog.householdQID,
    callLog.callDate,
    moment(callLog.callDate).format(ENG_DATE_FORMAT),
    `${callLog.householdName ?? ''} ${callLog.title}`]);

export const eventSearch = (query: string, event: CalendarEvent): boolean =>
  caseInsensitiveFuzzySearch(query, [
    event.body?.content,
    event.subject?.label,
    event.subject?.value]);

export const emailSearch = (query: string, message: QMessageMetadata): boolean =>
  caseInsensitiveFuzzySearch(query, [
    message.subject,
    message.from,
    message.notes,
    message.date,
    moment(message.date).format(ENG_DATE_FORMAT),
    ...message.to
  ]);

export const sortActivitiesByDate = (direction?: SortDirection) => (a: Activity, b: Activity): number => {
  const endDateA = getEndDate(a);
  const endDateB = getEndDate(b);

  const diff = endDateB.diff(endDateA);
  return direction === 'ASC' ? -1 * diff : diff;
};

import React, { Dispatch, FC, SetStateAction, useState } from 'react';
import { Form } from 'react-final-form';
import { useNavigate } from 'react-router';
import { useSelector } from 'react-redux';
import { Form as BsForm } from 'react-bootstrap';
import { Box, Button, Modal } from '@qwealth/qcore';
import { saveEntity, selectUserPartnerId } from '@qwealth/qdata';
import { BasicInfoForm, EntityBasicFields, ValidationType } from '@qwealth/kyc';
import { resetHouseholdState } from 'data/actions/households';
import { CreateEntityForm } from '../../data/models/CreateEntityForm';
import { useAppDispatch } from 'data/store';
import { createEntityValidator } from 'data/validations/legalEntityValidation';
import { errorHandler } from 'services/axiosService';
import InputForm from 'components/common/InputForm';
import ClientTypeSelector from '../common/ClientTypeSelector';
import EntityHouseholdSelector from './KycTab/EntityHouseholdSelector';
import { cleanupEntityPayload } from '../../utils/legalEntity';

// TODO: refactor to kyc and remove below and qlife dupe.
const BASIC_DEFAULTS: EntityBasicFields = {
  name: '',
  entityType: 'Corporate',
  BIN: '',
  controllingParty: '',
  languagePreference: 'English'
};

interface Props {
  show: boolean;
  setShow: Dispatch<SetStateAction<boolean>>;
}

const AddEntity: FC<Props> = ({ show, setShow }): JSX.Element => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const partnerID = useSelector(selectUserPartnerId);

  const [validationType, setValidationType] = useState<ValidationType>('Basic');

  const submitHandler = async (values: CreateEntityForm) => {
    await dispatch(saveEntity(cleanupEntityPayload(values), values.selectedHousehold?.value ?? ''))
      .then((entity) => {
        navigate({ pathname: `/legalEntities/${entity.QID}`, search: 'legalEntityTab=details' });
        // clears the household state so the stale state is removed.
        dispatch(resetHouseholdState());
        setShow(false);
      })
      .catch(errorHandler(dispatch, 'Could not save new entity.'));
  };
  
  return (
    <Modal
      onClose={() => {
        setShow(false);
      }}
      show={show}
      title="Add Legal Entity"
      size="lg"
    >
      <Modal.Body>
        <Form<CreateEntityForm>
          onSubmit={submitHandler}
          initialValues={{ ...BASIC_DEFAULTS, partnerID }}
          validate={values => createEntityValidator(values, validationType)}
        >
          {({ handleSubmit, form, submitting }) => (
            <form onSubmit={handleSubmit}>
              <Box display="grid" gridTemplateColumns="2fr 1fr">
                <InputForm>
                  <EntityHouseholdSelector formChange={form.change} />
                </InputForm>
              </Box>
              <BasicInfoForm validationType={validationType} />
              <ClientTypeSelector isRequired />
              <Box display="flex" gap="default" justifyContent="flex-end" marginTop="large">
                <BsForm.Check
                  type="switch"
                  label={(
                    <Box textStyle="formLabel">
                      NBIN Validation
                    </Box>)}
                  checked={validationType === 'NBIN'}
                  onChange={e => {
                    setValidationType(e.target.checked ? 'NBIN' : 'Basic');
                  }}
                />
                <Button
                  marginLeft="default"
                  disabled={submitting}
                  onClick={() => {
                    return handleSubmit();
                  }}
                >
                  Save
                </Button>
                <Button
                  variant="outline"
                  onClick={() => {
                    setShow(false);
                    form.reset();
                  }}
                >
                  Cancel
                </Button>
              </Box>
            </form>
          )}
        </Form>
      </Modal.Body>
    </Modal>
  );
}

export default AddEntity;

import { Dispatch } from 'redux';
import { createAction } from '@reduxjs/toolkit';
import { fetchPartner, fetchUserByUsername, IPartner, IUser, QContext } from '@qwealth/qdata';
import { errorHandler } from '../../services/axiosService';
import { WebSocketService } from '../../services/WebSocketService';

const PUT_CURRENT_USER = 'PUT_CURRENT_USER';
const PUT_CURRENT_PARTNER = 'PUT_CURRENT_PARTNER';

export const putCurrentUser = createAction<IUser>(PUT_CURRENT_USER);
export const putPartner = createAction<IPartner | null>(PUT_CURRENT_PARTNER);

export const loadUserInfo = (username: string) => async (dispatch: Dispatch): Promise<void> => {
  try {
    const userUuid = QContext.getUserUuid();
    const user = await fetchUserByUsername(username, false)
      .then(users => {
        let user_ = users[0];
        if (userUuid) {
          const preferredUser = users.find(user => user.uuid === userUuid);
          if (preferredUser) {
            user_ = preferredUser;
          }
        } else if (user_) {
          QContext.setUserUuid(user_.uuid);
        }
        return user_;
      });

    if (!user) {
      throw new Error('Could not load user info.');
    }

    dispatch(putCurrentUser(user));
    if (user.partnerID) {
      WebSocketService.connect(user.email, QContext.getIdToken());
      const partner = await fetchPartner(user.partnerID);
      dispatch(putPartner(partner));
    } else {
      dispatch(putPartner(null));
    }
  } catch (e) {
    await errorHandler(dispatch, 'Could not load user info.')(e);
  }
};

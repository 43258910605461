import { max } from 'lodash';
import { Form } from 'react-bootstrap';
import moment from 'moment';
import { AiOutlineMinusCircle } from 'react-icons/ai';
import { BsCheckCircle, BsXCircle } from 'react-icons/bs';

import { Box } from '@qwealth/qcore';
import {
  dateFormat,
  sendEmailNotification,
  FeesSchedule,
  FeesScheduleTier,
  ScheduleApprovalStatus,
  ApprovedStatus,
  RejectedStatus,
  PendingStatus, getSummary,
} from '@qwealth/qdata';

import { AppThunkDispatch } from 'data/store';
import { updateFeesSchedule } from 'data/actions/feesSchedules';
import ActionConfirmation from 'components/common/ActionConfirmation';
import FeeScheduleToolTip from 'components/common/Fees/common/FeeScheduleToolTip';

const todaysDate = moment().format(dateFormat);

export const getTierOfHighestOrder = (feeTier: Array<FeesScheduleTier>): number => {
  const orderNumbers = feeTier.map(tier => tier.tierOrder);
  const highestOrder = max(orderNumbers);
  return highestOrder ? highestOrder : 0;
};

export const getLastTierMaxAmount = (
  selectedFeeTier: FeesScheduleTier | null,
  feeTier: Array<FeesScheduleTier>,
): number => {
  if (selectedFeeTier) {
    const lastTier = feeTier.find(tier => tier.tierOrder === selectedFeeTier.tierOrder - 1);
    return lastTier ? lastTier.tierMaxAmount : 0;
  } else {
    const tierOrder = getTierOfHighestOrder(feeTier);
    const lastTier = feeTier.find(tier => tier.tierOrder === tierOrder);
    return lastTier ? lastTier.tierMaxAmount : 0;
  }
};

export const getUpdatedTiersOnEdit = (tier, feeTiers): Array<FeesScheduleTier> => {
  if (tier.tierOrder === feeTiers[feeTiers.length - 1].tierOrder) {
    feeTiers[feeTiers.length - 1] = tier;
    return feeTiers;
  }

  const maxDifference = tier.tierMaxAmount - feeTiers[tier.tierOrder - 1].tierMaxAmount;
  for (let i = tier.tierOrder; i < feeTiers.length; i++) {
    feeTiers[i].tierMinAmount =
      i === tier.tierOrder ? tier.tierMaxAmount + 0.01 : feeTiers[i - 1].tierMaxAmount + 0.01;
    feeTiers[i].tierMaxAmount =
      feeTiers[i].tierMinAmount > feeTiers[i].tierMaxAmount
        ? feeTiers[i].tierMinAmount + 0.01
        : feeTiers[i].tierMaxAmount;
  }
  feeTiers[tier.tierOrder - 1] = tier;
  return feeTiers;
};

export const getUpdatedTiersOnDelete = (tier, feeTiers): Array<FeesScheduleTier> => {
  if (tier.tierOrder === feeTiers[feeTiers.length - 1].tierOrder) {
    return [];
  }

  if (tier.tierOrder === 1) {
    feeTiers[tier.tierOrder].tierMinAmount = 0;
  } else {
    feeTiers[tier.tierOrder].tierMinAmount = feeTiers[tier.tierOrder - 2].tierMaxAmount + 0.01;
  }

  const updatedTiers: Array<FeesScheduleTier> = [];
  for (let i = tier.tierOrder; i < feeTiers.length; i++) {
    feeTiers[i].tierOrder = feeTiers[i].tierOrder - 1;
    updatedTiers.push(feeTiers[i]);
  }

  return updatedTiers;
};

export const sortTiersByTierOrder = scheduleTiers => {
  const orderedTiers: FeesScheduleTier[] = [];
  for (let tierIndex = 0; tierIndex < scheduleTiers.length; tierIndex++) {
    orderedTiers[scheduleTiers[tierIndex].tierOrder - 1] = { ...scheduleTiers[tierIndex] };
  }
  return [...orderedTiers];
};

export const getColumns = (hasApprovalPermission: boolean, email: string, dispatch: AppThunkDispatch) => {
  const sendScheduleApprovalCompleteEmail = async (
    partnerID: number,
    scheduleName: string,
    scheduleStatus: ScheduleApprovalStatus,
  ) => await dispatch(sendEmailNotification({
    changeBy: email,
    partnerID,
    scheduleName,
    scheduleStatus,
    notificationType: 'FeeScheduleCompleted',
  }));

  const handleApprove = async (feesSchedule: FeesSchedule) => {
    const schedule: Partial<FeesSchedule> = {
      id: feesSchedule.id,
      isApproved: 1,
      scheduleStatus: ApprovedStatus,
      reviewedBy: email,
      reviewedDate: todaysDate,
    };
    await dispatch(updateFeesSchedule(schedule));
    const { partnerID, feeScheduleName } = feesSchedule;
    await sendScheduleApprovalCompleteEmail(partnerID, feeScheduleName, ApprovedStatus);
  };

  const handleReject = async (feesSchedule: FeesSchedule) => {
    const schedule: Partial<FeesSchedule> = {
      id: feesSchedule.id,
      scheduleStatus: RejectedStatus,
      reviewedBy: email,
      reviewedDate: todaysDate,
    };
    await dispatch(updateFeesSchedule(schedule));
    const { partnerID, feeScheduleName } = feesSchedule;
    await sendScheduleApprovalCompleteEmail(partnerID, feeScheduleName, RejectedStatus);
  };

  const handleGrandfather = (feesSchedule: FeesSchedule) => {
    const schedule: Partial<FeesSchedule> = {
      id: feesSchedule.id,
      isGrandfatheredFromIMA: 1,
      grandfatheredFromIMABy: email,
      grandfatheredFromIMADate: todaysDate,
    };
    dispatch(updateFeesSchedule(schedule));
  };

  return [
    {
      dataField: 'feeScheduleName',
      headerSortingStyle: { cursor: 'pointer' },
      sort: true,
      text: 'Name',
      formatter: (feeScheduleName: string, feesSchedule: FeesSchedule) => (
        <Box display="flex">
          <Box>{feeScheduleName}</Box>
          <FeeScheduleToolTip schedule={feesSchedule} />
        </Box>
      ),
    },
    {
      dataField: 'scheduleType',
      headerSortingStyle: { cursor: 'pointer' },
      sort: true,
      text: 'Schedule Type',
    },
    {
      dataField: 'scheduleLevel',
      headerSortingStyle: { cursor: 'pointer' },
      sort: true,
      text: 'Schedule Level',
      formatter: (cell, row) => (
        <Box>{row.scheduleLevel === 'IA Code' ? row.iaCode : row.scheduleLevel}</Box>
      ),
    },
    {
      dataField: 'scheduleStatus',
      headerSortingStyle: { cursor: 'pointer' },
      sort: true,
      text: 'Approval Status',
    },
    {
      dataField: 'reasonForFeeSchedule',
      headerSortingStyle: { cursor: 'pointer' },
      sort: true,
      text: 'Reason For Fee Schedule',
      formatter: (reasonForFeeSchedule: string) => (
        <Box display="flex">
          <Box
            data-tooltip-content={reasonForFeeSchedule}
            data-tooltip-id="scheduleTooltips"
          >
            {getSummary(reasonForFeeSchedule, 50)}
          </Box>
        </Box>
      ),
    },
    {
      dataField: 'reviewedBy',
      headerSortingStyle: { cursor: 'pointer' },
      sort: true,
      text: 'Reviewed By',
    },
    {
      dataField: 'reviewedDate',
      headerSortingStyle: { cursor: 'pointer' },
      sort: true,
      text: 'Reviewed Date',
    },
    {
      dataField: 'isGrandfatheredFromIMA',
      headerSortingStyle: { cursor: 'pointer' },
      sort: true,
      text: 'Grandfathered From IMA',
      formatter: (isGrandfatheredFromIMA: 0 | 1) => (isGrandfatheredFromIMA ? 'Yes' : 'No'),
    },
    {
      dataField: 'grandfatheredFromIMABy',
      headerSortingStyle: { cursor: 'pointer' },
      sort: true,
      text: 'Grandfathered By',
    },
    {
      dataField: 'grandfatheredFromIMADate',
      headerSortingStyle: { cursor: 'pointer' },
      sort: true,
      text: 'Grandfathered Date',
    },
    {
      dataField: 'isDefault',
      headerSortingStyle: { cursor: 'pointer' },
      sort: true,
      text: 'Is Default Schedule',
      formatter: isDefault => (
        <Box ml="large">
          <Form.Check id="isDefaultSwitch" type="switch" readOnly checked={isDefault} />
        </Box>
      ),
    },
    {
      dataField: 'isApproved',
      headerSortingStyle: { cursor: 'pointer' },
      sort: true,
      text: 'Actions',
      hidden: !hasApprovalPermission,
      formatter: (cell, feesSchedule: FeesSchedule) => {
        const canBeGrandfathered =
          feesSchedule.scheduleStatus === ApprovedStatus &&
          !feesSchedule.isDefault &&
          !feesSchedule.isGrandfatheredFromIMA;
        return (
          <Box display="flex" flex="1" flexDirection="row" onClick={e => e.stopPropagation()}>
            {feesSchedule.scheduleStatus === PendingStatus && (
              <>
                <ActionConfirmation
                  actionHandler={() => handleApprove(feesSchedule)}
                  target={`Approve ${feesSchedule.feeScheduleName}`}
                  actionTitle="Approve Confirmation"
                  actionLabel="Approve"
                  Icon={<BsCheckCircle />}
                />
                <ActionConfirmation
                  actionHandler={() => handleReject(feesSchedule)}
                  target={`Reject ${feesSchedule.feeScheduleName}`}
                  actionTitle="Reject Confirmation"
                  actionLabel="Reject"
                  Icon={<BsXCircle />}
                />
              </>
            )}
            {canBeGrandfathered && (
              <ActionConfirmation
                actionHandler={() => handleGrandfather(feesSchedule)}
                target={`Grandfather in ${feesSchedule.feeScheduleName}`}
                actionTitle="Grandfather Confirmation"
                actionLabel="Grandfather"
                buttonProps={{
                  'data-tooltip-content': 'Grandfather',
                  'data-tooltip-id': 'scheduleTooltips'
                }}
                Icon={<AiOutlineMinusCircle />}
              />
            )}
          </Box>
        );
      },
    },
  ];
};

import { Dispatch } from 'redux';
import axios from 'axios';
import { omit } from 'lodash';
import moment from 'moment';

import { responseEntityMapper, responseListMapper } from '@qwealth/qcore';
import { dateFormat, FeesExclusion, FEES_EXCEPTION_TYPES } from '@qwealth/qdata';

import { errorHandler, REACT_APP_QWEALTH_API } from 'services/axiosService';
import { AppThunkDispatch } from '../store';

export const PUT_ALL_FEES_EXCLUSIONS = 'PUT_ALL_FEES_EXCLUSIONS';
export const PUT_ACTIVE_FEES_EXCLUSION = 'PUT_ACTIVE_FEES_EXCLUSION';
export const PUT_NEWLY_CREATED_FEES_EXCLUSION = 'PUT_NEWLY_CREATED_FEES_EXCLUSION';
export const RESET_FEES_EXCLUSION = 'RESET_FEES_EXCLUSION';
export const REMOVE_FEES_EXCLUSION = 'REMOVE_FEES_EXCLUSION';

const putAllFeesExclusions = feesExclusions => ({ type: PUT_ALL_FEES_EXCLUSIONS, feesExclusions });
const putActiveFeesExclusion = feesExclusion => ({
  type: PUT_ACTIVE_FEES_EXCLUSION,
  feesExclusion,
});
const removeFeesExclusion = id => ({ type: REMOVE_FEES_EXCLUSION, id });

export const putNewlyCreatedFeesExclusion = newlyCreatedFeesExclusion => ({
  type: PUT_NEWLY_CREATED_FEES_EXCLUSION,
  newlyCreatedFeesExclusion,
});
export const resetFeesExclusion = () => ({ type: RESET_FEES_EXCLUSION });

const setDetailsArrayOnExclusion = (exclusion: FeesExclusion) => {
  try {
    exclusion.detailsArray = JSON.parse(exclusion.details);
  } catch (e) {
    exclusion.detailsArray = [];
  }
  if (!exclusion.detailsArray) {
    exclusion.detailsArray = [];
  }
};

export const axiosFetchAllFeesExclusions = (types: string) =>
  new Promise((resolve, reject) => {
    axios
      .get(`${REACT_APP_QWEALTH_API}/fees/exclusions?type=${types}`)
      .then(resp => responseListMapper<FeesExclusion>(resp))
      .then(exclusions =>
        resolve(
          exclusions.map(d => {
            setDetailsArrayOnExclusion(d);
            return d;
          }),
        ),
      )
      .catch(e => reject(e));
  });

export const loadAllFeesExclusions = (types?: string) => (dispatch: Dispatch) => {
  types = types || FEES_EXCEPTION_TYPES.slice(0, -1).join();
  return axiosFetchAllFeesExclusions(types)
    .then(feesExclusions => dispatch(putAllFeesExclusions(feesExclusions)))
    .catch(errorHandler(dispatch));
};

export const axiosFetchSingleFeesExclusion = (feesExclusionId: number): Promise<FeesExclusion> => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${REACT_APP_QWEALTH_API}/fees/exclusions/${feesExclusionId}`)
      .then(resp => responseEntityMapper<FeesExclusion>(resp))
      .then(exclusion => {
        setDetailsArrayOnExclusion(exclusion);
        resolve(exclusion);
      })
      .catch(e => reject(e));
  });
};

export const loadSingleFeesExclusion = (feesExclusionId: number) => (dispatch: Dispatch) =>
  axiosFetchSingleFeesExclusion(feesExclusionId)
    .then(feesExclusion => {
      dispatch(putActiveFeesExclusion(feesExclusion));
    })
    .catch(errorHandler(dispatch));

export const addOrUpdateFeesExclusion = (feesExclusion: Partial<FeesExclusion>) => (dispatch: AppThunkDispatch) => {
  const exceptionData = omit(feesExclusion, ['id']);

  const request = !feesExclusion.id
    ? axios.post(`${REACT_APP_QWEALTH_API}/fees/exclusions`, exceptionData)
    : axios.patch(`${REACT_APP_QWEALTH_API}/fees/exclusions/${feesExclusion.id}`, exceptionData);
  return request
    .then(response => responseEntityMapper<FeesExclusion>(response))
    .then((response: FeesExclusion) => {
      if (!feesExclusion.id) {
        dispatch(putNewlyCreatedFeesExclusion(response));
      }
      dispatch(putActiveFeesExclusion(response));
      dispatch(loadAllFeesExclusions());
      return Promise.resolve(response);
    })
    .catch(e => {
      errorHandler(dispatch)(e);
      return Promise.reject(null);
    });
};

export const deleteFeesExclusion = (id: number) => (dispatch: AppThunkDispatch) => {
  const feesExclusionData = { effectiveEndDate: moment().format(dateFormat) };
  return axios
    .patch(`${REACT_APP_QWEALTH_API}/fees/exclusions/${id}`, feesExclusionData)
    .then(() => dispatch(removeFeesExclusion(id)))
    .catch(errorHandler(dispatch));
};

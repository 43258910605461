import React from 'react';
import { styled } from '@qwealth/qcore';

const StyledTitle = styled.h5`
  font-weight: bold;
  font-size: 20px;
  margin-bottom: 1rem;
  text-transform: uppercase;
`;

export const TitlePrimary = styled.div`
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: 0.75rem;
`;

export default function Title(props) {
  return <StyledTitle>{props.children}</StyledTitle>;
}

import { createReducer } from '@reduxjs/toolkit';
import { RelatedContact } from '@qwealth/qdata';
import {
  putLegalEntityRelatedContacts,
  removeLegalEntityRelatedContact,
  resetLegalEntityRelatedContact, setLegalEntityRelatedContactInitializedFlag, setLegalEntityRelatedContactLoadingFlag
} from '../actions/legalEntityRelatedContacts';

interface State {
  relatedContacts: RelatedContact[];
  initialized: boolean;
  loading: boolean;
}

const initialState: State = {
  initialized: false,
  relatedContacts: [],
  loading: false
}

export default createReducer(initialState, (builder) => {
  builder.addCase(resetLegalEntityRelatedContact, () => initialState);

  builder.addCase(putLegalEntityRelatedContacts, (state, action) => {
    const contacts: RelatedContact[] = action.payload;

    const updatedContacts: RelatedContact[] = [...state.relatedContacts];
    const existingUuids = updatedContacts.map(c => c.uuid);
    contacts.forEach(contact => {
      if (!existingUuids.includes(contact.uuid)) {
        updatedContacts.push(contact);
        existingUuids.push(contact.uuid);
      }
    })
    return { ...state, relatedContacts: updatedContacts };
  });

  builder.addCase(removeLegalEntityRelatedContact, (state, action) => {
    const relatedId = action.payload;
    const { relatedContacts } = state;
    const index = relatedContacts.findIndex(({ id }) => id === relatedId);

    if (index > -1) {
      return { ...state, relatedContacts: [...relatedContacts.slice(0, index), ...relatedContacts.slice(index + 1)] };
    }
    return state;
  });

  builder.addCase(setLegalEntityRelatedContactInitializedFlag, (state, action) => {
    return { ...state, initialized: action.payload };
  });

  builder.addCase(setLegalEntityRelatedContactLoadingFlag, (state, action) => {
    return { ...state, loading: action.payload };
  });
});

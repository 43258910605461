import { createReducer } from '@reduxjs/toolkit';
import { MyCorpEntity, MyHousehold, MyLead } from '../models/MyEntity';
import { putMyList, setMyListLoadingState } from '../actions/myList';

export interface State {
  isLoading: boolean;
  isInitialized: boolean;
  myList: Array<MyLead | MyHousehold | MyCorpEntity>;
}

export const initialState: State = {
  isLoading: false,
  isInitialized: false,
  myList: []
}

export default createReducer(initialState, (builder) => {
  builder.addCase(setMyListLoadingState, (state, action) =>
    ({...state, isLoading: action.payload}));

  builder.addCase(putMyList, (state, action) =>
    ({...state, myList: action.payload, isInitialized: true}));
});

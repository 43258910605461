import { createReducer } from '@reduxjs/toolkit';
import { setUser } from 'data/actions/microsoft/user';
import { MicrosoftUser } from 'data/models/MicrosoftUser';

const defaultState: Partial<MicrosoftUser> & { timeZone: string } = {
  displayName: '',
  timeZone: '',
};

const userReducer = createReducer(defaultState, (builder) => {
  builder.addCase(setUser, (state, action) =>
    ({ ...state, ...action.payload }))
});

export default userReducer;

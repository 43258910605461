import React, { FC, ReactElement, useEffect, useRef, useState } from 'react';
import { useMsal } from '@azure/msal-react';
import { InteractionStatus } from '@azure/msal-browser';
import { Box, BoxProps, Button, HeaderTitle, styled, withDefaults } from '@qwealth/qcore';

import { scopes } from 'services/microsoft/AuthService';
import { getPhoto } from 'services/pictures/UnsplashService';

const Background = styled(Box)`
  align-items: center;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  height: 100vh;
  justify-content: center;
  width: 100vw;
`;

const CopyRightsFooter = withDefaults<BoxProps>({
  as: 'span',
  color: 'white',
  fontSize: 'small',
})(
  // @ts-ignore
  styled(Box)`
    position: absolute;
    top: 95vh;
  `,
);

const LOGIN_STATES = [
  InteractionStatus.Login,
  InteractionStatus.AcquireToken,
  InteractionStatus.SsoSilent,
];

interface Props {
  topic?: string;
}

const LoginPage: FC<Props> = ({ topic = 'skyscraper' }): ReactElement => {
  const { instance, inProgress } = useMsal();
  const [wallpaper, setWallpaper] = useState('');
  const unsplashCalled = useRef(false);
  useEffect(() => {
    if (LOGIN_STATES.includes(inProgress) && !unsplashCalled.current) {
      unsplashCalled.current = true;
      // eslint-disable-next-line no-console
      getPhoto(topic).then(setWallpaper).catch(console.error);
    }
  }, [inProgress, topic]);

  return (
    <Background style={{ backgroundImage: `url(${wallpaper})` }}>
      <Box
        alignItems="center"
        bg="white"
        border="1 solid black"
        borderColor="gray.1"
        borderRadius="default"
        display="flex"
        flexDirection="column"
        height="15%"
        justifyContent="center"
        width="30%"
        verticalAlign="middle"
      >
        <HeaderTitle
          color="gray.1"
          fontSize="large"
          display="flex"
          padding="default"
          textAlign="center"
        >
          To proceed forward using your Microsoft account please press the login button below.
        </HeaderTitle>
        <Button onClick={() => instance.loginRedirect({ scopes })}>Login</Button>
      </Box>
      <CopyRightsFooter>©2023 All Rights Reserved. QWealth®</CopyRightsFooter>
    </Background>
  );
};

export default LoginPage;

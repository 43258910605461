import axios from 'axios';
import { apiUrl, QNote } from '@qwealth/qdata';
import { responseEntityMapper, responseListMapper } from '@qwealth/qcore';

export const loadQNote = async (noteId: number): Promise<QNote | undefined> =>
  await axios
    .get(`${apiUrl}/qnotes/${noteId}`)
    .then(response => responseEntityMapper<QNote>(response))
    .catch(err => {
      console.error(err);
      return undefined;
    });

export const getHouseholdQNotes = async (householdId: string): Promise<QNote[]> => axios
  .get(`${apiUrl}/qnotes`, { params: { householdQID: householdId } })
  .then(response => responseListMapper<QNote>(response));

import {
  fetchOpportunitiesWithQID,
  generateOpportunity,
  Opportunity,
  updateOpportunity,
} from '@qwealth/qdata';
import { errorHandler } from 'services/axiosService';
import { AppThunkDispatch } from '../store';

export const PUT_HOUSEHOLD_OPPORTUNITIES = 'PUT_HOUSEHOLD_OPPORTUNITIES';
export const PUT_OPPORTUNITY = 'PUT_OPPORTUNITY';

export const loadHouseholdOpportunities = (householdId: string) => async (dispatch: AppThunkDispatch): Promise<void> => {
  try {
    const opportunities = await fetchOpportunitiesWithQID(householdId);
    dispatch({
      type: PUT_HOUSEHOLD_OPPORTUNITIES,
      opportunities,
    });
  } catch (e) {
    errorHandler(dispatch, 'Household opportunities load failed')(e);
  }
};

export const editOpportunity = (opp: Opportunity) => async (dispatch: AppThunkDispatch): Promise<void> => {
  try {
    const opportunity = await updateOpportunity(opp);
    dispatch({
      type: PUT_OPPORTUNITY,
      opportunity,
    });
  } catch (e) {
    errorHandler(dispatch)(e);
  }
};

export const createNewOpportunity = (opp: Opportunity, householdId: string) => async (dispatch: AppThunkDispatch): Promise<Opportunity | null> => {
  try {
    const opportunity = await generateOpportunity(opp, householdId);
    dispatch({
      type: PUT_OPPORTUNITY,
      opportunity,
    });
    return opportunity as Opportunity;
  } catch (e) {
    errorHandler(dispatch, 'Generating Opportunity failed')(e);
    return null;
  }
};

export const LOADING_STATE = 'LOADING_STATE';
export type LoadingState = typeof LOADING_STATE;

export const READY_STATE = 'READY_STATE';
export type ReadyState = typeof READY_STATE;

export function isError(value: any): boolean {
  return value instanceof Error;
}

export function isLoading(value: any): boolean {
  return value === LOADING_STATE;
}

export function isReady(value: any): boolean {
  return value === READY_STATE;
}

import { QLog, QMessageMetadata, QTask, SelectOption } from '@qwealth/qdata';
import { CalendarEvent } from '../data-layer/event';

export type Activity = QTask | QLog | CalendarEvent | QMessageMetadata;
export type QFeedActivity = QTask | QLog;
export const ActivityTypes = ['events', 'emails', 'tasks', 'logs'] as const;
export type ActivityType = typeof ActivityTypes[number];

export const ACTIVITY_TYPE_OPTIONS: SelectOption<ActivityType>[] = [
  { label: 'Events', value: 'events' },
  { label: 'Emails', value: 'emails' },
  { label: 'Tasks', value: 'tasks' },
  { label: 'Call Logs', value: 'logs' },
];

import { indexBy, map, prop } from 'ramda';
import { EventApi } from '@fullcalendar/core';
import moment from 'moment';
import momentTz from 'moment-timezone';
import { findIana } from 'windows-iana';
import { Event, EventDate } from '@qwealth/qdata';
import {
  getCalendarEvents,
  getHouseholdCalendarEvents,
  patchCalendarEvent,
  postCalendarEvent,
} from 'services/microsoft/graphService';

import { DAYS_OF_WEEK, RECURRENCE_PATTERN_TYPE } from '../refData/acitivites';

type Body = {
  content?: string;
  contentType: string;
};

type Attendee = {
  emailAddress: Record<string, string>;
  type: string;
};

export type SVE = {
  id: string;
  value: string;
};

type Location = {
  displayName?: string;
  locationUri?: string;
};

type LocationValue = {
  displayName: Value;
  locationUri: string;
};

type Value = {
  value: string;
  label: string;
};

type RecurrenceResponse = {
  pattern?: RecurrencePattern;
  range?: RecurrenceRange;
};

export type Recurrence = {
  pattern: RecurrencePatternValue;
  range: RecurrenceRange;
};

type RecurrencePattern = {
  type: string;
  interval: number;
  daysOfWeek: Array<string>;
};

type RecurrenceRange = {
  type: string;
  startDate: string;
  endDate: string;
};

type RecurrencePatternValue = {
  type: Value;
  interval?: number;
  daysOfWeek?: Array<string>;
};

// this should match the posts more and what comes back from api service
export type CalendarEventResponse = {
  attendees: Array<Attendee>;
  body?: Body;
  end: Record<string, string>;
  id?: string;
  isAllDay: boolean;
  isOnlineMeeting: boolean;
  location: Location;
  onlineMeetingProvider?: string;
  onlineMeetingURL?: string;
  recurrence?: RecurrenceResponse;
  singleValueExtendedProperties?: Array<SVE>;
  start: Record<string, string>;
  subject?: string;
};

// this prepares for cleaning up date for use in UI
export type CalendarEvent = Event & {
  attendees?: Array<Record<string, string>>;
  body?: Body;
  id?: string;
  isEvent: boolean;
  location?: LocationValue;
  onlineMeetingURL?: string;
  recurrence?: Recurrence;
  selectedHousehold?: Record<string, string>;
  subject: Record<string, string>;
  calendarId?: string;
  allDay: boolean;
  eventApi?: EventApi;
};

const prepareAttendees = attendees =>
  Array.isArray(attendees)
    ? attendees.map(attendee => ({
        type: 'required',
        emailAddress: { address: attendee.value, name: attendee.label },
      }))
    : [];

const getDay = dateTime => moment(dateTime).format('dddd').toLocaleLowerCase();

const prepareRecurrence = (start: EventDate, recurrence: Recurrence): RecurrenceResponse | undefined => {
  const { pattern, range } = recurrence;
  const { type } = pattern;
  if (type.value === 'never') {
    return undefined;
  }

  const { endDate } = range;

  return {
    pattern: {
      interval: 1,
      daysOfWeek: type.value === 'daily' ? DAYS_OF_WEEK : [getDay(start.dateTime)],
      type: type.value === 'daily' ? 'weekly' : type.value,
    },
    range: {
      ...range,
      startDate: moment(start.dateTime).format('YYYY-MM-DD'),
      endDate: moment(endDate).format('YYYY-MM-DD'),
    },
  };
};

const transformAttendees = (attendee: Attendee) => ({
  value: attendee.emailAddress.address,
  label: attendee.emailAddress.name,
});

export const newRecurrence: Recurrence = {
  pattern: { type: { label: 'Never', value: 'never' } },
  range: {
    type: '',
    startDate: moment().format(),
    endDate: moment().add(1, 'y').format()
  },
};

const transformRecurrence = recurrence => {
  if (!recurrence) {
    return newRecurrence;
  }

  const recurringPatternByValue = indexBy(prop('value'), RECURRENCE_PATTERN_TYPE);
  const { pattern, range } = recurrence;
  const { type } = pattern;
  const { startDate, endDate } = range;

  return {
    pattern: {
      ...pattern,
      type: recurringPatternByValue[type],
    },
    range: { ...range, startDate: moment(startDate), endDate: moment(endDate) },
  };
};

const transformToCalendarEvent = (value: CalendarEventResponse, calendarId?: string): CalendarEvent => {
  const {
    start,
    end,
    subject,
    attendees,
    singleValueExtendedProperties: svep,
    location,
    body,
    recurrence,
    isAllDay,
  } = value;
  const { displayName, locationUri } = location;
  const ianaTimeZone = findIana(start.timeZone) || [];
  return {
    ...value,
    start: {
      timeZone: start.timeZone,
      dateTime: momentTz.tz(
        start.dateTime,
        'YYYY-MM-DDTHH:mm:ssZ',
        ianaTimeZone.length ? ianaTimeZone[0] : '',
      ),
    },
    end: {
      timeZone: end.timeZone,
      dateTime: momentTz.tz(
        end.dateTime,
        'YYYY-MM-DDTHH:mm:ssZ',
        ianaTimeZone.length ? ianaTimeZone[0] : '',
      ),
    },
    subject: { label: subject || '', value: subject || '' },
    location: {
      displayName: { label: displayName || '', value: displayName || '' },
      locationUri: locationUri || '',
    },
    attendees: map(transformAttendees, attendees || []),
    selectedHousehold: {
      id: svep && svep.length > 0 ? svep[0].id : 'Not Related',
      value: svep && svep.length > 0 ? svep[0].value : 'Not Related',
    },
    isEvent: true,
    body,
    recurrence: transformRecurrence(recurrence),
    calendarId,
    allDay: isAllDay,
  };
};

const transformToCalendarEventResponse = (values: CalendarEvent): CalendarEventResponse => {
  const {
    id,
    start,
    end,
    body,
    subject,
    location,
    attendees,
    onlineMeetingURL,
    recurrence,
    allDay,
    selectedHousehold,
  } = values;
  const event: CalendarEventResponse = {
    id,
    start: { ...start, dateTime: moment(start.dateTime).format('YYYY-MM-DDTHH:mm:ss') },
    end: { ...end, dateTime: moment(end.dateTime).format('YYYY-MM-DDTHH:mm:ss') },
    body: { content: body?.content || '', contentType: 'html' },
    subject: subject.value,
    location: { displayName: location?.displayName.value, locationUri: location?.locationUri },
    onlineMeetingURL,
    recurrence: recurrence && prepareRecurrence(start, recurrence),
    attendees: prepareAttendees(attendees),
    isOnlineMeeting:
      location?.displayName.value === 'Teams' || location?.displayName.value === 'Web Conference',
    isAllDay: allDay,
    singleValueExtendedProperties: [
      {
        id: 'String {00020329-0000-0000-C000-000000000046} Name HouseholdId',
        value: selectedHousehold?.value || 'Not Related',
      },
    ],
  };

  if (event.location.displayName === 'Teams') {
    return { ...event, onlineMeetingProvider: 'teamsForBusiness' };
  }

  return event;
};

const parseGraphCalendarResponse = (
  response: Array<CalendarEventResponse>,
  calendarId?: string,
): Array<CalendarEvent> => response.map(response => transformToCalendarEvent(response, calendarId));

export const fetchEvents = async (
  timeZone: string,
  formatFromDate: string,
  formatToDate: string,
  calendarId?: string,
): Promise<Array<CalendarEvent>> => {
  const response = await getCalendarEvents(timeZone, formatFromDate, formatToDate, calendarId);
  return parseGraphCalendarResponse(response, calendarId);
};

export const fetchEventsByHouseholdId = async (
  timeZone: string,
  formatFromDate: string,
  formatToDate: string,
  householdId: string,
  order: string,
  calendarId?: string,
): Promise<Array<CalendarEvent>> => {
  const response = await getHouseholdCalendarEvents(
    timeZone,
    formatFromDate,
    formatToDate,
    householdId,
    order,
    calendarId
  );

  return parseGraphCalendarResponse(response);
};

export const generateCalendarEvent = async (
  event: CalendarEvent,
  fromCalendarId?: string,
): Promise<CalendarEvent> => {
  const data = transformToCalendarEventResponse(event);
  const response = await postCalendarEvent(data, fromCalendarId);
  const [result] = parseGraphCalendarResponse([response]);
  return result;
};

export const updateCalendarEvent = async (
  event: CalendarEvent,
  eventId: string,
  fromEmail?: string
): Promise<CalendarEvent> => {
  const data = transformToCalendarEventResponse(event);
  const response = await patchCalendarEvent(data, eventId, fromEmail);
  const [result] = parseGraphCalendarResponse([response]);
  return result;
};

export const updateHouseholdCalendarEvent = async (
  event: { singleValueExtendedProperties: Array<SVE> },
  eventId: string,
): Promise<CalendarEvent> => {
  const response = await patchCalendarEvent(event, eventId);
  const [result] = parseGraphCalendarResponse([response]);
  return result;
};

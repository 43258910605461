import { ValidationErrors } from 'final-form';
import { basicInfoValidator, required, ValidationType } from '@qwealth/kyc';
import { CreateEntityForm } from '../models/CreateEntityForm';

export const createEntityValidator = (values: CreateEntityForm, validationType: ValidationType): ValidationErrors => {
  const entityValidations = basicInfoValidator(values, validationType);
  return {
    ...entityValidations,
    clientType: required(values.clientType, 'Client Type'),
    selectedHousehold: required(values.selectedHousehold, 'Household')
  };
}

import { FC } from 'react';
import { Form } from 'react-bootstrap';
import { BsInfoCircleFill } from 'react-icons/bs';

import { FeesSchedule, FeesScheduleTier } from '@qwealth/qdata';

import { usePartnerLookup } from 'hooks/usePartnerLookup';
import { numberToYesOrNoConvertor, numberToBooleanConvertor } from 'utils/DataUtil';
import {
  InfoSection,
  InfoDescriptionBox,
  ToolTipLi,
  ToolTipBox,
  ToolTipKeySpan,
  ToolTipValueSpan,
} from 'components/common/InfoSection';
import { TitlePrimary } from 'components/common/Title';

interface Props {
  schedule: FeesSchedule;
}

const FeeScheduleToolTip: FC<Props> = ({ schedule }) => {
  const partnerLookup = usePartnerLookup();

  return (
    <InfoSection>
      <BsInfoCircleFill />
      <InfoDescriptionBox>
        <TitlePrimary>Fee Schedule Details</TitlePrimary>
        <ul style={{ listStyle: 'none' }}>
          <ToolTipLi>
            <ToolTipKeySpan>Fee Schedule Name:</ToolTipKeySpan>
            <ToolTipValueSpan>{schedule.feeScheduleName}</ToolTipValueSpan>
          </ToolTipLi>
          <ToolTipLi>
            <ToolTipKeySpan>Partner:</ToolTipKeySpan>
            <ToolTipValueSpan>
              {partnerLookup.get(schedule.partnerID)?.partnerName}
            </ToolTipValueSpan>
          </ToolTipLi>
          <ToolTipLi>
            <ToolTipKeySpan>Approval Status:</ToolTipKeySpan>
            <ToolTipValueSpan>{schedule.scheduleStatus}</ToolTipValueSpan>
          </ToolTipLi>
          <ToolTipLi>
            <ToolTipKeySpan>Schedule Type:</ToolTipKeySpan>
            <ToolTipValueSpan>{schedule.scheduleType}</ToolTipValueSpan>
          </ToolTipLi>
          <ToolTipLi>
            <ToolTipKeySpan>Default Schedule:</ToolTipKeySpan>
            <ToolTipValueSpan>
              <Form.Check
                id="isDefaultSwitch"
                type="switch"
                readOnly
                checked={numberToBooleanConvertor(schedule.isDefault)}
              />
            </ToolTipValueSpan>
          </ToolTipLi>
          <ToolTipLi>
            <ToolTipKeySpan>Do you charge a minimum fee?</ToolTipKeySpan>
            <ToolTipValueSpan>{numberToYesOrNoConvertor(schedule.hasMinimumFee)}</ToolTipValueSpan>
          </ToolTipLi>
        </ul>
        <TitlePrimary>Tiers</TitlePrimary>
        <ul style={{ listStyle: 'none' }}>
          {schedule.feeTier &&
            schedule.feeTier.map((tier: FeesScheduleTier) => (
              <li key={tier.id} style={{ marginBottom: '1rem' }}>
                <ToolTipBox>
                  <ToolTipKeySpan>Minimum Amount:</ToolTipKeySpan>
                  <ToolTipValueSpan>{`$${tier.tierMinAmount}`}</ToolTipValueSpan>
                </ToolTipBox>
                <ToolTipBox>
                  <ToolTipKeySpan>Maximum Amount:</ToolTipKeySpan>
                  <ToolTipValueSpan>{`$${tier.tierMaxAmount}`}</ToolTipValueSpan>
                </ToolTipBox>
                <ToolTipBox>
                  <ToolTipKeySpan>Fee Rate:</ToolTipKeySpan>
                  <ToolTipValueSpan>{`${tier.feeRate}%`}</ToolTipValueSpan>
                </ToolTipBox>
              </li>
            ))}
        </ul>
      </InfoDescriptionBox>
    </InfoSection>
  );
};

export default FeeScheduleToolTip;

import { styled, Box, themeGet } from '@qwealth/qcore';

export const InfoDescriptionBox = styled(Box)`
  position: absolute;
  content: '';
  top: 36px;
  left: -40px;
  right: -40px;
  width: max-content;
  min-width: 500px;
  max-width: 100%;
  overflow-y: auto;
  max-height: 500px;
  box-shadow: 0 0 10px -3px #000;
  display: flex;
  flex-direction: column;
  padding: 20px;
  margin-bottom: 10px;
  z-index: 10;
  background: ${themeGet('colors.gray.4')};
  line-height: 26px;
  font-weight: 500 b {
    margin-bottom: 10px;
  }
  p {
    margin-bottom: 10px;
  }
  ul {
    padding-left: 15px;
    li {
      font-weight: lighter;
    }
  }
  @media (max-width: 767px) {
    max-width: 100%;
    min-width: 320px;
  }
`;

export const InfoSection = styled(Box)`
  position: relative;

  svg {
    margin-left: 5px;
  }

  div {
    display: none;
  }

  &:hover {
    div {
      display: block;
    }

    &::after {
      display: block;
    }
  }

  &::after {
    content: '';
    position: absolute;
    display: none;
    border-color: transparent ${themeGet('colors.gray.0')} transparent transparent;
    border-style: solid;
    border-width: 12px;
    width: 0;
    height: 0;
    left: 5px;
    -ms-transform: rotate(180deg);
    transform: rotate(90deg);
    bottom: -15px;
    z-index: 99;
  }
`;

export const ToolTipLi = styled.li`
  display: flex;
  justify-content: space-between;
`;

export const ToolTipBox = styled(Box)`
  display: flex !important;
  justify-content: space-between;
`;

export const ToolTipKeySpan = styled.span`
  width: 200px;
  font-weight: bold;
`;

export const ToolTipValueSpan = styled.span`
  width: 250px;
`;

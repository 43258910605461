import { createReducer } from '@reduxjs/toolkit';
import { RTQ } from '@qwealth/qdata';
import { addIpsToRtq, addRtq, loadHouseholdRtqs } from '../actions/RTQ';

export type RTQState = Record<string, Array<RTQ>>;

export default createReducer({} as RTQState, (builder) => {
  builder.addCase(addRtq, (state, action) => {
    const rtq = action.payload;
    if (!rtq) {
      return state;
    }

    const QID = (rtq.householdQID ? rtq.householdQID : rtq.QID) ?? '';
    const currentRtqs = state[QID];
    let newRtqs = { [QID]: [rtq] };

    if (QID && currentRtqs && currentRtqs.length > 0) {
      const updatedRtqs = currentRtqs.map(currentRtq => {
        return currentRtq.id === rtq.id ? { ...rtq } : { ...currentRtq };
      });

      newRtqs = { [QID]: updatedRtqs };
    }

    return {
      ...state,
      ...newRtqs,
    };
  });

  builder.addCase(loadHouseholdRtqs, (state, action) => {
    return { ...state, ...action.payload };
  });

  builder.addCase(addIpsToRtq, (state, action) => {
    const ips = action.payload;
    if (!ips) {
      return state;
    }

    const newRtqs: RTQState = { ...state };
    Object.keys(state).forEach(QID => {
      newRtqs[QID] = state[QID].map(currentRtq => {
        if (currentRtq.id === ips.rtqID) {
          return { ...currentRtq, ips };
        } else {
          return { ...currentRtq };
        }
      });
    });

    return newRtqs;
  });
});

import axios from 'axios';
import { Dispatch } from 'redux';
import { omit } from 'lodash';
import { convertToRaw } from 'draft-js';
import { createAction } from '@reduxjs/toolkit';
import { getPartnerShortCode, responseEntityMapper } from '@qwealth/qcore';
import { apiUrl, AppThunkDispatch, QNote } from '@qwealth/qdata';
import { deleteFiles, uploadFiles } from '@qwealth/qvault';
import { errorHandler } from '../../services/axiosService';
import { getHouseholdQNotes } from '../data-layer/qnote';

const PUT_HOUSEHOLD_QNOTES = 'PUT_HOUSEHOLD_QNOTES';
const ADD_HOUSEHOLD_QNOTE = 'ADD_HOUSEHOLD_QNOTE';
const DELETE_HOUSEHOLD_QNOTE = 'DELETE_HOUSEHOLD_QNOTE';

export const putHouseholdQNotes = createAction<Array<QNote>>(PUT_HOUSEHOLD_QNOTES);
export const addHouseholdQNote = createAction<QNote>(ADD_HOUSEHOLD_QNOTE);
export const deleteHouseholdQnote = createAction<number>(DELETE_HOUSEHOLD_QNOTE);

export const loadHouseholdNotes = (householdId: string) => async (dispatch: AppThunkDispatch): Promise<void> => {
  try {
    const qnotes = await getHouseholdQNotes(householdId);
    dispatch(putHouseholdQNotes(qnotes));
  } catch (error) {
    errorHandler(dispatch, 'Could not load household notes')(error);
  }
};

export const saveQNote = (qnote) => (dispatch: AppThunkDispatch): Promise<void> => {
  const {pendingAttachments, id, householdQID } = qnote;
  const noteContent = qnote.noteContent.getCurrentContent();
  const payload = {
    ...omit(qnote, [
      'attachments',
      'pendingAttachments',
      's3Attachments',
      'attachmentsToDelete',
    ]),
    noteContent: JSON.stringify(convertToRaw(noteContent)),
  };
  const saveFn = qnote.id
    ? axios.patch(`${apiUrl}/qnotes/${id}`, payload)
    : axios.post(`${apiUrl}/qnotes`, payload);
  return saveFn
    .then(response => responseEntityMapper<QNote>(response))
    .then(async savedNote => {
      dispatch(addHouseholdQNote(savedNote));
      if (pendingAttachments) {
        const partnerShortCode = getPartnerShortCode(householdQID);
        const path = `${partnerShortCode}/${householdQID}/Internal/QNote Attachments/${savedNote.id}/`;
        await uploadFiles(pendingAttachments, path);
      }
    })
    .then(async () => {
      if (qnote.attachmentsToDelete) {
        await deleteFiles(qnote.attachmentsToDelete);
      }
    })
    .catch(errorHandler(dispatch, 'Could not save QNote, please contact support'));
};

export const deleteQNote = (id: number) => (dispatch: Dispatch): Promise<void> =>
  axios
    .delete(`${apiUrl}/qnotes/${id}`)
    .then(() => {
      dispatch(deleteHouseholdQnote(id));
    })
    .catch(errorHandler(dispatch, 'Could not delete QNote, please contact support'));

import { Opportunity } from '@qwealth/qdata';
import { PUT_HOUSEHOLD_OPPORTUNITIES, PUT_OPPORTUNITY } from '../actions/householdOpportunities';

export default function (state: Array<Opportunity> = [], action): Array<Opportunity> {
  switch (action.type) {
    case PUT_HOUSEHOLD_OPPORTUNITIES: {
      const { opportunities } = action;
      const stateCopy = [...state];
      if (opportunities === 0) {
        return [];
      }
      if (Array.isArray(opportunities) || opportunities === undefined) {
        if (opportunities === undefined) {
          return stateCopy;
        }
        return stateCopy.concat(opportunities);
      }
      return [...state, opportunities];
    }
    case PUT_OPPORTUNITY: {
      const { opportunity } = action;
      const index = state.findIndex(({ id }) => id === opportunity.id);
      if (index > -1) {
        return [...state.slice(0, index), opportunity, ...state.slice(index + 1)];
      }
      return [...state, opportunity];
    }
    default:
      return state;
  }
}

import { createReducer } from '@reduxjs/toolkit';
import { ActiveFlow, ActiveStage, ActiveTask } from '@qwealth/qdata';
import { updateItemAndGetArrayImmutably } from '@qwealth/qcore';
import {
  putHouseholdActiveFlow,
  putStageOnCurrentActiveFlow,
  putTaskOnCurrentActiveFlowStage,
} from '../actions/householdActiveFlows';

interface HouseholdActiveFlowsState {
  activeFlow: ActiveFlow;
}

const defaultActiveFlow: ActiveFlow = {
  prospectHouseholdQID: '',
  prospectDefaultFlowID: -1,
  prospectFlowStatus: 0,
};

const defaultState: HouseholdActiveFlowsState = {
  activeFlow: defaultActiveFlow,
};

export default createReducer(defaultState, (builder) => {
  builder.addCase(putHouseholdActiveFlow, (state, action) =>
    ({...state, activeFlow: action.payload}));

  builder.addCase(putStageOnCurrentActiveFlow, (state, action) => {
    const activeStage = action.payload;
    const activeFlow: ActiveFlow = {
      ...state.activeFlow,
      stages: updateItemAndGetArrayImmutably(
        state.activeFlow.stages,
        (s: ActiveStage) => s.id === activeStage.id,
        (targetItem: ActiveStage) => (targetItem ? { ...targetItem, ...activeStage } : undefined),
      ),
    };
    return { ...state, activeFlow };
  });

  builder.addCase(putTaskOnCurrentActiveFlowStage, (state, action) => {
    const { activeTask, activeStageId } = action.payload;
    const activeFlow: ActiveFlow = {
      ...state.activeFlow,
      stages: updateItemAndGetArrayImmutably(
        state.activeFlow.stages,
        (s: ActiveStage) => s.id === activeStageId,
        (targetStage: ActiveStage) =>
          targetStage
            ? {
                ...targetStage,
                tasks: updateItemAndGetArrayImmutably(
                  targetStage.tasks,
                  (t: ActiveTask) => t.id === activeTask.id,
                  (targetTask: ActiveStage) =>
                    targetTask ? { ...targetTask, ...activeTask } : activeTask,
                ),
              }
            : undefined,
      ),
    };
    return { ...state, activeFlow };
  });
});

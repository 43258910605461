export const ALL_DATE_RANGE = 'all_time';

export const DAYS_OF_WEEK = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday'];

export const ASSIGN_TO_ME_RESPONSIBILITY = {
  label: 'Assigned To Me',
  value: 'assignedToMe',
};

export const CREATED_BY_ME_RESPONSIBILITY = {
  label: 'Created By Me',
  value: 'createdByMe',
};

export const RECURRENCE_PATTERN_TYPE = [
  { label: 'Never', value: 'never' },
  { label: 'Every Day', value: 'daily' },
  { label: 'Every Week', value: 'weekly' },
  { label: 'Every Month', value: 'relativeMonthly' },
  { label: 'Every Year', value: 'relativeYearly' },
];

let envPrefix = '';
if (window.location.href.includes('dev') || window.location.hostname === 'localhost') {
  envPrefix = 'dev.';
} else if (window.location.href.includes('staging')) {
  envPrefix = 'staging.';
}

export const DIGIT_URL = 'https://qwealth.d1g1t.com';
export const Q_CASH_URL = `https://${envPrefix}qcash.qwnext.com`;
export const Q_FOLIO_URL = `https://${envPrefix}qfolio.qwnext.com`;
export const Q_LIFE_URL = `https://${envPrefix}qlife.qwnext.com`;
export const Q_CONNECT_URL = `https://${envPrefix}qconnect.qwnext.com`;
export const SF_URL = 'https://qwealth.my.salesforce.com/';
export const CONQUEST_DASHBOARD_PATH = '/new-planning/:conquestPlanID/dashboard';
export const Q_NET_URL = 'https://quintessencewealth.sharepoint.com';
export const Q_FEEDBACK_URL = 'https://qfeedback.qwealth.com/';
export const Q_WIKI_URL = 'https://qcentral.wiki.qwealth.com/';
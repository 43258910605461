import { Dispatch } from 'redux';
import axios from 'axios';
import { omit } from 'lodash';

import { responseEntityMapper, responseListMapper } from '@qwealth/qcore';
import { FeesGroup, FeesGroupStructure } from '@qwealth/qdata';

import { errorHandler, REACT_APP_QWEALTH_API } from 'services/axiosService';
import { AppThunkDispatch, RootState } from 'data/store';

export const PUT_ALL_FEES_GROUPS = 'PUT_ALL_FEES_GROUPS';
export const PUT_ACTIVE_FEES_GROUP = 'PUT_ACTIVE_FEES_GROUP';
export const PUT_NEWLY_CREATED_FEES_GROUP = 'PUT_NEWLY_CREATED_FEES_GROUP';
export const RESET_FEES_GROUP = 'RESET_FEES_GROUP';
export const UPDATE_STRUCTURE_IN_GROUP_LIST = 'UPDATE_STRUCTURE_IN_GROUP_LIST';
export const SET_FEE_GROUP_LOADING = 'SET_FEE_GROUP_LOADING';

const putAllFeesGroups = feesGroups => ({ type: PUT_ALL_FEES_GROUPS, feesGroups });
const putActiveFeesGroup = feesGroup => ({ type: PUT_ACTIVE_FEES_GROUP, feesGroup });
const setFeeGroupLoading = isLoading => ({ type: SET_FEE_GROUP_LOADING, isLoading });

export const putNewlyCreatedFeesGroup = newlyCreatedFeesGroup => ({
  type: PUT_NEWLY_CREATED_FEES_GROUP,
  newlyCreatedFeesGroup,
});
export const resetFeesGroup = () => ({ type: RESET_FEES_GROUP });
const updateStructureInGroupList = (feesGroupStructure: FeesGroupStructure) => ({
  type: UPDATE_STRUCTURE_IN_GROUP_LIST,
  feesGroupStructure,
});

export const fetchFeesGroups =
  (params: Record<string, string | number | undefined> = {}, id?: number) =>
  async (dispatch: Dispatch): Promise<FeesGroup[]> => {
    try {
      const response = await axios.get(`${REACT_APP_QWEALTH_API}/fees/groups/${id ?? ''}`, {
        params,
      });
      return responseListMapper<FeesGroup>(response);
    } catch (error) {
      errorHandler(dispatch, 'API Error. Failed getting Fee Groups')(error);
    }
    return [];
  };

export const loadFeesGroups =
  (params: Record<string, string | number | undefined> = {}, id?: number) =>
  async (dispatch: AppThunkDispatch, getState: () => RootState): Promise<FeesGroup[]> => {
    if (getState().feesGroups.isLoading) {
      return Promise.reject('In progress...');
    }
    dispatch(setFeeGroupLoading(true));
    const feeGroups = await dispatch(fetchFeesGroups(params, id));
    dispatch(putAllFeesGroups(feeGroups));
    dispatch(setFeeGroupLoading(false));
    return feeGroups;
  };

export const loadAllFeesGroups = () => (dispatch: AppThunkDispatch) => {
  return dispatch(fetchFeesGroups())
    .then(feesGroups => dispatch(putAllFeesGroups(feesGroups)))
    .catch(errorHandler(dispatch));
};

export const axiosFetchSingleFeesGroup = (feesGroupId: number) =>
  new Promise((resolve, reject) => {
    axios
      .get(`${REACT_APP_QWEALTH_API}/fees/groups/${feesGroupId}`)
      .then(res => resolve(responseEntityMapper<FeesGroup>(res)))
      .catch(e => reject(e));
  });

export const loadSingleFeesGroup = (feesGroupId: number) => (dispatch: Dispatch) => {
  return axiosFetchSingleFeesGroup(feesGroupId)
    .then(feesSchedule => {
      dispatch(putActiveFeesGroup(feesSchedule));
    })
    .catch(errorHandler(dispatch));
};

export const addOrUpdateFeesGroup = (feesGroup: FeesGroup) => dispatch => {
  const feesGroupData = omit(feesGroup, ['feeGroupStructure', 'showStructureModal']);

  const request = !feesGroup.id
    ? axios.post(`${REACT_APP_QWEALTH_API}/fees/groups/`, feesGroupData)
    : axios.patch(`${REACT_APP_QWEALTH_API}/fees/groups/${feesGroup.id}`, feesGroupData);
  return request
    .then(response => responseEntityMapper<FeesGroup>(response))
    .then((response: FeesGroup) => {
      if (!feesGroup.id) {
        dispatch(putNewlyCreatedFeesGroup(response));
      }
      dispatch(putActiveFeesGroup(response));
      dispatch(loadAllFeesGroups());
      return Promise.resolve(response);
    })
    .catch(e => {
      errorHandler(dispatch)(e);
      return Promise.reject(null);
    });
};

export const addOrUpdateFeesGroupStructure =
  (feesGroupStructure: Partial<FeesGroupStructure>) => (dispatch: AppThunkDispatch) => {
    const feesGroupStructureData = omit(feesGroupStructure, [
      !feesGroupStructure.id ? 'id' : '',
      'householdQID',
      !feesGroupStructure.feeGroupStructureEndDate ? 'feeGroupStructureEndDate' : '',
    ]);

    const request = !feesGroupStructure.id
      ? axios.post(`${REACT_APP_QWEALTH_API}/fees/groups/structure/`, feesGroupStructureData)
      : axios.patch(
          `${REACT_APP_QWEALTH_API}/fees/groups/structure/${feesGroupStructure.id}`,
          feesGroupStructureData,
        );

    return request
      .then(response => responseEntityMapper<FeesGroupStructure>(response))
      .then(response => {
        dispatch(updateStructureInGroupList(response));
        return Promise.resolve(response);
      })
      .catch(e => {
        errorHandler(dispatch)(e);
        return Promise.reject(null);
      });
  };

export const fetchFeeGroupStructures =
  (params: Record<string, string | number | undefined> = {}) =>
  async (dispatch: Dispatch): Promise<FeesGroupStructure[]> => {
    try {
      const response = await axios.get(`${REACT_APP_QWEALTH_API}/fees/groups/structure/`, {
        params,
      });
      return responseListMapper<FeesGroupStructure>(response);
    } catch (error) {
      errorHandler(dispatch, 'API Error. Failed getting Fee Group Structures')(error);
    }
    return [];
  };


import React, { FC, ReactElement } from 'react';
import { Box, styled } from '@qwealth/qcore';

interface AvatarProp {
  initials: string;
}

export const DefaultAvatar: FC<AvatarProp> = ({ initials }): ReactElement => (
  <Box
    borderRadius="round"
    height="50px"
    width="50px"
    bg="primary"
    fontSize="sectionTitle"
    fontWeight="bold"
    color="white"
    display="flex"
    alignItems="center"
    justifyContent="center"
  >
    {initials}
  </Box>
);

export const Avatar = styled.img`
  vertical-align: middle;
  width: 50px;
  height: 50px;
  border-radius: 50%;
`;

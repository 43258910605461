import { Dispatch } from 'redux';
import { createAction } from '@reduxjs/toolkit';
import { errorHandler } from 'services/axiosService';
import { getPortalMap } from '../data-layer/cognito';
import { PortalMap } from '../models/portalAccess';

const ADD_PORTAL_ACCESS = 'ADD_HOUSEHOLD_PORTAL';
export const addPortalAccess = createAction<PortalMap>(ADD_PORTAL_ACCESS);

export const loadPortalAccessList =
  (emails: Array<string>) =>
  (dispatch: Dispatch): Promise<void> => {
    return getPortalMap(emails)
      .then(newMap => {
        dispatch(addPortalAccess(newMap));
      })
      .catch(errorHandler(dispatch, 'Could not reload portal map'));
  };

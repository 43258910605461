import { createReducer } from '@reduxjs/toolkit';
import {
  putActivities,
  putActivity,
  removeActivity,
  setActivitiesLoading,
  updateActivity,
} from '../actions/activities';
import { QFeedActivity } from '../models/Activity';

interface State {
  allActivities: Array<QFeedActivity>;
  isLoading: boolean;
}

const initialState: State = {
  allActivities: [],
  isLoading: false,
}

export default createReducer(initialState, (builder) => {
  builder.addCase(setActivitiesLoading, (state, action) => ({...state, isLoading: action.payload}));

  builder.addCase(putActivities, (state, action) => ({...state, allActivities: action.payload}));

  builder.addCase(removeActivity, (state, action) => ({
    ...state,
    allActivities: state.allActivities.filter(a => a.id !== action.payload)
  }));

  builder.addCase(putActivity, (state, action) => ({
    ...state,
    allActivities: state.allActivities.concat([action.payload])
  }));

  builder.addCase(updateActivity, (state, action) => {
    const activity = action.payload;
    const updatedAllActivities = state.allActivities.map(currentActivity => {
      if (currentActivity.id !== activity.id) {
        return currentActivity;
      }
      return { ...activity };
    });
    return { ...state, allActivities: updatedAllActivities };
  });
});

import { createReducer } from '@reduxjs/toolkit';
import { QBias } from '@qwealth/qdata';
import { putHouseholdQbias } from '../actions/householdQbias';

interface State {
  [key: string]: QBias
}

export default createReducer({} as State, (builder) => {
  builder.addCase(putHouseholdQbias, (state, action) => {
      const qBiasMap = action.payload.reduce((map, qBias) => ({ ...map, [qBias.QID ?? '']: qBias }), {} as State);
      return { ...state, ...qBiasMap };
    })
});

import { IUser } from '@qwealth/qdata';
import { createReducer } from '@reduxjs/toolkit';
import { putUser, putUsers, removeUser, resetUsers, setLoading } from '../actions/users';

interface IState {
  initializedUsers: boolean;
  isLoading: boolean;
  users: IUser[];
}

const DEFAULT_STATE: IState = {
  initializedUsers: false,
  isLoading: false,
  users: [],
};

export default createReducer(DEFAULT_STATE, (builder) => {
  builder.addCase(resetUsers, () => ({ ...DEFAULT_STATE }));
  builder.addCase(removeUser, (state, action) => ({
    ...state,
    users: state.users.filter(user => action.payload.id !== user.id)
  }));
  builder.addCase(putUser, (state, action) => {
      const user = action.payload;
      const newUsers: IUser[] = state.users.slice();
      const index = newUsers.findIndex((item: IUser) => item.id === user.id);
      if (index === -1) {
        newUsers.push(user);
      } else {
        newUsers[index] = {
          ...newUsers[index],
          ...user,
        };
      }
      return {
        ...state,
        users: newUsers,
      };
  });
  builder.addCase(putUsers, (state, action) =>
    ({...state, users: action.payload, initializedUsers: true}));
  builder.addCase(setLoading, (state, action) =>
    ({...state, isLoading: action.payload}));
});

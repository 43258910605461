import axios from 'axios';
import { responseEntityMapper } from '@qwealth/qcore';
import { Household } from '@qwealth/qdata';
import { REACT_APP_QWEALTH_API } from '../../services/axiosService';

export const updateHouseholdRequest = (
  householdDetails: Partial<Household>,
): Promise<Household> =>
  new Promise((resolve, reject) => {
    axios
      .patch(`${REACT_APP_QWEALTH_API}/households/${householdDetails.QID}`, householdDetails)
      .then(response => responseEntityMapper<Household>(response))
      .then(household => resolve(household))
      .catch(e => reject(e));
  });

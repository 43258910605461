import axios from 'axios';
import { Calendar, Message, User } from '@microsoft/microsoft-graph-types';
import { Client, PageIterator } from '@microsoft/microsoft-graph-client';
import { getAccessAndIdToken } from '@qwealth/qcore';
import { AttachmentWrapper } from '@qwealth/qvault';
import { CalendarEventResponse, SVE } from 'data/data-layer/event';
import { MailWrapper } from '../../data/models/MailWrapper';

async function getAuthenticatedClient(): Promise<Client> {
  const authenticationResult = await getAccessAndIdToken();
  // @ts-ignore
  const { accessToken: newAccessToken } = authenticationResult;
  // Initialize Graph client
  return Client.init({
    // Use the provided access token to authenticate requests
    authProvider: done => {
      done(null, newAccessToken || null);
    },
  });
}

export async function getUserDetails(): Promise<User> {
  const client = await getAuthenticatedClient();
  return await client
    .api('/me')
    .select('id,displayName,givenName,mail,mailboxSettings,surname,userPrincipalName')
    .get();
}

export async function getUserAvatar(): Promise<Blob> {
  const client = await getAuthenticatedClient();
  return await client.api('/me/photo/$value').get();
}

export const getCalendarEvents = async (
  timeZone: string,
  startDateTime: string,
  endDateTime: string,
  calendarId?: string,
): Promise<Array<CalendarEventResponse>> => {
  const client = await getAuthenticatedClient();

  const base = calendarId ? `/me/calendars/${calendarId}/calendarView` : '/me/calendar/calendarView';

  const apiUrl = `${base}?top=500&startDateTime=${startDateTime}&endDateTime=${endDateTime}&$expand=singleValueExtendedProperties($filter=id%20eq%20'String%20{00020329-0000-0000-C000-000000000046}%20Name%20HouseholdId')`;

  const response = await client
    .api(apiUrl)
    .header('Prefer', `outlook.timezone="${timeZone}"`)
    .select(
      'subject,organizer,start,end,attendees,body,location,bodyPreview,extensions,singleValueExtendedProperties,isOnlineMeeting,onlineMeetingProvider,onlineMeetingUrl,recurrence,isAllDay',
    )
    .get();

  if (response['@odata.nextLink']) {
    // Presence of the nextLink property indicates more results are available
    // Use a page iterator to get all results
    const events: Array<CalendarEventResponse> = [];

    const pageIterator = new PageIterator(client, response, event => {
      events.push(event);
      return true;
    });

    await pageIterator.iterate();
    return events;
  }
  return response.value;
};

export const getHouseholdCalendarEvents = async (
  timeZone: string,
  startDateTime: string,
  endDateTime: string,
  householdId: string,
  order: string,
  calendarId?: string,
): Promise<Array<CalendarEventResponse>> => {
  const client = await getAuthenticatedClient();
  const base = calendarId ? `/me/calendars/${calendarId}/events` : '/me/events';

  const { value } = await client
    .api(
      `${base}?$filter=singleValueExtendedProperties/Any(ep: ep/id eq 'String {00020329-0000-0000-C000-000000000046} Name HouseholdId' and cast(ep/value, Edm.String) eq '${householdId}') and start/dateTime ge '${startDateTime}' and start/dateTime lt '${endDateTime}'`,
    )
    .header('Prefer', `outlook.timezone="${timeZone}"`)
    .select(
      'subject,organizer,start,end,attendees,body,location,bodyPreview,singleValueExtendedProperties,webLink,extensions,isOnlineMeeting,onlineMeetingProvider,onlineMeetingUrl,recurrence,isAllDay',
    )
    .orderby(`start/dateTime ${order}`)
    .top(50)
    .get();

  return value;
};

export const getAccessibleCalendars = async (): Promise<Calendar[]> => {
  const client = await getAuthenticatedClient();
  return await client
    .api('/me/calendars')
    .get()
    .then(({ value }) => value);
};

export const findSharedCalendarId = (calendars: Calendar[], email: string): string | undefined =>
  calendars.find(({ canEdit, owner }) => canEdit && owner?.address === email)?.id

export const postCalendarEvent = async (
  event: CalendarEventResponse,
  calendarId?: string
): Promise<CalendarEventResponse> => {
  const client = await getAuthenticatedClient();
  const url = calendarId ? `/me/calendars/${calendarId}/events` : '/me/calendar/events';
  return await client
    .api(url)
    .expand(
      'singleValueExtendedProperties($filter=id%20eq%20\'String%20%7B00020329-0000-0000-C000-000000000046%7D%20Name%20HouseholdId\')',
    )
    .post(JSON.stringify(event));
};

export const deleteCalendarEvent = async (id: string, calendarId?: string): Promise<void> => {
  const client = await getAuthenticatedClient();
  const url = calendarId ? `/me/calendars/${calendarId}/events/${id}` : `/me/events/${id}`;
  return await client.api(url).delete();
};

export const patchCalendarEvent = async (
  event: CalendarEventResponse | { singleValueExtendedProperties: Array<SVE> },
  eventId: string,
  calendarId?: string,
): Promise<CalendarEventResponse> => {
  const client = await getAuthenticatedClient();
  const url = calendarId ? `/me/calendars/${calendarId}/events/${eventId}` : `/me/events/${eventId}`;
  return await client
    .api(url)
    .expand(
      'singleValueExtendedProperties($filter=id%20eq%20\'String%20%7B00020329-0000-0000-C000-000000000046%7D%20Name%20HouseholdId\')',
    )
    .update(JSON.stringify(event));
};

export const getMailFromSenders = async (
  emails: Array<string>,
): Promise<{
  messageList: Message[];
  nextLink: string;
}> => {
  const client = await getAuthenticatedClient();

  return await client
    .api('/me/messages')
    .search(`"participants: ${emails.join(' OR ')}"`)
    .get()
    .then(result => ({ messageList: result.value, nextLink: result['@odata.nextLink'] }));
};

export const getMailByQuery = async (
  searchQuery: string,
): Promise<{
  messageList: Message[];
  nextLink: string;
}> => {
  const client = await getAuthenticatedClient();

  return await client
    .api('/me/messages')
    .search(searchQuery)
    .get()
    .then(result => ({ messageList: result.value, nextLink: result['@odata.nextLink'] }));
};

export const getMail = async (): Promise<{
  messageList: Message[];
  nextLink: string;
}> => {
  const client = await getAuthenticatedClient();

  return await client
    .api('/me/messages')
    .get()
    .then(result => ({ messageList: result.value, nextLink: result['@odata.nextLink'] }));
};

export const getMoreMessages = async (
  nextLink: string,
): Promise<{
  messageList: Message[];
  nextLink: string;
}> => {
  const client = await getAuthenticatedClient();

  return await client
    .api(nextLink)
    .get()
    .then(result => ({ messageList: result.value, nextLink: result['@odata.nextLink'] }));
};

export async function sendMail(mail: MailWrapper): Promise<void> {
  const client = await getAuthenticatedClient();
  await client.api('/me/sendMail').post(mail);
}

export async function draftMessage(message: Message): Promise<Message> {
  const client = await getAuthenticatedClient();
  return await client.api('/me/messages').header('Prefer', 'IdType="ImmutableId"').post(message);
}

export async function sendMessage(id: string): Promise<void> {
  const client = await getAuthenticatedClient();
  await client.api(`/me/messages/${id}/send`).post(null);
}

export async function getMessageAsMime(id: string): Promise<string> {
  const client = await getAuthenticatedClient();
  return await client.api(`/me/messages/${id}/$value`).get();
}

function blobToBase64(blob): Promise<string | ArrayBuffer | null> {
  return new Promise(resolve => {
    const reader = new FileReader();
    reader.onloadend = () => {
      const raw = reader.result as string;
      const base64WithoutTags = raw.substr(raw.indexOf(',') + 1).trim();
      resolve(base64WithoutTags);
    };
    reader.readAsDataURL(blob);
  });
}

export async function sendMailWithAttachments(
  mail: Message,
  attachments: Array<AttachmentWrapper>,
): Promise<string> {
  const client = await getAuthenticatedClient();
  return await client
    .api('/me/messages')
    .header('Prefer', 'IdType="ImmutableId"')
    .post(mail)
    .then(({ id }) =>
      Promise.all(
        attachments.map(async ({ fileName, blob }) => {
          const arrayBuffer = await blob!.arrayBuffer();
          if (arrayBuffer.byteLength < 4 * 1000000) {
            const contentBytes = await blobToBase64(blob);
            return client
              .api(`/me/messages/${id}/attachments`)
              .header('Content-type', 'application/json')
              .post({
                '@odata.type': '#microsoft.graph.fileAttachment',
                name: fileName,
                contentType: 'binary',
                contentBytes,
              });
          }
          return client
            .api(`/me/messages/${id}/attachments/createUploadSession`)
            .post({
              AttachmentItem: {
                attachmentType: 'file',
                name: fileName,
                size: arrayBuffer!.byteLength,
                contentType: 'binary',
              },
            })
            .then(({ uploadUrl }) =>
              axios.put(uploadUrl, arrayBuffer, {
                headers: {
                  'Content-Range': `bytes 0-${arrayBuffer!.byteLength - 1}/${
                    arrayBuffer!.byteLength
                  }`,
                },
              }),
            );
        }),
      ).then(async () => {
        await client.api(`/me/messages/${id}/send`).post(null);
        return id;
      }),
    );
}

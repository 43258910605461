import { createReducer } from '@reduxjs/toolkit';
import { putHouseholdMetadata, setLoading } from '../actions/householdMetadata';
import { initialState } from '../models/HouseholdMetadataState';
import { deleteHouseholdNote, putHouseholdNote, putHouseholdNotes } from '../actions/note';

export default createReducer(initialState, (builder) => {
  builder.addCase(putHouseholdMetadata, (state, action) => ({...state, ...action.payload}));

  builder.addCase(setLoading, (state, action) => ({...state, isLoading: action.payload}));

  builder.addCase(deleteHouseholdNote, (state, action) => {
      return { ...state, notes: state.notes.filter(({ id }) => id !== action.payload ) };
  });

  builder.addCase(putHouseholdNotes, (state, action) => ({...state, notes: action.payload}));

  builder.addCase(putHouseholdNote, (state, action) => {
    const note = action.payload;
    let { notes } = state;
    const index = notes.map(({ id }) => id).indexOf(note.id);
    if (index > -1) {
      notes = notes.map(n => (n.id === note.id ? note : n));
    } else {
      notes = [...notes, note];
    }

    return { ...state, notes };
  });
});

import { createReducer } from '@reduxjs/toolkit';
import { ContactWithRelations, Note } from '@qwealth/qdata';
import {
  putContactsLoadingProps,
  putActiveContact,
  setIsSavingContact,
  resetContact,
  putContactsErrorProps,
} from '../actions/contacts';
import { deleteContactNote, putContactNote, putContactNotes } from '../actions/note';

const defaultContact: ContactWithRelations = {
  contactPhone: [],
  contactType: 'Household Member',
  dateOfBirth: '',
  contactEmail: [],
  firstName: '',
  lastName: '',
  QID: '',
  partnerID: -1,
  status: 'Active',
  related: [],
  notes: [],
  hasTrustedContact: 0,
  householdMembers: [],
  nbinBankAccountDetails: []
};

interface IContactsState {
  contact: ContactWithRelations;
  loading: {
    contacts: boolean;
    contact: boolean;
    contactRelatedContacts: boolean;
    contactNotes: boolean;
  };
  error: {
    contact: boolean;
  };
  isSavingContact: boolean;
}

const initialState: IContactsState = {
  contact: defaultContact,
  loading: {
    contacts: false,
    contact: false,
    contactRelatedContacts: false,
    contactNotes: false,
  },
  error: {
    contact: false
  },
  isSavingContact: false,
};

export default createReducer(initialState, (builder) => {
  builder.addCase(putContactNote, (state, action) => {
    const note = action.payload;
    let notes: Note[] = state.contact.notes ? state.contact.notes : [];
    const index = notes.map(({ id }) => id).indexOf(note.id);
    if (index > -1) {
      notes = notes.map(n => (n.id === note.id ? note : n));
    } else {
      notes = [...notes, note];
    }

    return { ...state, contact: { ...state.contact, notes } };
  });
  builder.addCase(putContactNotes, (state, action) => {
    const notes = action.payload;
    return { ...state, contact: { ...state.contact, notes }};
  });
  builder.addCase(deleteContactNote, (state, action) => {
    const noteId = action.payload;
    const { notes = [] } = state.contact;
    return {
      ...state,
      contact: { ...state.contact, notes: notes.filter(({ id }) => id !== noteId) },
    };
  });
  builder.addCase(resetContact, (state) => ({ ...state, contact: defaultContact }));
  builder.addCase(putContactsLoadingProps, (state, action) => ({
    ...state,
    loading: {
      ...state.loading,
      [action.payload.name]: action.payload.isLoading
    }
  }));
  builder.addCase(putContactsErrorProps, (state, action) => ({
    ...state,
    error: {
      ...state.error,
      [action.payload.name]: action.payload.isError
    }
  }));
  builder.addCase(putActiveContact, (state, action) =>
    ({...state, contact: { ...state.contact, ...action.payload }}));
  builder.addCase(setIsSavingContact, (state, action) => ({...state, isSavingContact: action.payload}));
});

import { updateItemAndGetArrayImmutably, deleteItemAndGetArrayImmutably } from '@qwealth/qcore';
import { FeesGroup, FeesGroupStructure, defaultFeesGroup, ACTIVE_END_DATE } from '@qwealth/qdata';
import {
  PUT_ALL_FEES_GROUPS,
  PUT_ACTIVE_FEES_GROUP,
  PUT_NEWLY_CREATED_FEES_GROUP,
  RESET_FEES_GROUP,
  UPDATE_STRUCTURE_IN_GROUP_LIST, SET_FEE_GROUP_LOADING,
} from 'data/actions/feesGroups';

interface IFeesGroupsState {
  initialized: boolean;
  isLoading: boolean;
  feesGroupList: Array<FeesGroup>;
  feesGroup: FeesGroup;
  newlyCreatedFeesGroup: FeesGroup | null;
}

const initialState: IFeesGroupsState = {
  initialized: false,
  isLoading: false,
  feesGroupList: [],
  feesGroup: defaultFeesGroup,
  newlyCreatedFeesGroup: null,
};

export default function (state = initialState, action): IFeesGroupsState {
  switch (action.type) {
    case PUT_ALL_FEES_GROUPS: {
      return { ...state, initialized: true, feesGroupList: action.feesGroups };
    }
    case SET_FEE_GROUP_LOADING: {
      return { ...state, isLoading: action.isLoading };
    }
    case PUT_ACTIVE_FEES_GROUP: {
      return { ...state, feesGroup: action.feesGroup };
    }
    case PUT_NEWLY_CREATED_FEES_GROUP: {
      const { newlyCreatedFeesGroup } = action;
      const doesAlreadyExist = state.feesGroupList.find(
        group => group.id === newlyCreatedFeesGroup.id,
      );
      return {
        ...state,
        newlyCreatedFeesGroup,
        feesGroupList: !doesAlreadyExist
          ? [...state.feesGroupList, newlyCreatedFeesGroup]
          : state.feesGroupList,
      };
    }
    case RESET_FEES_GROUP: {
      return { ...state, feesGroup: defaultFeesGroup, newlyCreatedFeesGroup: null };
    }
    case UPDATE_STRUCTURE_IN_GROUP_LIST: {
      const updatedStructure: FeesGroupStructure = action.feesGroupStructure;
      const feesGroupList = updateItemAndGetArrayImmutably(
        state.feesGroupList,
        (group: FeesGroup) => group.id === updatedStructure.feeGroupID,
        (groupWithUpdatedStructure?: FeesGroup) => {
          if (groupWithUpdatedStructure) {
            const newStructureList =
              updatedStructure.feeGroupStructureEndDate === ACTIVE_END_DATE
                ? [...groupWithUpdatedStructure.feeGroupStructure, updatedStructure]
                : groupWithUpdatedStructure.feeGroupStructure.filter(
                    structure => structure.id !== updatedStructure.id,
                  );
            return { ...groupWithUpdatedStructure, feeGroupStructure: newStructureList };
          } else {
            return groupWithUpdatedStructure;
          }
        },
      );
      return { ...state, feesGroupList };
    }
    default:
      return state;
  }
}

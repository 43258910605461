import { compose } from 'ramda';
import { getPartnerShortCode } from '@qwealth/qcore';
import {
  ApiResponse,
  apiUrl,
  CallApi,
  createApi,
  parseResponse,
  withAuth,
  withData,
  withHttpMethod,
} from '@qwealth/qdata';
import { uploadFiles } from '@qwealth/qvault';
import { MeetingNote, MeetingNoteResponse } from 'data/models/meetingNote';

const transformToMeetingNote = (values: MeetingNoteResponse): MeetingNote => {
  const { attendees, msGraphId, meetingName } = values;
  return {
    ...values,
    attendees: attendees.map(att => ({ label: att, value: att })),
    msGraphId: { label: meetingName, value: msGraphId },
  };
};

const transformToMeetingNoteResponse = (values: MeetingNote): MeetingNoteResponse => {
  const {
    msGraphId: { label, value, __isNew__: isNew },
    attendees,
  } = values;
  return {
    ...values,
    meetingName: label,
    msGraphId: isNew ? 'CreatedEvent' : value,
    attendees: attendees.map(att => att.value),
  };
};

export const meetingNotesApi = withAuth(createApi(`${apiUrl}/meetingNotes`));

export const meetingNotesQIDApi: CallApi = (QID, options) => meetingNotesApi(`QID/${QID}`, options);

export const meetingNotesIDApi: CallApi = (id, options) => meetingNotesApi(`${id}`, options);

export const parseQWealthAPIData = (response: ApiResponse<MeetingNoteResponse>): Array<MeetingNote> =>
  response.data.map(transformToMeetingNote);

export const fetchMeetingNoteById = async (id: number): Promise<MeetingNoteResponse> => {
  const response = await meetingNotesApi(`/${id}`);
  const parsedResponse = await parseResponse<ApiResponse<MeetingNoteResponse>>(response);
  return parsedResponse.data[0];
};

export const fetchMeetingNotesWithQID = async (QID: string): Promise<Array<MeetingNote>> => {
  const response = await meetingNotesQIDApi(QID);
  const parsedResponse = await parseResponse<ApiResponse<MeetingNoteResponse>>(response);
  const result = parseQWealthAPIData(parsedResponse);
  return Array.isArray(result) ? result : [result];
};

export const deleteMeetingNoteGraph = async (id: number): Promise<void> => {
  const api = compose(withHttpMethod('DELETE'))(meetingNotesApi);
  await api(id);
};

export const updateMeetingNote = async (meetingNote: MeetingNote): Promise<MeetingNote> => {
  const data = transformToMeetingNoteResponse(meetingNote);
  const api = compose(withHttpMethod('PATCH'), withData(data))(meetingNotesIDApi);
  const response = await api(meetingNote.id);
  const result = await parseResponse<ApiResponse<MeetingNote>>(response);
  return { ...result.data[0], attendees: meetingNote.attendees };
};

export const uploadMeetingNoteS3Files = async (
  QID: string,
  meetingNoteId: number,
  meetingNoteName: string,
  meetingNoteFiles: Array<File>,
): Promise<void> => {
  const partnerShortCode = getPartnerShortCode(QID);
  const path = `${partnerShortCode}/${QID}/Internal/Meeting Notes/${meetingNoteName}_${meetingNoteId}/`;
  await uploadFiles(meetingNoteFiles, path);
};

const generateMeetingNote = async (meetingNote: MeetingNote): Promise<MeetingNote> => {
  const data = transformToMeetingNoteResponse(meetingNote);
  const api = compose(withHttpMethod('POST'), withData(data))(meetingNotesApi);
  const response = await api();
  const parsedResponse = await parseResponse<ApiResponse<MeetingNoteResponse>>(response);
  return transformToMeetingNote(parsedResponse.data[0]);
};

export const startMeetingNoteProcess = async (
  meetingNote: MeetingNote,
  meetingNoteFiles: Array<File>,
): Promise<MeetingNote> => {
  const newMeetingNote = await generateMeetingNote(meetingNote);
  if (meetingNoteFiles) {
    await uploadMeetingNoteS3Files(
      meetingNote.QID,
      newMeetingNote.id,
      newMeetingNote.meetingName,
      meetingNoteFiles,
    );
  }
  return newMeetingNote;
};

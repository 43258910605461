import { IMA } from '@qwealth/qdata';

import {
  setActiveIma,
  setImaList,
  removeImaById
} from '../actions/IMA';
import { createReducer } from '@reduxjs/toolkit';

export default createReducer([] as IMA[], (builder) => {
  builder.addCase(setActiveIma, (state, action) => ([...state, action.payload]));

  builder.addCase(removeImaById, (state, action) => [...state.filter(i => i.id !== action.payload)]);

  builder.addCase(setImaList, (state, action) => ([...action.payload]));
});

import { SelectOption } from '@qwealth/qdata';
import { putResponsibilityOptions, setResponsibilityLoading } from '../actions/responsibilityFilter';
import { createReducer } from '@reduxjs/toolkit';

type State = {
  options: Array<SelectOption<string>>;
  isLoading: boolean;
  isInitialized: boolean;
};

const initialState: State = {
  isLoading: false,
  isInitialized: false,
  options: [],
};

export default createReducer(initialState, (builder) => {
  builder.addCase(setResponsibilityLoading, (state, action) => ({...state, isLoading: action.payload}));

  builder.addCase(putResponsibilityOptions, (state, action) => ({
    options: action.payload,
    isLoading: false,
    isInitialized: true
  }));
});

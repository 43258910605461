import { createReducer } from '@reduxjs/toolkit';
import { pushRecent } from '../actions/recentHistory';
import { QHistory } from '../models/QHistory';
import { immutablySwapItems } from '../helpers/data';

export const RECENT_HISTORY_KEY = 'recentlyViewed_v2';

function updateLocalStorage(newState) {
  localStorage.setItem(RECENT_HISTORY_KEY, JSON.stringify(newState));
  return newState;
}

const recentlyViewed = localStorage.getItem('recentlyViewed_v2');
const initialState = recentlyViewed ? JSON.parse(recentlyViewed) : [];

export default createReducer(initialState, (builder) => {
  builder.addCase(pushRecent, (state, action) => {
    const recent = action.payload;
    const { text } = recent;
    const index = state.findIndex(r => r.text?.toLowerCase() === text?.toLowerCase());
    if (index > -1) {
      const updatedText = state[index].text !== text ? text : '';
      return updateLocalStorage(immutablySwapItems<QHistory>(state, 0, index, updatedText));
    }
    return updateLocalStorage([recent, ...state.slice(0, 10)]);
  })
});

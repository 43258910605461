import axios from 'axios';
import { responseListMapper } from '@qwealth/qcore';
import { FeesRecord, FeeReviewsQueryParams } from '@qwealth/qdata';
import { REACT_APP_QWEALTH_API } from 'services/axiosService';

export const fetchFeeReviews = async (params: FeeReviewsQueryParams = {}): Promise<FeesRecord[]> => {
  const response = await axios.get(`${REACT_APP_QWEALTH_API}/fees/current/`, { params });
  const feesReviews = responseListMapper<FeesRecord>(response);
  return feesReviews;
};

import { CreateCaseError, Case, CategoryType, CasesUser } from '@qwealth/qdata';
import { LOADING_STATE, LoadingState, READY_STATE, ReadyState } from 'utils/LoadingState';

export type ActivePage = 'list' | 'create' | 'details';

export type PageStatus = LoadingState | Error | ReadyState;

export type CreateCasePageStatus = LoadingState | CreateCaseError | ReadyState;
export type CaseDetailPageStatus = LoadingState | CreateCaseError | ReadyState;

export type State = {
  cases: CasesModalState;
};

export interface CaseItem extends Case {
  requester: UserItem | undefined;
  responder: UserItem | undefined;
}

export type UserItem = CasesUser | LoadingState | Error;

export type CasesModalState = {
  activePage: ActivePage;
  caseCreatePage: CaseCreatePageState;
  caseViewPage: CaseDetailPageState;
  casesListPage: CasesListPageState;
};

export type CasesListPageState = {
  cases: Array<CaseItem>;
  hasNextPage: boolean;
  newCases: Array<Case>;
  pageNo: number;
  status: PageStatus;
};

export type CaseCreatePageForm = {
  attachments: Array<any>;
  category: CategoryType;
  description: string;
  files: Array<File>;
  priority: number;
  requesterId: number;
  qId: string;
  status: number;
  subcategory: string;
  subsubcategory: string;
  subject: string;
  type?: string;
  partnerShortCode?: string;
  email: string;
  name: string;
};

export type CaseDetailPageState = {
  case?: Case;
  form: CaseCreatePageForm;
  status: CaseDetailPageStatus;
};

export type CaseCreatePageState = {
  form: CaseCreatePageForm;
  status: CreateCasePageStatus;
};

const defaultFreshdeskRequesterId = parseInt(
  process.env.REACT_APP_QWEALTH_FRESHDESK_CREATOR_USER || '0',
  10,
);

export const defaultState: CasesModalState = {
  activePage: 'list',
  caseCreatePage: {
    form: {
      attachments: [],
      category: 'Fees',
      description: '',
      files: [],
      priority: 1,
      requesterId: defaultFreshdeskRequesterId,
      qId: '',
      status: 2,
      subcategory: '',
      subsubcategory: '',
      subject: '',
      type: 'Problem',
      email: '',
      name: '',
    },
    status: READY_STATE,
  },
  casesListPage: {
    cases: [],
    hasNextPage: false,
    newCases: [],
    pageNo: 1,
    status: LOADING_STATE,
  },
  caseViewPage: {
    form: {
      attachments: [],
      category: 'Fees',
      description: '',
      files: [],
      priority: 1,
      requesterId: defaultFreshdeskRequesterId,
      qId: '',
      status: 2,
      subcategory: '',
      subsubcategory: '',
      subject: '',
      type: 'Problem',
      email: '',
      name: '',
    },
    status: READY_STATE,
  },
};

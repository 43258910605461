import { createReducer } from '@reduxjs/toolkit';
import { Message } from '../models/Message';
import { deleteMessage, putMessages } from '../actions/messages';

const messagesReducer = createReducer([] as Array<Message>, (builder) => {
  builder.addCase(putMessages, (state, action) => {
    let updatedState = [...state];
    const currentMessageIds = state.map(m => m.id);
    action.payload.forEach(message => {
      const index = currentMessageIds.indexOf(message.id);
      if (index > -1) {
        updatedState = Object.assign([], updatedState, { [index]: message });
      } else {
        updatedState = [...updatedState, message];
      }
    });

    return updatedState;
  })
  builder.addCase(deleteMessage, (state, action) =>
    state.filter(message => message.id !== action.payload))
});

export default messagesReducer;

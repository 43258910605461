import React, { FC, ReactElement } from 'react';
import { Outlet } from 'react-router-dom';
import { AppLayout } from '@qwealth/qcore';
import Sidebar from './common/Sidebar';
import Header from './common/Header';
import Notifications from './common/Notifications';

const Layout: FC = (): ReactElement => {
  return (
    <AppLayout>
      <Sidebar />
      <AppLayout.Main p="large" display="flex" flexDirection="column">
        <Header />
        <Outlet />
        <Notifications />
      </AppLayout.Main>
    </AppLayout>
  );
}

export default Layout;

import React, { FC, ReactElement } from 'react';
import { StylesConfig } from 'react-select';
import { AggregateHousehold, SelectOption } from '@qwealth/qdata';
import {
  getHouseholdLabel,
  QSearchComponent,
  ResultMappers,
  SearchFilterParam,
} from '@qwealth/qsearch';
import { ExtendedSearchOption } from 'data/models/ExtendedSearchOption';

const filter: SearchFilterParam = {
  recordTypes: ['Household', 'ProspectHousehold'],
  householdStatuses: ['Active'],
};

interface Props {
  onChange: (household: ExtendedSearchOption) => void;
  value?: SelectOption<string>;
  disabled?: boolean;
  cacheOptions?: boolean;
  partnerIds?: Array<number>;
  customStyles?: StylesConfig;
}

const householdMapper = (household: AggregateHousehold): ExtendedSearchOption => ({
  value: household.QID as string,
  label: getHouseholdLabel(household),
  household
});

const QSearchMapper: ResultMappers<string> = {
  householdMapper: householdMapper,
  prospectHouseholdMapper: householdMapper,
};

const HouseholdSearch: FC<Props> = ({
                                      value,
                                      onChange,
                                      disabled,
                                      cacheOptions,
                                      partnerIds,
                                      customStyles
}): ReactElement => (
  <QSearchComponent
    cacheOptions={cacheOptions}
    onChange={(selectedHousehold) => {
      onChange(selectedHousehold as ExtendedSearchOption);
    }}
    searchMapper={QSearchMapper}
    searchFilters={{ ...filter, partnerIds }}
    value={value}
    disabled={disabled}
    placeholder="Household Name/QID"
    noOptionsMessage="Type to search..."
    customStyles={customStyles}
  />
);

export default HouseholdSearch;

import { createReducer } from '@reduxjs/toolkit';
import { QNote } from '@qwealth/qdata';
import { orderBy } from 'lodash';
import moment from 'moment';
import { updateItemAndGetArrayImmutably } from '@qwealth/qcore';
import { putHouseholdQNotes, addHouseholdQNote, deleteHouseholdQnote } from '../actions/qnotes';

const sortNotes = notes => orderBy(notes, note => moment(note.noteDate), 'desc');

interface State {
  householdNotes: Array<QNote>;
  qNotesInitialized: boolean;
}

const defaultState: State = {
  qNotesInitialized: false,
  householdNotes: []
};

export default createReducer(defaultState, (builder) => {
  builder.addCase(putHouseholdQNotes, (state, action) =>
    ({ householdNotes: sortNotes(action.payload), qNotesInitialized: true }));

  builder.addCase(addHouseholdQNote, (state, action) => {
    const qnote = action.payload;
    const newHouseholdNotes = updateItemAndGetArrayImmutably(
      state.householdNotes,
      note => note.id === qnote.id,
      note => (note ? { ...note, ...qnote } : qnote),
    );
    return { ...state, householdNotes: sortNotes(newHouseholdNotes) };
  });

  builder.addCase(deleteHouseholdQnote, (state, action) =>
    ({
      ...state,
      householdNotes: state.householdNotes.filter(note => note.id !== action.payload)
    }));
});

import axios from 'axios';
import { createAction } from '@reduxjs/toolkit';
import { responseListMapper, responseEntityMapper } from '@qwealth/qcore';
import { errorHandler, REACT_APP_QWEALTH_API } from '../../services/axiosService';
import { Dispatch } from 'redux';
import { MandateEWMRow } from '../models/reports/MandateEWM';

import { DashboardData, TransitionsRow } from '../models/reports/Transitions';
import { ReportData, ReportFlag, ReportName, ReportRows } from '../models/reports/main';

const SET_REPORT_DATA = 'SET_REPORT_DATA';
const SET_REPORT_LOADING = 'SET_REPORT_LOADING';

export const setReportData = createAction<{
  reportName: ReportName;
  data: ReportData;
}>(SET_REPORT_DATA);
export const setReportLoading = createAction<{
  reportFlag: ReportFlag;
  isLoading: boolean;
}>(SET_REPORT_LOADING);

export const loadMandateReportEWM =
  (iaCode: string) =>
  (dispatch: Dispatch): Promise<Dispatch> => {
    dispatch(setReportLoading({ reportFlag: 'isLoadingMandateEWM', isLoading: true}));
    let filter = '';
    if (iaCode !== 'All') {
      filter = `/iacode/${iaCode}`;
    }
    return axios
      .get(`${REACT_APP_QWEALTH_API}/reports/mandateEWM${filter}`)
      .then(response => responseListMapper<MandateEWMRow>(response))
      .then(records => dispatch(setReportData({ reportName: 'mandateReportEWM', data: records})))
      .catch(errorHandler(dispatch, 'Could not load report'))
      .finally(() => dispatch(setReportLoading({ reportFlag: 'isLoadingMandateEWM', isLoading: false})));
  };

export const loadReport =
  <T extends ReportRows>(reportField: ReportName, loadingField: ReportFlag, endpoint: string, fieldMapper?: (records: T[]) => T[]) =>
  (partnerID: number, iaCode: string, insuranceCode?: string, params: Record<string, string> = {}) =>
  (dispatch: Dispatch): Promise<Dispatch> => {
    dispatch(setReportLoading({ reportFlag: loadingField, isLoading: true }));
    if (partnerID) {
      params = { ...params, partnerID: partnerID.toString() };
    }
    if (iaCode !== 'All') {
      params = { ...params, iaCode };
    }
    if (insuranceCode && insuranceCode !== 'All') {
      params = { ...params, insuranceCode };
    }

    return axios
      .get(`${REACT_APP_QWEALTH_API}/reports/${endpoint}`, { params })
      .then(response => responseListMapper<T>(response))
      .then(records => {
        const reportRows: T[] = fieldMapper ? fieldMapper(records) : records;
        return dispatch(setReportData({ reportName: reportField, data: reportRows }));
      })
      .catch(errorHandler(dispatch, `Could not load ${endpoint} report`))
      .finally(() => dispatch(setReportLoading({ reportFlag: loadingField, isLoading: false })));
  };

export const fetchTransitionsDashboard =
  (params: Record<string, string>) =>
  (dispatch: Dispatch): Promise<DashboardData> =>
    axios
      .get(`${REACT_APP_QWEALTH_API}/reports/transitions`, { params })
      .then(response => responseEntityMapper<DashboardData>(response))
      .catch(errorHandler(dispatch, 'Could not load Transitions report'));

export const fetchTransitionsReport =
  <T extends TransitionsRow>(endpoint: string, params: Record<string, string>) =>
  (dispatch: Dispatch): Promise<T[]> =>
    axios
      .get(`${REACT_APP_QWEALTH_API}/reports/transitions/${endpoint}`, { params })
      .then(response => responseListMapper<T>(response))
      .catch(errorHandler(dispatch, `Could not load ${endpoint} report`));
    
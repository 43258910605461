import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { IPartner, loadPartners } from '@qwealth/qdata';
import { RootState } from 'data/store';
import { useDispatchOnMount } from '@qwealth/qcore';

export const usePartnerLookup = (): Map<number, IPartner> => {
  const { initialized: initializedPartnerList, partnerList } = useSelector(
    (state: RootState) => state.partners,
  );
  useDispatchOnMount(loadPartners, initializedPartnerList ? undefined : true);

  const [partnerLookup, setPartnerLookup] = useState<Map<number, IPartner>>(
    new Map<number, IPartner>(),
  );
  useEffect(() => {
    const partnerLookup = new Map<number, IPartner>(partnerList.map(i => [i.id!, i]));
    setPartnerLookup(partnerLookup);
  }, [partnerList]);

  return partnerLookup;
};

import { ProspectFlow, ProspectStage, ProspectTask } from '@qwealth/qdata';
import { deleteItemAndGetArrayImmutably, updateItemAndGetArrayImmutably } from '@qwealth/qcore';
import { isEmpty } from 'lodash';
import {
  PUT_ALL_PROSPECT_FLOWS,
  PUT_ACTIVE_PROSPECT_FLOW,
  PUT_NEWLY_CREATED_PROSPECT_FLOW,
  RESET_PROSPECT_FLOW,
  PUT_STAGE_ON_ACTIVE_PROSPECT_FLOW,
  DELETE_STAGE_FROM_ACTIVE_PROSPECT_FLOW,
  PUT_TASK_ON_ACTIVE_PROSPECT_FLOW_STAGE,
  DELETE_TASK_FROM_ACTIVE_PROSPECT_FLOW_STAGE,
} from '../actions/prospectFlows';

const defaultProspectFlow: ProspectFlow = {
  prospectFlowName: '',
  prospectFlowEffect: 'Global',
  prospectFlowPartnerID: null,
  prospectFlowStatus: 'Active',
  stage: [],
};

const sanitizeEmptyStagesAndTasks = (prospectFlow: ProspectFlow) => {
  prospectFlow.stage = prospectFlow.stage?.filter(s => !isEmpty(s));
  prospectFlow.stage = prospectFlow.stage?.map(s => {
    s.task = s.task?.filter(t => !isEmpty(t));
    return s;
  });
};

interface IProspectFlowsState {
  initialized: boolean;
  prospectFlowList: Array<ProspectFlow>;
  prospectFlow: ProspectFlow;
  newlyCreatedProspectFlow: ProspectFlow | null;
}

const initialState: IProspectFlowsState = {
  initialized: false,
  prospectFlowList: [],
  prospectFlow: defaultProspectFlow,
  newlyCreatedProspectFlow: null,
};

export default function (state = initialState, action): IProspectFlowsState {
  switch (action.type) {
    case PUT_ALL_PROSPECT_FLOWS: {
      return { ...state, initialized: true, prospectFlowList: action.prospectFlows };
    }
    case PUT_ACTIVE_PROSPECT_FLOW: {
      const { prospectFlow }: { prospectFlow: ProspectFlow } = action;
      sanitizeEmptyStagesAndTasks(prospectFlow);
      return { ...state, prospectFlow };
    }
    case PUT_NEWLY_CREATED_PROSPECT_FLOW: {
      const { newlyCreatedProspectFlow } = action;
      return { ...state, newlyCreatedProspectFlow };
    }
    case RESET_PROSPECT_FLOW: {
      return { ...state, prospectFlow: defaultProspectFlow, newlyCreatedProspectFlow: null };
    }
    case PUT_STAGE_ON_ACTIVE_PROSPECT_FLOW: {
      const { prospectStage } = action;
      const prospectFlow: ProspectFlow = {
        ...state.prospectFlow,
        stage: updateItemAndGetArrayImmutably(
          state.prospectFlow.stage,
          (s: ProspectStage) => s.id === prospectStage.id,
          (targetItem: ProspectStage) =>
            targetItem ? { ...targetItem, ...prospectStage } : prospectStage,
        ),
      };
      sanitizeEmptyStagesAndTasks(prospectFlow);
      return { ...state, prospectFlow };
    }
    case DELETE_STAGE_FROM_ACTIVE_PROSPECT_FLOW: {
      const { id } = action;
      const prospectFlow: ProspectFlow = {
        ...state.prospectFlow,
        stage: deleteItemAndGetArrayImmutably(
          state.prospectFlow.stage,
          (s: ProspectStage) => s.id === id,
        ),
      };
      return { ...state, prospectFlow };
    }
    case PUT_TASK_ON_ACTIVE_PROSPECT_FLOW_STAGE: {
      const { prospectTask, prospectStageId } = action;
      const prospectFlow: ProspectFlow = {
        ...state.prospectFlow,
        stage: updateItemAndGetArrayImmutably(
          state.prospectFlow.stage,
          (s: ProspectStage) => s.id === prospectStageId,
          (targetStage: ProspectStage) =>
            targetStage
              ? {
                  ...targetStage,
                  task: updateItemAndGetArrayImmutably(
                    targetStage.task,
                    (t: ProspectTask) => t.id === prospectTask.id,
                    (targetTask: ProspectStage) =>
                      targetTask ? { ...targetTask, ...prospectTask } : prospectTask,
                  ),
                }
              : undefined,
        ),
      };
      return { ...state, prospectFlow };
    }
    case DELETE_TASK_FROM_ACTIVE_PROSPECT_FLOW_STAGE: {
      const { id, prospectStageId } = action;
      const prospectFlow: ProspectFlow = {
        ...state.prospectFlow,
        stage: updateItemAndGetArrayImmutably(
          state.prospectFlow.stage,
          (s: ProspectStage) => s.id === prospectStageId,
          (targetStage: ProspectStage) =>
            targetStage
              ? {
                  ...targetStage,
                  task: deleteItemAndGetArrayImmutably(
                    targetStage.task,
                    (t: ProspectTask) => t.id === id,
                  ),
                }
              : undefined,
        ),
      };
      return { ...state, prospectFlow };
    }
    default:
      return state;
  }
}

import { addKyc, setKycMap } from '../actions/KYC';
import { createReducer } from '@reduxjs/toolkit';
import { updateKycRtq } from '../actions/RTQ';

export default createReducer({}, (builder) => {
  builder.addCase(updateKycRtq, (state, action) => {
    const rtq = action.payload;
    if (!rtq) {
      return state;
    }

    const QID = rtq.QID ?? '';
    const currentKyc = state[QID];
    const rtqs = currentKyc?.rtq;
    const newRtqs = rtqs?.map(localRtq => {
      if (localRtq.id === rtq.id) {
        return { ...localRtq, ...rtq };
      } else {
        return { ...localRtq };
      }
    });

    const newKyc = { [QID]: { ...currentKyc, rtq: newRtqs } };
    return {
      ...state,
      ...newKyc,
    };
  });

  builder.addCase(addKyc, (state, action) => {
    const kyc = action.payload;

    if (!kyc) {
      return state;
    }

    const QID = kyc.QID;
    const currentKyc = state[QID];
    const newKyc = { [QID]: { ...currentKyc, ...kyc } };

    return {
      ...state,
      ...newKyc,
    };
  });

  builder.addCase(setKycMap, (state, action) => {
    const kycMap = action.payload;
    if (!kycMap) {
      return state;
    }

    return {
      ...state,
      ...kycMap,
    };
  });
});

import axios from 'axios';
import { omit } from 'lodash';
import { Dispatch } from 'redux';
import { createAction } from '@reduxjs/toolkit';
import { getPartnerShortCode, responseEntityMapper } from '@qwealth/qcore';
import {
  AuthorizedIndividualType,
  IAddressDto,
  IPersonDto,
  ContactWithRelations,
  Email,
  IHouseholdDto,
  MemberType,
  fetchRelatedContactsWithQID,
  deleteRelatedContact,
  RelatedContact,
  RELATED_CONTACT_TYPE,
  RelationshipType,
  TrustedRelationshipType,
  IHouseholdMemberDto
} from '@qwealth/qdata';
import {
  defaultDemographicFormData,
  NewRelatedContact,
  saveRelatedContactDocument
} from '@qwealth/kyc';
import { errorHandler, REACT_APP_QWEALTH_API } from '../../services/axiosService';
import { AppThunkDispatch, RootState } from '../store';
import { LongContactForm } from '../models/LongContactForm';
import { RelatedContactForm, saveRelatedContact } from './relatedContact';
import { RelationshipFormWithNewContactModel } from '../models/relatedContact';

const PUT_ACTIVE_CONTACT = 'PUT_ACTIVE_CONTACT';
const RESET_CONTACT = 'RESET_CONTACT';
const PUT_CONTACTS_LOADING_PROPS = 'PUT_CONTACTS_LOADING_PROPS';
const PUT_CONTACTS_ERROR_PROPS = 'PUT_CONTACTS_ERROR_PROPS';
const SET_IS_SAVING_CONTACT = 'SET_IS_SAVING_CONTACT';

export const putActiveContact = createAction<Partial<ContactWithRelations>>(PUT_ACTIVE_CONTACT);
export const putContactsLoadingProps = createAction<{ name: string; isLoading: boolean }>(PUT_CONTACTS_LOADING_PROPS);
export const putContactsErrorProps = createAction<{ name: string; isError: boolean }>(PUT_CONTACTS_ERROR_PROPS);
export const setIsSavingContact = createAction<boolean>(SET_IS_SAVING_CONTACT);
export const resetContact = createAction(RESET_CONTACT);

export const deleteContact = (qid: string) => (dispatch: AppThunkDispatch): Promise<void> =>
  axios
    .delete(`${REACT_APP_QWEALTH_API}/persons/${qid}`)
    .catch(errorHandler(dispatch));

export const loadSingleContact = (contactQID: string) => (dispatch: AppThunkDispatch): Promise<void> => {
  dispatch(putContactsLoadingProps({ name: 'contact', isLoading: true }));
  return axios
    .get(`${REACT_APP_QWEALTH_API}/persons/${contactQID}`)
    .then(response => responseEntityMapper<IPersonDto>(response))
    .then(async contact => {
      if (!contact) {
        dispatch(putContactsErrorProps({ name: 'contact', isError: true }));
        throw new Error('Invalid contact, please contact support');
      }

      const householdMember = (contact.householdMembers ?? [])[0];
      const memberType = householdMember?.memberType as MemberType;
      if (memberType) {
        dispatch(putActiveContact({ ...contact, memberType }));
      } else {
        dispatch(putActiveContact(contact));
      }
    })
    .catch(err => {
      dispatch(putContactsErrorProps({ name: 'contact', isError: true }));
      errorHandler(dispatch, 'Failed to load contact. Please contact support.')(err);
    })
    .finally(() => dispatch(putContactsLoadingProps({ name: 'contact', isLoading: false })));
};

export const loadRelatedContacts = (contactQID: string) => async (dispatch: AppThunkDispatch, getState: () => RootState): Promise<void> => {
  if (getState().contacts.loading.contactRelatedContacts) {
    return Promise.reject();
  }
  dispatch(putContactsLoadingProps({ name: 'contactRelatedContacts', isLoading: true }));
  try {
    const related = await fetchRelatedContactsWithQID([contactQID]).then(({ data }) => data);
    dispatch(putActiveContact({ related }));
  } catch (err) {
    await errorHandler(dispatch, 'Failed to load related contacts')(err);
  } finally {
    dispatch(putContactsLoadingProps({ name: 'contactRelatedContacts', isLoading: false }));
  }
}

export const saveNewRelatedContact = async (form: RelationshipFormWithNewContactModel): Promise<RelatedContact[]> => {
  const partnerShortCode = getPartnerShortCode(form.ownerQid);
  const s3DocumentLocation = await saveRelatedContactDocument(form, form.ownerQid, form.relationshipType as RelationshipType);
  const payload: NewRelatedContact = {
    partnerShortCode,
    contactType: RELATED_CONTACT_TYPE,
    ownerQid: form.ownerQid,
    relationshipType: form.relationshipType as RelationshipType,
    trustedRelationshipType: form.relationshipType === 'Trusted Contact' ? form.trustedRelationshipType as TrustedRelationshipType : undefined,
    authorizedIndividualType: form.relationshipType === 'Authorized Individual' ? form.authorizedIndividualType as AuthorizedIndividualType : undefined,
    status: 'Active',
    s3DocumentLocation,
    ...form.contact,
  };
  return await axios.post(`${REACT_APP_QWEALTH_API}/contact`, payload)
    .then(response => responseEntityMapper<IPersonDto>(response))
    .then(newContact => {
      if (newContact?.QID) {
        return fetchRelatedContactsWithQID([newContact.QID])
          .then(result => result.data);
      } else {
        throw new Error('Failed to create new related contact');
      }
    });
}

export const addRelatedContact = (form: RelationshipFormWithNewContactModel) => async (dispatch: Dispatch, getState: () => RootState): Promise<RelatedContact[]> => {
  dispatch(putContactsLoadingProps({ name: 'contactRelatedContacts', isLoading: true }));
  try {
    let savedRelatedContacts: RelatedContact[];
    if (form.isNewContact) {
      savedRelatedContacts = await saveNewRelatedContact(form);
    } else {
      savedRelatedContacts = await saveRelatedContact(form as RelatedContactForm);
    }
    const related = getState().contacts.contact.related ?? [];
    dispatch(putActiveContact({ related: [...related, ...savedRelatedContacts] }));
    return Promise.resolve(savedRelatedContacts);
  } catch (e) {
    await errorHandler(dispatch, 'Failed to save related contact')(e);
    return Promise.reject(e);
  } finally {
    dispatch(putContactsLoadingProps({ name: 'contactRelatedContacts', isLoading: false }));
  }
};

export const deleteRelated = (id: number) => async (dispatch: AppThunkDispatch, getState: () => RootState): Promise<void> => {
  const related = (getState().contacts.contact?.related ?? []).filter(r => r.id !== id);
  await deleteRelatedContact(id)
    .then(() => dispatch(putActiveContact({ related })))
    .catch(errorHandler(dispatch, 'Failed to delete related contact'));
}

export const addContact = (contactData: LongContactForm) => (dispatch: AppThunkDispatch): Promise<string | null> => {
  dispatch(setIsSavingContact(true));
  const { memberType, contactType, honorifics, gender, maritalStatus, iaCode, insuranceCode } = contactData;
  return axios
    .post(`${REACT_APP_QWEALTH_API}/contact`, {
      ...omit(contactData, ['selectedHousehold']),
      memberType: contactType === 'Household Member' ? memberType : undefined,
      iaCode: contactType === 'Household Member' ? iaCode : undefined,
      insuranceCode: contactType === 'Household Member' ? insuranceCode : undefined,
      honorifics: honorifics === 'Please Select' ? undefined : honorifics,
      gender: gender === defaultDemographicFormData.gender
        ? undefined
        : gender,
      maritalStatus: maritalStatus === defaultDemographicFormData.maritalStatus
        ? undefined
        : maritalStatus
    })
    .then(response => responseEntityMapper<IPersonDto>(response))
    .then(async contact => contact?.QID || null)
    .catch(async err => {
      await errorHandler(dispatch, 'Could not create new contact')(err);
      return null;
    })
    .finally(() => {
      dispatch(setIsSavingContact(false));
    });
};

export const updateContact = (person: Partial<IPersonDto>) => (dispatch: Dispatch): Promise<void> => axios
  .patch(`${REACT_APP_QWEALTH_API}/persons/${person.QID}`, person)
  .then(response => responseEntityMapper<IPersonDto>(response))
  .then((savedContact) => dispatch(putActiveContact(savedContact)))
  .catch(errorHandler(dispatch, 'Could not save contact data'));

export const addHouseholdMember = (householdMemberData: Partial<IHouseholdMemberDto>, personQID: string) =>
  (dispatch: AppThunkDispatch): Promise<void> => {
    dispatch(setIsSavingContact(true));
    return axios
      .post(`${REACT_APP_QWEALTH_API}/persons/${personQID}/households/`, householdMemberData)
      .catch(errorHandler(dispatch))
      .finally(() => {
        dispatch(setIsSavingContact(false));
      });
  };

interface ConvertResponse {
  contact: IPersonDto;
  email: Email;
  household: IHouseholdDto;
}

interface ConvertProps {
  QID: string;
  householdName: string;
  primaryEmail?: string;
  prospectFlowID?: number;
  address?: IAddressDto;
  iaCode?: string;
  insuranceCode?: string;
}

export const convertContactToProspectHousehold = (payload: ConvertProps) =>
  (dispatch: AppThunkDispatch): Promise<string | null> =>
    axios
      .post(`${REACT_APP_QWEALTH_API}/convertContact`, payload)
      .then(response => responseEntityMapper<ConvertResponse>(response))
      .then(result => result.household.QID as string)
      .catch(err => {
        errorHandler(dispatch, 'Failed to convert lead to prospect')(err);
        return null;
      });

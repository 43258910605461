import React, { FC, ReactElement } from 'react';
import { RFFSelectInput2, useFieldAsState } from '@qwealth/qcore';
import { usePartnerIACodes } from 'hooks/usePartnerIACodes';

const fieldSubscription = { value: true, touched: true, error: true };

const InsuranceCodeField: FC = (): ReactElement => {
  const [selectedPartnerId] = useFieldAsState<number>('partnerID');
  const { insuranceCodeOptions } = usePartnerIACodes(selectedPartnerId as number);

  return (
    <RFFSelectInput2
      name="insuranceCode"
      subscription={fieldSubscription}
      label="Insurance Code*"
      inputFieldProps={{
        data: [{ label: 'Select Insurance Code', value: '' }, ...insuranceCodeOptions],
        toValue: opt => opt.value,
        toLabel: opt => opt.label
      }}
    />
  );
};

export default InsuranceCodeField;

import axios from 'axios';
import { omit } from 'lodash';
import { createAction } from '@reduxjs/toolkit';
import { responseEntityMapper } from '@qwealth/qcore';
import {
  ActiveFlow,
  ActiveStage,
  ActiveTask,
  ProspectFlow,
  QTask,
  ProspectTask,
} from '@qwealth/qdata';
import { errorHandler, REACT_APP_QWEALTH_API } from 'services/axiosService';
import { AppThunkDispatch } from '../store';
import { putActivity } from './householdActivities';
import { updateProspectTaskRequest } from './prospectFlows';

const PUT_HOUSEHOLD_ACTIVE_FLOW = 'PUT_HOUSEHOLD_ACTIVE_FLOW';
const PUT_STAGE_ON_CURRENT_ACTIVE_FLOW = 'PUT_STAGE_ON_CURRENT_ACTIVE_FLOW';
const PUT_TASK_ON_CURRENT_ACTIVE_FLOW_STAGE = 'PUT_TASK_ON_CURRENT_ACTIVE_FLOW_STAGE';

export const putHouseholdActiveFlow = createAction<ActiveFlow>(PUT_HOUSEHOLD_ACTIVE_FLOW);
export const putStageOnCurrentActiveFlow = createAction<ActiveStage>(PUT_STAGE_ON_CURRENT_ACTIVE_FLOW);
export const putTaskOnCurrentActiveFlowStage = createAction<{ activeTask: ActiveTask; activeStageId: number; }>(PUT_TASK_ON_CURRENT_ACTIVE_FLOW_STAGE);

const getActiveFlowMergedWithProspectFlow = (
  activeFlow: ActiveFlow,
  defaultFlow: ProspectFlow,
): ActiveFlow => {
  activeFlow.prospectDefaultFlow = omit(defaultFlow, ['stage']);
  activeFlow.stages &&
    activeFlow.stages.forEach(stage => {
      const defaultStage = defaultFlow.stage?.find(s => s.id === stage.prospectDefaultStageID);
      if (defaultStage) {
        stage.prospectDefaultStage = omit(defaultStage, ['task']);
        stage.tasks &&
          stage.tasks.forEach(task => {
            const defaultTask = defaultStage.task?.find(t => t.id === task.prospectDefaultTaskID);
            if (defaultTask) {
              task.prospectDefaultTask = defaultTask;
            }
          });
      }
    });
  return activeFlow;
};

const loadHouseholdDefaultFlow = activeFlow => (dispatch: AppThunkDispatch) =>
  axios
    .get(`${REACT_APP_QWEALTH_API}/defaultFlow/${activeFlow.prospectDefaultFlowID}`)
    .then(response => responseEntityMapper<ProspectFlow>(response))
    .then(defaultFlow => {
      if (defaultFlow) {
        const activeFlowMerged = getActiveFlowMergedWithProspectFlow(activeFlow, defaultFlow);
        dispatch(putHouseholdActiveFlow(activeFlowMerged));
      }
    })
    .catch(errorHandler(dispatch));

export const loadHouseholdActiveFlow = (householdQID: string) => (dispatch: AppThunkDispatch): Promise<void> =>
  axios
    .get(`${REACT_APP_QWEALTH_API}/activeFlow/QID/${householdQID}`)
    .then(response => responseEntityMapper<ActiveFlow>(response))
    .then(async activeFlow => {
      if (activeFlow) {
        await dispatch(loadHouseholdDefaultFlow(activeFlow));
      }
    })
    .catch(errorHandler(dispatch));

export const deleteActiveFlow = (activeFlowID: number) => (dispatch: AppThunkDispatch): Promise<void> =>
  axios.delete(`${REACT_APP_QWEALTH_API}/activeFlow/${activeFlowID}`).catch(errorHandler(dispatch));

export const updateActiveStage = (activeStage: ActiveStage) => (dispatch: AppThunkDispatch): Promise<void> =>
  axios
    .patch(`${REACT_APP_QWEALTH_API}/activeStage/${activeStage.id}`, activeStage)
    .then(response => responseEntityMapper<ActiveStage>(response))
    .then(activeStage => {
      const activeStageData = omit(activeStage, ['tasks']);
      dispatch(putStageOnCurrentActiveFlow(activeStageData));
    })
    .catch(errorHandler(dispatch));

export const deleteActiveStage = (activeStageID: number) => (dispatch: AppThunkDispatch): Promise<void> =>
  axios
    .delete(`${REACT_APP_QWEALTH_API}/activeStage/${activeStageID}`)
    .catch(errorHandler(dispatch));

export const updateActiveTask =
  (
    activeTask: ActiveTask,
    taskData: Partial<QTask>,
    prospectDefaultTask: ProspectTask | null,
    activeStageId: number,
  ) =>
    (dispatch: AppThunkDispatch): Promise<void> => {
    let updatedActiveTask;
    return axios
      .patch(`${REACT_APP_QWEALTH_API}/activeTask/${activeTask.id}`, activeTask)
      .then(responseEntityMapper)
      .then(activeTask => (updatedActiveTask = activeTask))
      .then(() => axios.patch(`${REACT_APP_QWEALTH_API}/tasks/${activeTask.taskID}`, taskData))
      .then(response => responseEntityMapper<QTask>(response))
      .then(updatedTask => dispatch(putActivity(updatedTask)))
      .then(() => {
        if (prospectDefaultTask) {
          return updateProspectTaskRequest(prospectDefaultTask, prospectDefaultTask.id as number)
            .then(prospectDefaultTask => {
              updatedActiveTask.prospectDefaultTask = prospectDefaultTask;
              return dispatch(putTaskOnCurrentActiveFlowStage({ activeTask: updatedActiveTask, activeStageId }));
            })
            .catch(errorHandler(dispatch));
        }
        return dispatch(putTaskOnCurrentActiveFlowStage({ activeTask: updatedActiveTask, activeStageId }));
      })
      .catch(errorHandler(dispatch));
  };

export const deleteActiveTask = (activeTaskID: number) => (dispatch: AppThunkDispatch): Promise<void> =>
  axios.delete(`${REACT_APP_QWEALTH_API}/activeTask/${activeTaskID}`).catch(errorHandler(dispatch));

import { ComplianceRow } from './Compliance';
import { NewAccountRow } from './NewAccount';
import { KYCAgeRow } from './KYCAge';
import { MandateRow } from './Mandate';
import { MandateEWMRow } from './MandateEWM';
import { EmailRow } from './Email';
import { OpportunityRow } from './Opportunity';
import { HouseholdAddressRow } from './HouseholdAddress';
import { FeeGroupRow, FeeGroupFilters } from './FeeGroup';
import { ClientVitals } from './ClientVitals';
import { AccountMandate } from './AccountMandate';
import { AmlContactsRow, AmlEntitiesRow, AmlFilters } from './Aml';
import { UserDefinedFieldsRow, UserDefinedFieldsFilters } from './UserDefinedFields';
import { FeesRow, FeesFilters, FeesLoadParams } from './Fees';
import { DocusignHouseholdRow, DocusignFilters, DocusignLoadParams } from './Docusign';

// please keep all types alphabetically sorted
export type ReportRows =
  | AccountMandate
  | AmlContactsRow
  | AmlEntitiesRow
  | ClientVitals
  | ComplianceRow
  | DocusignHouseholdRow
  | EmailRow
  | FeeGroupRow
  | FeesRow
  | HouseholdAddressRow
  | KYCAgeRow
  | MandateEWMRow
  | MandateRow
  | NewAccountRow
  | OpportunityRow
  | UserDefinedFieldsRow;

export type ReportData = Array<ReportRows>;

export const ReportFlags = [
  'isLoadingAccountMandate',
  'isLoadingAmlContacts',
  'isLoadingAmlEntities',
  'isLoadingClientVitals',
  'isLoadingCompliance',
  'isLoadingDocusign',
  'isLoadingEmail',
  'isLoadingFeeGroupReport',
  'isLoadingFees',
  'isLoadingHouseholdAddressReport',
  'isLoadingKYCAge',
  'isLoadingMandate',
  'isLoadingMandateEWM',
  'isLoadingNewAccountReport',
  'isLoadingOpportunity',
  'isLoadingUserDefinedFields'
] as const;
export type ReportFlag = typeof ReportFlags[number];

export type ReportFlagModel = {
  [k in ReportFlag]: boolean;
};

export type ReportDataModel = {
  KYCAgeReport: KYCAgeRow[];
  complianceReport: ComplianceRow[];
  newAccountReport: NewAccountRow[];
  emailReport: EmailRow[];
  mandateReport: MandateRow[];
  mandateReportEWM: MandateEWMRow[];
  opportunityReport: OpportunityRow[];
  householdAddressReport: HouseholdAddressRow[];
  feeGroupReport: FeeGroupRow[];
  clientVitalsReport: ClientVitals[];
  accountMandateReport: AccountMandate[];
  amlContactsReport: AmlContactsRow[];
  amlEntitiesReport: AmlEntitiesRow[];
  userDefinedFieldsReport: UserDefinedFieldsRow[];
  feesReport: FeesRow[];
  docusignReport: DocusignHouseholdRow[];
};

export const ReportNames = [
  'KYCAgeReport',
  'accountMandateReport',
  'amlContactsReport',
  'amlEntitiesReport',
  'clientVitalsReport',
  'complianceReport',
  'emailReport',
  'feeGroupReport',
  'feesReport',
  'householdAddressReport',
  'mandateReport',
  'mandateReportEWM',
  'newAccountReport',
  'opportunityReport',
  'userDefinedFieldsReport',
  'docusignReport'
] as const;
export type ReportName = typeof ReportNames[number];

export type ReportSpecificFilters =
  | AmlFilters
  | FeeGroupFilters
  | UserDefinedFieldsFilters
  | FeesFilters;

export type ExtraLoadParams = FeesLoadParams | DocusignLoadParams;

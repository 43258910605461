import { CategoryType } from '@qwealth/qdata';

// keep objects and arrays alphabetical order plz
export const CATEGORIES: Array<CategoryType> = [
  'Client Service', //confirmed
  'Compliance', //confirmed
  'Fees', //confirmed no sub
  'IT Help', //confirmed
  'Marketing', //confirmed
  'Onboarding', //confirmed
  'Technology', //confirmed
  'Trade Desk', //confirmed no sub
  'Trade Requests', //confirmed no sub
  'Transitions', //confirmed
];

export const SUBCATEGORIES = {
  Technology: [
    'Calendly', //confirmed
    'FreshDesk', //confirmed
    'Other', //confirmed
    'Process Automation/Productivity', //confirmed
    'QCentral', //confirmed
    'Training', //confirmed
    'User Adiministration', //confirmed
  ],
  'IT Help': [
    'Audio/Visual', //confirmed
    'Hardware', //confirmed
    'Software', //confirmed
    'Email', //confirmed
    'User Administration', //confirmed
    'Network', //confirmed
    'Phone', //confirmed
    'Files', //confirmed
    'Teams', //confirmed
  ],
  Transitions: [
    'AEX Signing Issue', //confirmed
    'NBIN Inform Signing Issue', //confirmed
    'AEX Package Issue', //confirmed
    'NBIN Inform Package Issue', //confirmed
    'New AEX Package Creation', //confirmed
    'New Inform Package Creation', //confirmed
    'Digital to Wet Packages', //confirmed
    'Status of an Application', //confirmed
    'Deficiencies', //confirmed
    'Transfer Issues', //confirmed
    'Transfer Follow Ups', //confirmed
    'Miscellaneous', //confirmed
  ],

  Marketing: [
    'Blog Post', //confirmed
    'Business Stationary', //confirmed
    'Creative & Print', //confirmed
    'Email Design/Deployment', //confirmed
    'Event', //confirmed
    'Fund Sheet', //confirmed
    'Other', //confirmed
    'Proposal Request', //confirmed
    'Reporting & Dashboard', //confirmed
    'Social', //confirmed
    'Training', //confirmed
    'Website Design/Update', //confirmed
  ],
  Compliance: [
    'Document Review', //confirmed
    'Employee Onboarding', //confirmed
  ],
  Onboarding: [
    'CFR Question', //confirmed
  ],
  'Client Service': [
    'Account Opening', //confirmed
    'Name Change', //confirmed
    'Other', //confirmed
    'Password Reset', //confirmed
    'Redemption', //confirmed
    'Training', //confirmed
    'Account Corrections/Inquiries', //confirmed
    'Account Performance/Book value', //confirmed
    'Banking', //confirmed
    'D1g1t Administration', //confirmed
    'KYC/Client Information', //confirmed
    'Money Movement', //confirmed
    'PACs/SWPs/Sweeps', //confirmed
    'RESP/RDSP Grant', //confirmed
    'RESP/RDSP/TFSA Corrections', //confirmed
    'RIF/LIF Modifications & Unlocking', //confirmed
    'Tax Information Requests', //confirmed
    'Transfers In/Out', //confirmed
    'Transfer Rejections/Notifications', //confirmed
  ],
};

export const SUBSUBCATEGORIES = {
  QCentral: [
    'Bug/API error', //confirmed
    'Data Updates', //confirmed
    'Feature Request', //confirmed
    'Reporting & Dashboard', //confirmed
  ],
};

export const QC_CATEGORIES = ['Client Service', 'Onboarding', 'Technology', 'Transitions'];

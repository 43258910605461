import React, { ReactElement, useState } from 'react';
import { useNavigate } from 'react-router';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import { BsPlus } from 'react-icons/bs';
import { Tooltip } from 'react-tooltip';
import { Box, Button } from '@qwealth/qcore';
import AddHouseholdModal from 'components/Households/AddHousehold/AddHouseholdModal';
import AddEntity from '../../../LegalEntities/AddEntity';

const AddMenu = (): ReactElement => {
  const navigate = useNavigate();

  const [show, setShow] = useState(false);
  const [showHouseholdModal, setShowHouseholdModal] = useState(false);
  const [showEntityModal, setShowEntityModal] = useState(false);
  return (
    <>
      <OverlayTrigger
        trigger="click"
        placement="bottom"
        show={show}
        onToggle={() => setShow(!show)}
        rootClose
        overlay={
          <Popover id="popover-basic">
            <Popover.Body>
              <Box display="grid" gap="default">
                <Button
                  bg="white"
                  color="primary"
                  onClick={() => {
                    setShow(false);
                    navigate({ pathname: '/contacts/create' });
                  }}
                >
                  New Contact
                </Button>
                <Button
                  bg="white"
                  color="primary"
                  onClick={() => {
                    setShow(false);
                    setShowHouseholdModal(true);
                  }}
                >
                  New Household
                </Button>
                <Button
                  bg="white"
                  color="primary"
                  onClick={() => {
                    setShow(false);
                    setShowEntityModal(true);
                  }}
                >
                  New Entity
                </Button>
              </Box>
            </Popover.Body>
          </Popover>
        }
      >
        <Box ml="default" mt="default">
          <Tooltip id="add-button" content="Add Contact, Household, or Entity" />
          <Button bg="gray.3" color="primary" size="small" variant="icon" data-tooltip-id="add-button">
            <BsPlus />
          </Button>
        </Box>
      </OverlayTrigger>
      <AddHouseholdModal
        onClose={() => setShowHouseholdModal(false)}
        shouldShowModal={showHouseholdModal}
      />
      <AddEntity show={showEntityModal} setShow={setShowEntityModal} />
    </>
  );
};

export default AddMenu;

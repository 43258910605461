/**
 * Converts a number value to a Yes or No.
 */
export const numberToYesOrNoConvertor = (number): 'Yes' | 'No' | undefined => {
  if (isNaN(number)) {
    return undefined;
  }
  return number === 1 ? 'Yes' : 'No';
};

/**
 * Converts a number value to a Boolean.
 */
export const numberToBooleanConvertor = (number): undefined | boolean => {
  if (isNaN(number)) {
    return undefined;
  }
  return number !== 0;
};

export const flattenList = <T>(promisedLists: T[][]): T[] =>
  promisedLists.reduce((flatten, current) => [...flatten, ...current], []);

import React, { FC, ReactElement, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { Box } from '@qwealth/qcore';

import {
  QSearchComponent,
  FilterModal,
  QGroupOption,
  QSearchOption,
  getInitialFilters,
  SearchFilterParam
} from '@qwealth/qsearch';
import AddMenu from './AddMenu';
import { RootState } from 'data/store';

const SearchBar: FC = (): ReactElement => {
  const navigate = useNavigate();

  const [searchFilters, setSearchFilters] = useState<SearchFilterParam>(() => getInitialFilters());
  const [cacheOptions, setCacheOptions] = useState<boolean>(true);

  const recentHistory = useSelector((state: RootState) => state.recentHistory);

  const defaultOptions = useMemo(() => {
    const recentOptions: Array<QGroupOption> = [];
    if (recentHistory && recentHistory.length > 0) {
      const options: Array<QSearchOption> = recentHistory.map(h => ({
        label: h.text,
        value: { pathname: h.pathname, search: h.search },
      }));
      recentOptions.push({ label: 'Recent', options });
    }
    return recentOptions;
  }, [recentHistory]);

  return (
    <Box display="flex" flexDirection="row" style={{ zIndex: 999 }}>
      <Box width={600}>
        <QSearchComponent
          defaultOptions={defaultOptions}
          cacheOptions={cacheOptions}
          searchFilters={searchFilters}
          onChange={(selected) => {
            navigate(selected.value);
          }}
        />
      </Box>
      <FilterModal
        searchFilters={searchFilters}
        setSearchFilters={filters => {
          setCacheOptions(false);
          setSearchFilters(filters);
        }}
      />
      <AddMenu />
    </Box>
  );
};

export default SearchBar;

import { Dispatch } from 'redux';
import axios from 'axios';
import { omit } from 'lodash';
import { createAction } from '@reduxjs/toolkit';
import { responseEntityMapper, responseListMapper } from '@qwealth/qcore';
import {
  defaultFeesTier,
  FeesSchedule,
  FeesScheduleTier,
  FEES_SCHEDULE_LEVEL,
  FEES_SCHEDULE_TYPES,
  FLAT_TIER_MAX_AMOUNT,
} from '@qwealth/qdata';

import { errorHandler, REACT_APP_QWEALTH_API } from 'services/axiosService';
import { AppThunkDispatch } from 'data/store';
import { sortTiersByTierOrder } from 'data/helpers/feeSchedule';

const PUT_ALL_FEES_SCHEDULES = 'PUT_ALL_FEES_SCHEDULES';
const PUT_SCHEDULE = 'PUT_SCHEDULE';

export const putAllFeesSchedules = createAction<FeesSchedule[]>(PUT_ALL_FEES_SCHEDULES);
export const putSchedule = createAction<FeesSchedule>(PUT_SCHEDULE);

export const loadAllFeesSchedules = () => (dispatch: Dispatch): Promise<void> =>
  axios
    .get(`${REACT_APP_QWEALTH_API}/fees/schedules`)
    .then(resp => responseListMapper<FeesSchedule>(resp))
    .then(feesSchedules => dispatch(putAllFeesSchedules(feesSchedules)))
    .catch(errorHandler(dispatch));

export const fetchFeesSchedule = (feesScheduleId: number): Promise<FeesSchedule> => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${REACT_APP_QWEALTH_API}/fees/schedules/${feesScheduleId}`)
      .then(resp => resolve(responseEntityMapper<FeesSchedule>(resp)))
      .catch(e => reject(e));
  });
};

export const resetDefaultSchedule = schedule => (dispatch, getState) => {
  const {
    id: scheduleId,
    partnerID,
    iaCode,
    scheduleLevel,
  }: {
    id: number | undefined;
    partnerID: number;
    iaCode: string | undefined;
    scheduleLevel: string;
  } = schedule;
  const { feesScheduleList } = getState().feesSchedules;

  const defaultSchedules = feesScheduleList.filter(record => {
    const isPartner = record.partnerID === partnerID;
    const isDefault = record.isDefault === 1;
    return isPartner && isDefault && record.scheduleLevel === scheduleLevel;
  });

  let oldDefaultSchedule: FeesSchedule | undefined;
  if (scheduleLevel === FEES_SCHEDULE_LEVEL[0]) {
    oldDefaultSchedule = defaultSchedules.length === 1 ? defaultSchedules[0] : undefined;
  } else {
    oldDefaultSchedule = defaultSchedules.find(record => record.iaCode === iaCode);
  }

  if (oldDefaultSchedule) {
    if (oldDefaultSchedule.id === scheduleId) {
      return;
    }

    oldDefaultSchedule.isDefault = 0;
    dispatch(addOrUpdateFeesSchedule(oldDefaultSchedule));
  }
};

export const addOrUpdateFeesSchedule =
  (feesSchedule: FeesSchedule, deletedTierIds: number[] = []) =>
  (dispatch: AppThunkDispatch): Promise<FeesSchedule> => {
    feesSchedule.minimumFeeAmount = feesSchedule.hasMinimumFee ? feesSchedule.minimumFeeAmount : 0;

    const feesScheduleData = omit(feesSchedule, [
      !feesSchedule.id ? 'id' : '',
      !feesSchedule.partnerID ? 'partnerID' : '',
      'feeTier',
      'flatFeeRate',
    ]);

    const request = !feesSchedule.id
      ? axios.post(`${REACT_APP_QWEALTH_API}/fees/schedules`, feesScheduleData)
      : axios.patch(`${REACT_APP_QWEALTH_API}/fees/schedules/${feesSchedule.id}`, feesScheduleData);
    return request
      .then(response => responseEntityMapper<FeesSchedule>(response))
      .then(async (response: FeesSchedule) => {
        response.feeTier = sortTiersByTierOrder(response.feeTier);

        let feeTiers: FeesScheduleTier[] = feesSchedule.id ? response.feeTier : [];
        // Add tier when type is flat
        const isScheduleTypeFlat = feesSchedule.scheduleType === FEES_SCHEDULE_TYPES[0];
        if (!feesSchedule.id && isScheduleTypeFlat) {
          const tier = { ...defaultFeesTier };
          tier.feeTierName = 'Default Tier';
          tier.scheduleID = response.id;
          tier.feeRate = feesSchedule.flatFeeRate as number;
          tier.tierMaxAmount = FLAT_TIER_MAX_AMOUNT;
          const addedFlatTier = await dispatch(addFeesScheduleTier(tier));
          feeTiers.push(addedFlatTier);
        }

        // If schedule is selected as default
        if (feesSchedule.isDefault) {
          dispatch(resetDefaultSchedule(response));
        }

        // Add schedule tiers when type is not flat
        if (!feesSchedule.id && !isScheduleTypeFlat) {
          const addTierPromises = feesSchedule.feeTier?.map(tier => {
            tier.scheduleID = response.id;
            const tierData = omit(tier, [
              'feeType',
              'flatFeeAmount',
              'minFeeAmount',
              'createdBy',
              'lastUpdatedBy',
              'feeTierScheduleEndDate',
              'feeTierScheduleStartDate',
            ]);
            return dispatch(addFeesScheduleTier(tierData));
          });
          feeTiers = addTierPromises ? await Promise.all(addTierPromises) : [];
        }

        deletedTierIds.map(id => {
          dispatch(deleteFeesScheduleTier(id));
        });

        dispatch(putSchedule({ ...response, feeTier: feeTiers }));
        return Promise.resolve(response);
      })
      .catch(e => {
        errorHandler(dispatch, 'API Error. Failed updating Fee Schedule')(e);
        return Promise.reject(null);
      });
  };

export const updateFeesSchedule =
  (schedule: Partial<FeesSchedule>) => async (dispatch: AppThunkDispatch): Promise<FeesSchedule> => {
    const scheduleData = omit(schedule, ['id']);
    try {
      const axiosResponse = await axios.patch(
        `${REACT_APP_QWEALTH_API}/fees/schedules/${schedule.id}`,
        scheduleData,
      );
      const updatedSchedule = responseEntityMapper<FeesSchedule>(axiosResponse);
      updatedSchedule.feeTier = sortTiersByTierOrder(updatedSchedule.feeTier);
      dispatch(putSchedule(updatedSchedule));
      return Promise.resolve(updatedSchedule);
    } catch (error) {
      errorHandler(dispatch, 'API Error. Failed updating Fee Schedule')(error);
      return Promise.reject(null);
    }
  };

export const addFeesScheduleTier = (feesScheduleTier: Partial<FeesScheduleTier>) => (dispatch: Dispatch): Promise<FeesScheduleTier> =>
  axios
    .post(`${REACT_APP_QWEALTH_API}/fees/tiers`, omit(feesScheduleTier, ['id']))
    .then(response => responseEntityMapper<FeesScheduleTier>(response))
    .then((response: FeesScheduleTier) => Promise.resolve(response))
    .catch(e => {
      errorHandler(dispatch)(e);
      return Promise.reject(null);
    });

export const deleteFeesScheduleTier = (tierId: number) => (dispatch: Dispatch): Promise<void> =>
  axios.delete(`${REACT_APP_QWEALTH_API}/fees/tiers/${tierId}`).catch(errorHandler(dispatch));

export const fetchFeesSchedulesByPartnerId = (id: number) => (dispatch: Dispatch): Promise<void> =>
  axios
    .get(`${REACT_APP_QWEALTH_API}/fees/schedules/partner/${id}`)
    .then(res => dispatch(putAllFeesSchedules(responseListMapper<FeesSchedule>(res))))
    .catch(errorHandler(dispatch, 'API Error. Failed getting Fees Schedules By Partner ID'));

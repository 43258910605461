import {
  SET_CALENDAR_EVENTS_LOADING,
  PUT_EVENTS,
  PUT_EVENT,
  DELETE_EVENT,
} from '../../actions/microsoft/calendar';

interface IState {
  loading: boolean;
  events: Array<any>;
}

export default function (
  state = {
    loading: true,
    events: [],
  },
  action,
): IState {
  switch (action.type) {
    case SET_CALENDAR_EVENTS_LOADING:
      return { ...state, loading: action.loading };
    case PUT_EVENTS:
      return { ...state, events: action.events };
    case PUT_EVENT: {
      const { events } = state;

      const index = events.findIndex(({ id }) => id === action.event.id);
      if (index > -1) {
        return {
          ...state,
          events: [...events.slice(0, index), action.event, ...events.slice(index + 1)],
        };
      }

      return { ...state, events: [...events, action.event] };
    }
    case DELETE_EVENT: {
      const { events } = state;
      const updatedEvents = events.filter(({ id }) => id !== action.id);
      return { ...state, events: updatedEvents };
    }
    default:
      return state;
  }
}

import React, { useEffect, useState, useCallback, ReactElement } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { matchPath, useMatch, useNavigate } from 'react-router';
import {
  BsFillGrid1X2Fill,
  BsBarChartFill,
  BsCardHeading,
  BsUnlockFill,
  BsFileText,
  BsCashCoin,
  BsListTask,
} from 'react-icons/bs';
import { VscFeedback } from 'react-icons/vsc';

import { AppLayout, Box } from '@qwealth/qcore';
import {
  selectUserIsAdmin,
  selectUserIsITUser,
  selectUserIsSupport,
} from '@qwealth/qdata';

import { selectCanViewOwnFlowsOnly } from 'data/selectors/authSelectors';
import {
  Q_LIFE_URL,
  Q_CASH_URL,
  Q_FOLIO_URL,
  SF_URL,
  DIGIT_URL,
  CONQUEST_DASHBOARD_PATH,
  Q_NET_URL,
  Q_FEEDBACK_URL,
} from 'data/refData/constants';
import QApp from './QApp';
import { SideNavLinkItem } from './SideNavLinkItem';

const CONQUEST_URL = process.env.REACT_APP_QWEALTH_CONQUEST_URL ?? '';

export default function Sidebar(): ReactElement {
  const [hasHouseholdContext, setHouseholdContext] = useState<boolean>(false);
  const [householdId, setHouseholdId] = useState('');
  const isAdmin = useSelector(selectUserIsAdmin);
  const isUserSupport = useSelector(selectUserIsSupport);
  const isUserInformationTechnology = useSelector(selectUserIsITUser);
  const canViewOwnFlowsOnly = useSelector(selectCanViewOwnFlowsOnly);
  const prospectRouteMatch = useMatch('/prospect-households/:QID');

  const location = useLocation();
  const matchPaths = useCallback(
    values => {
      let match = false;
      values.forEach(value => {
        const path = matchPath({
          path: value,
        }, location.pathname);
        if (path) {
          match = true;
        }
      });
      return match;
    },
    [location],
  );

  useEffect(() => {
    const urlParams = new URLSearchParams(location.search);
    setHouseholdContext(urlParams.has('household') || matchPaths(['/prospect-households/:QID']));
    // @ts-ignore
    const prospectQID = prospectRouteMatch?.params?.QID;
    setHouseholdId(urlParams.get('household') || prospectQID || '');
  }, [location, matchPaths, prospectRouteMatch]);

  const navigate = useNavigate();

  // @ts-ignore
  const conquestPlanID = useSelector(state => state.householdMetadata.conquestPlanID);

  const QApps = {
    QLife: `${Q_LIFE_URL}/?household=${householdId}`,
    QCash: `${Q_CASH_URL}/?household=${householdId}`,
    QFolio: `${Q_FOLIO_URL}/proposal/${householdId}`,
    SF: SF_URL,
    Digit: DIGIT_URL,
    Conquest: conquestPlanID
      ? CONQUEST_URL.concat(CONQUEST_DASHBOARD_PATH.replace(':conquestPlanID', conquestPlanID))
      : CONQUEST_URL,
  };
  const externalApps = ['SF', 'Digit', 'Conquest'];

  const hasAdminPageAccess = isAdmin || isUserSupport || isUserInformationTechnology || canViewOwnFlowsOnly;
  const canViewUserPage = isAdmin || isUserSupport || isUserInformationTechnology;

  let adminPath = '/admin/partners';
  if (canViewUserPage) {
    adminPath = '/admin/users';
  } else if (canViewOwnFlowsOnly) {
    adminPath = '/admin/prospect-flows';
  }

  return (
    <AppLayout.SideNav title="QCentral">
      <AppLayout.SideNavLinks>
        <AppLayout.SideNavLinkItem
          activeClass={matchPaths(['/', '/qboard']) ? 'active' : undefined}
          title="QBoard"
          icon={BsFillGrid1X2Fill}
          onClick={() => navigate({ pathname: '/qboard' })}
        />
        <AppLayout.SideNavLinkItem
          activeClass={matchPaths(['/my-list']) ? 'active' : undefined}
          title="My List"
          icon={BsListTask}
          onClick={() => navigate({ pathname: '/my-list' })}
        />
        <AppLayout.SideNavLinkItem
          activeClass={matchPaths(['/report/*']) ? 'active' : undefined}
          title="Reports"
          icon={BsFileText}
          onClick={() => navigate({ pathname: '/report/kyc-compliance-report' })}
        />
        {/* TODO change to AppLayout.SideNavLinkItem when qcore is updated. Delete import and SideNavLinkItem.tsx file */}
        <SideNavLinkItem
          title="QNet"
          icon={BsCardHeading}
          onClick={() => window.open(Q_NET_URL, '_blank')}
          isOpenInNewWindow
        />
        {isAdmin && (
          <AppLayout.SideNavLinkItem
            activeClass={matchPaths(['/qnalytics']) ? 'active' : undefined}
            title="QNalytics"
            icon={BsBarChartFill}
            onClick={() => navigate({ pathname: '/qnalytics' })}
          />
        )}
        <AppLayout.SideNavLinkItem
          activeClass={matchPaths(['/fees', '/fees/*']) ? 'active' : undefined}
          title="Fees"
          icon={BsCashCoin}
          onClick={() => navigate({ pathname: '/fees' })}
        />
        {hasAdminPageAccess && (
          <AppLayout.SideNavLinkItem
            activeClass={matchPaths(['/admin/*']) ? 'active' : undefined}
            title="Administration"
            icon={BsUnlockFill}
            onClick={() => navigate({ pathname: adminPath })}
          />
        )}
        {/* TODO change to AppLayout.SideNavLinkItem when qcore is updated. Delete import and SideNavLinkItem.tsx file */}
        <SideNavLinkItem
          title="QFeedback"
          icon={VscFeedback}
          onClick={() => window.open(Q_FEEDBACK_URL, '_blank')}
          isOpenInNewWindow
        />
      </AppLayout.SideNavLinks>
      {hasHouseholdContext ? (
        <AppLayout.SideNavSection title="QSUITE">
          <Box
            display="grid"
            gridTemplateColumns="repeat(auto-fill, 40px)"
            gap="default"
            justifyContent="space-evenly"
          >
            {Object.keys(QApps).map(app => (
              <QApp
                key={app}
                name={app}
                alt={app}
                img={`/${app}.png`}
                onClick={() =>
                  window.open(QApps[app], externalApps.includes(app) ? '_blank' : '_self')
                }
              />
            ))}
          </Box>
        </AppLayout.SideNavSection>
      ) : (
        <></>
      )}
    </AppLayout.SideNav>
  );
}

import { createReducer } from '@reduxjs/toolkit';
import { updateItemAndGetArrayImmutably, deleteItemAndGetArrayImmutably } from '@qwealth/qcore';
import { FeesRecord, FeesWaiveDispute, FeeRun } from '@qwealth/qdata';
import {
  putAllFeesReviews,
  putAllWaiveDisputes,
  putAllFeeRuns,
  setWaiveDisputeTypeInReview,
  putWaiveDispute,
  removeWaiveDispute, putFeeRun,
} from '../actions/feeRuns';

export interface IFeesReviewsState {
  initializedFeesReviews: boolean;
  initializedWaiveDisputes: boolean;
  initializedFeeRuns: boolean;
  feesReviews: FeesRecord[];
  waiveDisputes: FeesWaiveDispute[];
  feeRuns: FeeRun[];
}

export const initialState: IFeesReviewsState = {
  initializedFeesReviews: false,
  initializedWaiveDisputes: false,
  initializedFeeRuns: false,
  feesReviews: [],
  waiveDisputes: [],
  feeRuns: [],
};

export default createReducer(initialState, (builder) => {
  builder.addCase(putAllFeesReviews, (state, action) => ({
    ...state,
    initializedFeesReviews: true,
    feesReviews: action.payload
  }));

  builder.addCase(putAllWaiveDisputes, (state, action) => ({
    ...state,
    initializedWaiveDisputes: true,
    waiveDisputes: action.payload
  }));

  builder.addCase(putAllFeeRuns, (state, action) => ({...state, initializedFeeRuns: true, feeRuns: action.payload}));

  builder.addCase(setWaiveDisputeTypeInReview, (state, action) => {
    const waiveDispute: FeesWaiveDispute = action.payload;
    const feesReviews: FeesRecord[] = updateItemAndGetArrayImmutably(
      state.feesReviews,
      (review: FeesRecord) => review.id === waiveDispute.feesCurrentPeriodID,
      (review?: FeesRecord) => (review ? { ...review, reviewType: waiveDispute.type } : review),
    );
    return { ...state, feesReviews };
  });

  builder.addCase(putWaiveDispute, (state, action) => {
    const newWaiveDispute: FeesWaiveDispute = action.payload;
    const waiveDisputes: FeesWaiveDispute[] = updateItemAndGetArrayImmutably(
      state.waiveDisputes,
      (waiveDispute: FeesWaiveDispute) => waiveDispute.id === newWaiveDispute.id,
      () => ({ ...newWaiveDispute }),
    );
    return { ...state, waiveDisputes };
  });

  builder.addCase(removeWaiveDispute, (state, action) => {
    const deletedWaiveDispute: FeesWaiveDispute = action.payload;
    const waiveDisputes: FeesWaiveDispute[] = deleteItemAndGetArrayImmutably(
      state.waiveDisputes,
      (waiveDispute: FeesWaiveDispute) => waiveDispute.id === deletedWaiveDispute.id,
    );
    const feesReviews = state.feesReviews.map(review =>
      review.id === deletedWaiveDispute.feesCurrentPeriodID
        ? { ...review, reviewType: undefined }
        : review,
    );
    return { ...state, waiveDisputes, feesReviews };
  });

  builder.addCase(putFeeRun, (state, action) => {
    const index = state.feeRuns.findIndex(feeRun => feeRun.id === action.payload.id);
    const newFeeRuns = [...state.feeRuns];
    index === -1 ? newFeeRuns.push(action.payload) : (newFeeRuns[index] = action.payload);
    return { ...state, feeRuns: newFeeRuns };
  });
});

import React, { FC, ReactElement } from 'react';
import { useSelector } from 'react-redux';
import { Modal } from 'react-bootstrap';
import { Form } from 'react-final-form';
import { Box, Button, RFFSelectInput2, RFFTextInput, useDispatchOnMount } from '@qwealth/qcore';
import {
  createHousehold,
  loadPartners,
  selectPartners,
  selectUser,
  selectUserIsAssociatePM,
  selectUserIsInHeadOffice,
  selectUserIsPortfolioManager,
} from '@qwealth/qdata';
import { commonSubscription } from '@qwealth/kyc';
import { RootState } from 'data/store';
import { CreateHouseholdForm } from 'data/models/CreateHouseholdForm';
import { Q_LIFE_URL } from 'data/refData/constants';
import { createHouseholdValidation } from 'data/validations/household';
import ClientTypeSelector from 'components/common/ClientTypeSelector';

interface Props {
  shouldShowModal: boolean;
  onClose: () => void;
}

const AddHouseholdModal: FC<Props> = ({ onClose, shouldShowModal }): ReactElement => {
  const { partnerID: initialPartnerId } = useSelector(selectUser);
  const partners = useSelector(selectPartners);
  const { initialized: initializedPartnerList } = useSelector((state: RootState) => state.partners);
  const isUserAPM = useSelector(selectUserIsAssociatePM);
  const isUserPM = useSelector(selectUserIsPortfolioManager);
  const isUserInHeadOffice = useSelector(selectUserIsInHeadOffice);
  const canSelectPartners = isUserInHeadOffice || isUserAPM || isUserPM;

  useDispatchOnMount(loadPartners, initializedPartnerList ? undefined : true);

  return (
    <Form<CreateHouseholdForm>
      initialValues={{
        householdName: '',
        iaCode: '',
        insuranceCode: '',
        partnerID: initialPartnerId || -1,
      }}
      onSubmit={async ({ householdName, iaCode, insuranceCode, partnerID }) => {
        const { household: { QID } } = await createHousehold(householdName, partnerID, iaCode, insuranceCode);
        onClose();
        window.location.assign(`${Q_LIFE_URL}/?household=${QID}`);
      }}
      validate={createHouseholdValidation}
    >
      {({ handleSubmit, form: { reset, change }, submitting }) => (
        <Modal
          onHide={() => {
            onClose();
            reset();
          }}
          show={shouldShowModal}
        >
          <Modal.Header closeButton>
            <Box textStyle="sectionTitle">Add New Household</Box>
          </Modal.Header>
          <Modal.Body>
            <RFFTextInput
              name="householdName"
              label="Household Name (Last Name)*"
            />
            {canSelectPartners ? (
              <RFFSelectInput2
                label="Partner*"
                name="partnerID"
                subscription={commonSubscription}
                inputFieldProps={{
                  data: partners,
                  toLabel: p => p.partnerName,
                  toValue: p => p.id,
                  // @ts-ignore
                  onChange: (partnerId: string) => {
                    change('partnerID', parseInt(partnerId));
                  }
                }}
              />
            ) : null}
            <ClientTypeSelector isRequired />
            <Box display="flex" gap="default" justifyContent="flex-end" mt="largest">
              <Button color="primary" onClick={handleSubmit} disabled={submitting}>
                Save
              </Button>
              <Button
                variant="outline"
                onClick={() => {
                  onClose();
                  reset();
                }}
              >
                Cancel
              </Button>
            </Box>
          </Modal.Body>
        </Modal>
      )}
    </Form>
  );
};

export default AddHouseholdModal;

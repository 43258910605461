import React, { FC, ReactElement } from 'react';
import { Field, useForm } from 'react-final-form';
import { Box, RadioInput, RFFErrorMessage, useFieldAsState } from '@qwealth/qcore';
import InsuranceCodeField from './InsuranceCodeField';
import IACodeField from './IACodeField';
import { BOTH_CLIENT_TYPE, INSURANCE_CLIENT_TYPE, INVESTMENT_CLIENT_TYPE } from 'data/models/CreateHouseholdForm';

interface Props {
  isRequired?: boolean;
}

const ClientTypeSelector: FC<Props> = ({ isRequired }): ReactElement => {
  const { change } = useForm();
  const [clientType] = useFieldAsState('clientType');
  return (
    <>
      <Field name="clientType">
        {({ input, meta }) => (
          <>
            <Box mt="large" textStyle="formLabel">
              Client Type{isRequired ? '*' : ''}
            </Box>
            <Box display="flex">
              <RadioInput
                name="clientType"
                id={INSURANCE_CLIENT_TYPE}
                value={INSURANCE_CLIENT_TYPE}
                label={INSURANCE_CLIENT_TYPE}
                checked={input.value === INSURANCE_CLIENT_TYPE}
                onChange={() => {
                  input.onChange(INSURANCE_CLIENT_TYPE);
                  change('iaCode', '');
                }}
              />
              <RadioInput
                name="clientType"
                id={INVESTMENT_CLIENT_TYPE}
                value={INVESTMENT_CLIENT_TYPE}
                label={INVESTMENT_CLIENT_TYPE}
                checked={input.value === INVESTMENT_CLIENT_TYPE}
                onChange={() => {
                  input.onChange(INVESTMENT_CLIENT_TYPE);
                  change('insuranceCode', '');
                }}
              />
              <RadioInput
                name="clientType"
                label={BOTH_CLIENT_TYPE}
                id={BOTH_CLIENT_TYPE}
                value={BOTH_CLIENT_TYPE}
                checked={input.value === BOTH_CLIENT_TYPE}
                onChange={() => input.onChange(BOTH_CLIENT_TYPE)}
              />
            </Box>
            {meta.touched && meta.error ? (
              <RFFErrorMessage errorNotificationUUID={input.name}>{meta.error}</RFFErrorMessage>
            ) : null}
          </>
        )}
      </Field>
      {[INSURANCE_CLIENT_TYPE, BOTH_CLIENT_TYPE].includes(clientType as string) && (
        <Box mt="default">
          <InsuranceCodeField />
        </Box>
      )}

      {[INVESTMENT_CLIENT_TYPE, BOTH_CLIENT_TYPE].includes(clientType as string) && (
        <Box mt="default">
          <IACodeField />
        </Box>
      )}
    </>
  );
};

export default ClientTypeSelector;

import axios from 'axios';
import { createAction } from '@reduxjs/toolkit';
import { pick } from 'lodash';
import { Dispatch } from 'redux';
import { responseEntityMapper, responseListMapper } from '@qwealth/qcore';
import { Note } from '@qwealth/qdata';
import { errorHandler, REACT_APP_QWEALTH_API } from 'services/axiosService';
import { putContactsLoadingProps } from './contacts';
import { AppThunkDispatch } from '../store';

const PUT_HOUSEHOLD_NOTE = 'PUT_HOUSEHOLD_NOTE';
const PUT_HOUSEHOLD_NOTES = 'PUT_HOUSEHOLD_NOTES';
const DELETE_HOUSEHOLD_NOTE = 'DELETE_HOUSEHOLD_NOTE';
const PUT_CONTACT_NOTE = 'PUT_CONTACT_NOTE';
const PUT_CONTACT_NOTES = 'PUT_CONTACT_NOTES';
const DELETE_CONTACT_NOTE = 'DELETE_CONTACT_NOTE';

export const putHouseholdNote = createAction<Note>(PUT_HOUSEHOLD_NOTE);
export const putHouseholdNotes = createAction<Note[]>(PUT_HOUSEHOLD_NOTES);
export const deleteHouseholdNote = createAction<number>(DELETE_HOUSEHOLD_NOTE);
export const putContactNote = createAction<Note>(PUT_CONTACT_NOTE);
export const putContactNotes = createAction<Note[]>(PUT_CONTACT_NOTES);
export const deleteContactNote = createAction<number>(DELETE_CONTACT_NOTE);

export const addNote = (note: Note, isContactNote?: boolean) => (dispatch: AppThunkDispatch): Promise<void> => {
  if (isContactNote) {
    dispatch(putContactsLoadingProps({ name: 'contactNotes', isLoading: true }));
  }
  return axios
    .post(`${REACT_APP_QWEALTH_API}/notes`, note)
    .then(response => responseEntityMapper<Note>(response))
    .then(savedNote => {
      if (isContactNote) {
        dispatch(putContactNote(savedNote));
      } else {
        dispatch(putHouseholdNote(savedNote));
      }
    })
    .catch(errorHandler(dispatch, 'Could not add note'))
    .finally(() => {
      if (isContactNote) {
        dispatch(putContactsLoadingProps({ name: 'contactNotes', isLoading: false }));
      }
    });
};

export const loadNotes = (QID: string, isContactNote?: boolean) => (dispatch: Dispatch): Promise<void> => {
  if (isContactNote) {
    dispatch(putContactsLoadingProps({ name: 'contactNotes', isLoading: true }));
  }
  return axios
    .get(`${REACT_APP_QWEALTH_API}/notes/QID/${QID}`)
    .then(response => responseListMapper<Note>(response))
    .then(notes => {
      if (isContactNote) {
        dispatch(putContactNotes(notes));
      } else {
        dispatch(putHouseholdNotes(notes))
      }
    })
    .catch(errorHandler(dispatch))
    .finally(() => {
      if (isContactNote) {
        dispatch(putContactsLoadingProps({ name: 'contactNotes', isLoading: false }));
      }
    });
};

export const getNote = (noteId: number): Promise<Note> =>
  axios
    .get(`${REACT_APP_QWEALTH_API}/notes/${noteId}`)
    .then(response => responseEntityMapper<Note>(response));

export const updateNote = (note: Note, isContactNote?: boolean) => (dispatch: AppThunkDispatch): Promise<void> => {
  if (isContactNote) {
    dispatch(putContactsLoadingProps({ name: 'contactNotes', isLoading: true }));
  }

  const { id, Content } = note;
  const additionalFields = pick(note, [
    'isSignificantChange',
    'significantChangeDate',
    'isMeaningfulInteraction',
    'meaningfulInteractionDate',
  ]);

  return axios
    .patch(`${REACT_APP_QWEALTH_API}/notes/${id}`, {
      Content,
      ...additionalFields,
    })
    .then(response => responseEntityMapper<Note>(response))
    .then(savedNote => dispatch(putHouseholdNote(savedNote)))
    .catch(errorHandler(dispatch))
    .finally(() => {
      if (isContactNote) {
        dispatch(putContactsLoadingProps({ name: 'contactNotes', isLoading: false }));
      }
    });
};

export const deleteNote = (noteId: number, isContactNote?: boolean) => (dispatch: AppThunkDispatch): Promise<void> =>
  axios
    .delete(`${REACT_APP_QWEALTH_API}/notes/${noteId}`)
    .then(() => {
      if (isContactNote) {
        dispatch(deleteContactNote(noteId));
      } else {
        dispatch(deleteHouseholdNote(noteId));
      }
    })
    .catch(errorHandler(dispatch));

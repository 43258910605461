import { PUT_AUTO_TRANSACTIONS } from 'data/actions/autoTransactions';

const immutablyUpdateTransactions = (list, item) => {
  const updated = [...list];
  const index = updated.findIndex(
    ({ autoTransactionID }) => autoTransactionID === item?.autoTransactionID,
  );
  if (index > -1) {
    return [...list.slice(0, index), item, ...list.slice(index + 1)];
  }

  return [...list, item];
};

export default function (state = [], action) {
  switch (action.type) {
    case PUT_AUTO_TRANSACTIONS: {
      const { autoTransactions } = action;
      if (autoTransactions === 0 || autoTransactions === undefined) {
        return state;
      }

      if (Array.isArray(autoTransactions)) {
        let updated = [...state];
        autoTransactions.forEach(t => {
          updated = immutablyUpdateTransactions(updated, t);
        });
        return updated;
      }

      return immutablyUpdateTransactions(state, autoTransactions);
    }
    default:
      return state;
  }
}

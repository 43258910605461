import axios from 'axios';
import { Dispatch } from 'redux';
import { createAction } from '@reduxjs/toolkit';
import { responseListMapper } from '@qwealth/qcore';
import { IACode, REACT_APP_QWEALTH_API, SelectOption } from '@qwealth/qdata';
import { errorHandler } from 'services/axiosService';

const SET_RESPONSIBILITY_LOADING = 'SET_RESPONSIBILITY_LOADING';
const SET_RESPONSIBILITY_OPTIONS = 'SET_RESPONSIBILITY_OPTIONS';

export const putResponsibilityOptions = createAction<SelectOption<string>[]>(SET_RESPONSIBILITY_OPTIONS);
export const setResponsibilityLoading = createAction<boolean>(SET_RESPONSIBILITY_LOADING);

const regex = /^\S+\s+\S+/gm;

export const loadOptions = (userIACodes: Array<string>, userEmail: string) => (dispatch: Dispatch): Promise<void> => {
  dispatch(setResponsibilityLoading(true));
  return axios.get(`${REACT_APP_QWEALTH_API}/iaCodes/access?iaCode=${userIACodes}`)
    .then(response => responseListMapper<IACode>(response))
    .then(iaCodes => {
      const options: Array<SelectOption<string>> = [];
      const emails: Array<string> = [userEmail];
      iaCodes.forEach(({ teamMemberName, email }) => {
        if (!emails.includes(email)) {
          emails.push(email);
          const label = `Assigned to ${(teamMemberName ?? '').match(regex) || email}`;
          options.push({ label, value: email });
        }
      });
      return options.sort((a, b) => a.label.localeCompare(b.label));
    })
    .then(options => dispatch(putResponsibilityOptions(options)))
    .catch(errorHandler(dispatch, 'Could not load ia codes'))
    .finally(() => dispatch(setResponsibilityLoading(false)));
};

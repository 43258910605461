import { createReducer } from '@reduxjs/toolkit';
import { DocusignEnvelope } from '@qwealth/qdata';
import {
  putDocusignEnvelopes,
  removeActiveDocusignEnvelope,
  putActiveDocusignEnvelope,
  putRegistrationOnActiveEnvelope,
} from '../actions/docusignEnvelopes';

export interface IDocusignEnvelopesState {
  initialized: boolean;
  envelopes: Array<DocusignEnvelope>;
  envelope: DocusignEnvelope;
}

const initialState: IDocusignEnvelopesState = {
  initialized: false,
  envelopes: [],
  envelope: {} as DocusignEnvelope,
};

export default createReducer(initialState, builder => {
  builder.addCase(putDocusignEnvelopes, (state, action) => ({
    ...state,
    initialized: true,
    envelopes: action.payload,
  }));
  builder.addCase(putActiveDocusignEnvelope, (state, action) => ({
    ...state,
    envelope: {
      ...state.envelope,
      ...action.payload,
    },
  }));
  builder.addCase(removeActiveDocusignEnvelope, state => ({
    ...state,
    initialized: true,
    envelope: {} as DocusignEnvelope,
  }));
  builder.addCase(putRegistrationOnActiveEnvelope, (state, action) => {
    const newRegistration = action.payload;
    const nbinRegistration = state.envelope.nbinRegistration ?? []
    const index = nbinRegistration.findIndex(r => r.id === newRegistration.id);
    if (index > -1) {
      nbinRegistration[index] = newRegistration;
    } else {
      nbinRegistration.push(newRegistration);
    }
    return state;
  });
});
import moment from 'moment';
import { TASK_PRIORITIES, TASK_STATUS_LIST, TaskPriority, TaskStatus, TaskType } from '@qwealth/qdata';
import { DateRange } from './DateRange';
import { ActivityType, ActivityTypes } from './Activity';
import { SortDirection } from './SortDirection';
import { ALL_DATE_RANGE, ASSIGN_TO_ME_RESPONSIBILITY } from '../refData/acitivites';

export const ACTIVITY_FILTER_KEY = 'activity_filters_v8';
export const ALL_TASK_TYPES = 'All Types';
export const ALL_LOG_SUBJECTS = 'All Subjects';

export type ActivityFilters = {
  filterQuery: string;
  filterDateValue: string;
  filterActivities: Array<ActivityType>;
  filterTaskResponsibilities: Array<string>;
  filterTaskStatus: Array<TaskStatus>;
  filterTaskType: TaskType | 'All Types';
  filterTaskPriorities: Array<TaskPriority>;
  filterLogSubject: string;
  filterDateRange: DateRange;
  sortDirection: SortDirection;
};

export type ActivityFiltersWithoutQuery = Omit<ActivityFilters, 'filterQuery'>;

export type QFeedActivityFilters = ActivityFilters & {
  userEmail: string;
};

export type HouseholdActivityFilters = ActivityFiltersWithoutQuery & {
  householdQID: string;
  timeZone: string;
};

export const DATE_FILTERS = [
  { label: 'All Time', value: ALL_DATE_RANGE },
  { label: 'This Week', value: 'this_week' },
  { label: 'All Upcoming', value: 'all_upcoming' },
  { label: 'All Past', value: 'all_past' },
  { label: 'Custom', value: 'custom' },
];

export const defaultFilter: ActivityFilters = {
  sortDirection: 'DESC',
  filterQuery: '',
  filterDateValue: DATE_FILTERS[0].value,
  filterActivities: [...ActivityTypes],
  filterTaskResponsibilities: [ASSIGN_TO_ME_RESPONSIBILITY.value],
  filterTaskStatus: [...TASK_STATUS_LIST],
  filterTaskType: ALL_TASK_TYPES,
  filterTaskPriorities: [...TASK_PRIORITIES],
  filterLogSubject: ALL_LOG_SUBJECTS,
  filterDateRange: {
    fromDate: moment().startOf('day'),
    toDate: moment().add(7, 'day').endOf('day'),
  },
};

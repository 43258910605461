import { createReducer } from '@reduxjs/toolkit';
import { putCurrentUser, putPartner } from '../actions/auth';
import { AuthState } from 'data/models/AuthState';

const defaultState: AuthState = {
  user: null,
  partner: null,
  initializedUser: false,
  initializedPartner: false,
};

export default createReducer(defaultState, (builder) => {
  builder.addCase(putCurrentUser, (state, action) =>
    ({...state, user: action.payload, initializedUser: true}));

  builder.addCase(putPartner, (state, action) =>
    ({...state, partner: action.payload, initializedPartner: true}));
});

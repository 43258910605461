import axios from 'axios';
import { apiUrl } from '@qwealth/qdata';

import { REACT_APP_QWEALTH_API } from 'services/axiosService';
import { Proposal, ProposalResponse } from 'data/models/RtqProposal';
import { ChangeRtqLevelPayload } from '../models/ChangeRtqLevel';

export const portfolioManagerApprovalApi = (rtqId: number, isApproved: boolean): Promise<void> =>
  axios.post(`${apiUrl}/newrtq/${rtqId}/portfolioManagerApproval`, {
    isApproved
  });

export const fetchProposalMandatesAndModels = async (): Promise<ProposalResponse> => {
  const response = await axios.get(`${REACT_APP_QWEALTH_API}/pdf/powerPrint/`);
  return response.data;
};

export const fetchProposalPdf = async (proposal: Proposal): Promise<Blob | null> => {
  const { useExistingRtq, rtq, ...payload } = proposal;
  const config = { responseType: 'blob' as const };
  const response = await axios.post(`${REACT_APP_QWEALTH_API}/pdf/powerPrint/`, payload, config);
  return response.data;
};

export const changeRtqLevelApi = async (rtqId: number, payload: ChangeRtqLevelPayload): Promise<void> => {
  await axios.post(`${REACT_APP_QWEALTH_API}/newrtq/${rtqId}/changeRtqLevel`, payload);
}

export const initRtqApi = async (qid: string, householdQid: string): Promise<void> =>
  await axios.post(`${REACT_APP_QWEALTH_API}/newrtq/init`, { qid, householdQid });

import { createApi } from 'unsplash-js';

const { REACT_APP_QWEALTH_UNSPLASH_ACCESS_KEY } = process.env;
const unsplash = createApi({ accessKey: REACT_APP_QWEALTH_UNSPLASH_ACCESS_KEY });

export function getPhoto(query) {
  return unsplash.photos
    .getRandom({
      query,
      orientation: 'landscape',
    })
    .then(returned => {
      if (returned.errors) {
        throw returned.errors[0];
      }
      return returned.response.urls.regular;
    });
}

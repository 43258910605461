import axios from 'axios';
import { Dispatch } from 'redux';
import { createAction } from '@reduxjs/toolkit';
import { QWealthAuthorizationInterceptor } from '@qwealth/qdata';

const AXIOS_INITIALIZED = 'AXIOS_INITIALIZED';

export const initializeAxios = createAction(AXIOS_INITIALIZED);

let unauthenticatedAlert = false;
const UnauthorizedInterceptor = (error) => {
  if (error?.response?.status === 401) {
    if (!unauthenticatedAlert) {
      unauthenticatedAlert = true;
      alert('Please log back in as your QCentral session is expired.');
    }
  }
  return Promise.reject(error);
};

export const configureAxios = () => async (dispatch: Dispatch): Promise<boolean> => {
  // interceptor to set the authorization only on QW API requests
  axios.interceptors.request.use(QWealthAuthorizationInterceptor);
  axios.interceptors.response.use((response) => response, UnauthorizedInterceptor);

  dispatch(initializeAxios());
  return Promise.resolve(true);
};

import { Dispatch } from 'redux';
import { createAction } from '@reduxjs/toolkit';
import { getUserDetails } from 'services/microsoft/graphService';
import { MicrosoftUser } from '../../models/MicrosoftUser';
import { EST } from 'data/refData/time';

export const SET_USER = 'SET_MS_USER';

export const setUser = createAction<MicrosoftUser>(SET_USER);

export const loadUser = () => async (dispatch: Dispatch): Promise<void> => {
  const userDetails = await getUserDetails();
  const { id, givenName, displayName, mailboxSettings, mail, surname, userPrincipalName } =
    userDetails;

  // Use Fallback timeZone if not found
  const timeZone = mailboxSettings?.timeZone ? mailboxSettings.timeZone : EST;
  const timeFormat = mailboxSettings?.timeFormat ? mailboxSettings.timeFormat : EST;

  dispatch(
    setUser({
      displayName,
      email: mail || userPrincipalName,
      givenName,
      id,
      surname,
      timeFormat,
      timeZone,
    }),
  );
};

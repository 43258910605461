import React, { FC, ReactElement } from 'react';
import { Field } from 'react-final-form';
import { Box, RFFErrorMessage } from '@qwealth/qcore';
import { ExtendedSearchOption } from 'data/models/ExtendedSearchOption';
import {
  BOTH_CLIENT_TYPE,
  INSURANCE_CLIENT_TYPE,
  INVESTMENT_CLIENT_TYPE
} from 'data/models/CreateHouseholdForm';
import HouseholdSearch from '../../common/HouseholdSearch';

interface Props {
  formChange: (name: any, value: any) => void;
}

const EntityHouseholdSelector: FC<Props> = ({ formChange }): ReactElement => {
  return (
    <>
      <Box textStyle="formLabel">Household*</Box>
      <Field name="selectedHousehold">
        {({ input, meta }) => (
          <>
            <HouseholdSearch
              onChange={(option: ExtendedSearchOption) => {
                input.onChange(option);

                const { partnerID, iaCode, insuranceCode } = option.household;
                formChange('partnerID', partnerID);
                formChange('insuranceCode', insuranceCode);
                formChange('iaCode', iaCode);

                let clientType = '';
                if (iaCode && insuranceCode) {
                  clientType = BOTH_CLIENT_TYPE;
                } else if (iaCode) {
                  clientType = INVESTMENT_CLIENT_TYPE;
                } else if (insuranceCode) {
                  clientType = INSURANCE_CLIENT_TYPE;
                }
                formChange('clientType', clientType);
              }}
              value={input.value}
            />
            {meta.touched && meta.error ? (
              <RFFErrorMessage errorNotificationUUID={input.name}>
                {meta.error}
              </RFFErrorMessage>
            ) : null}
          </>
        )}
      </Field>
    </>
  )
}

export default EntityHouseholdSelector;

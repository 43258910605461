import webstomp, { Client, Message, Subscription, SubscriptionsMap } from 'webstomp-client';
import { EnhancedStore } from '@reduxjs/toolkit/src/configureStore';
import { QContext, REACT_APP_QWEALTH_QC_API } from '@qwealth/qdata';
import { Message as QMessage } from 'data/models/Message';
import { putMessages } from '../data/actions/messages';

export class WebSocketService {
  private static _instance: WebSocketService = new WebSocketService();

  private _websocketUrl = `${(REACT_APP_QWEALTH_QC_API ?? '')
    .replace('https', 'wss')
    .replace('http', 'ws')
    .replace('/v1', '')}`;
  private _email?: string;
  private _idToken?: string;
  private _client: Client | null = null;
  private _store: EnhancedStore | null = null;

  private constructor() {
  }

  private connectCallback(): void {
    console.log('WebSocket Connected!');
    WebSocketService.subscribe('/topic/messages', (messageOutput) => {
      const messages = JSON.parse(messageOutput.body) as Array<QMessage>;
      const filteredMessages = messages.filter(({ recipient }) => recipient.toLowerCase() === this._email?.toLowerCase());
      this._store?.dispatch(putMessages(filteredMessages));
    });
  };

  private connectClient(): void {
    try {
      if (this._client?.connected === true) {
        this._client.disconnect(() => {
          console.log('Disconnected from WS...');
        });
      }
      console.log('Connecting to WS...');
      const client = webstomp.client(`${this._websocketUrl}/notification?token=${this._idToken}`, { debug: false });
      this._client = client;
      client.connect(
        { Authorization: `Bearer ${this._idToken}`, user_uuid: QContext.getUserUuid() },
        this.connectCallback.bind(this));
    } catch (e) {
      console.error(e);
      this._client = null;
    }
  }

  public static getInstance(): WebSocketService {
    return WebSocketService._instance;
  }

  public static getIdToken(): string | undefined {
    return this.getInstance()._idToken;
  }

  public static setReduxStore(store: EnhancedStore): void {
    this._instance._store = store;
  }

  public static connect(email: string, idToken: string): void {
    this._instance._email = email;
    this._instance._idToken = idToken;
    this._instance.connectClient.bind(this._instance)();
  }
  
  public static send(destination: string, message: Record<string, unknown>): void {
    if (this._instance._client?.connected !== true) {
      console.warn('client unavailable!');
      this._instance.connectClient();
    }

    if (this._instance._client?.connected === true) {
      this._instance._client.send(destination, JSON.stringify(message));
    }
  }

  public static subscribe(destination: string, callback?: (message: Message) => void): Subscription | null {
    if (this._instance._client?.connected !== true) {
      console.warn('client unavailable!');
      return null;
    }
    return this._instance._client.subscribe(destination, callback, { user_uuid: QContext.getUserUuid() });
  }

  public static getSubscriptions(): SubscriptionsMap {
    if (this._instance._client?.connected !== true) {
      console.warn('client unavailable!');
      return {};
    }
    return this._instance._client.subscriptions;
  }
}

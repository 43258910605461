import { Dispatch } from 'redux';
import { getDateRange } from 'utils/ActivityUtil';
import {
  deleteMeetingNoteGraph,
  fetchMeetingNotesWithQID,
  startMeetingNoteProcess,
  updateMeetingNote,
} from 'data/data-layer/meetingNotes';
import { errorHandler } from '../../services/axiosService';
import { MeetingNote } from 'data/models/meetingNote';
import fetchFilteredEvents from './microsoft/helper';
import { ALL_DATE_RANGE } from '../refData/acitivites';

export const SET_LOADING_STATUS = 'SET_LOADING_STATUS';
export const SET_CREATE_STATUS = 'SET_CREATE_STATUS';
export const PUT_MEETING_NOTE = 'PUT_MEETING_NOTE';
export const DELETE_MEETING_NOTE = 'DELETE_MEETING_NOTE';
export const PUT_MEETING_NOTES_EVENTS = 'PUT_MEETING_NOTES_EVENTS';
export const EDIT_MEETING_NOTE = 'EDIT_MEETING_NOTE';
export const RESET_MEETING_NOTES = 'RESET_MEETING_NOTES';
export const RESET_MEETING_NOTE_FORM = 'RESET_MEETING_NOTE_FORM';

const setLoading = isLoading => ({
  type: SET_LOADING_STATUS,
  isLoading,
});

const setCreateStatus = status => ({
  type: SET_CREATE_STATUS,
  status,
});

const putMeetingNotes = meetingNotes => ({
  type: PUT_MEETING_NOTE,
  meetingNotes,
});

const removeMeetingNote = id => ({
  type: DELETE_MEETING_NOTE,
  id,
});

const putMeetingNotesEvents = events => ({
  type: PUT_MEETING_NOTES_EVENTS,
  events,
});

const editMeetingNote = meetingNote => ({
  type: EDIT_MEETING_NOTE,
  meetingNote,
});

export const resetMeetingNotes = () => ({ type: RESET_MEETING_NOTES });

export const resetMeetingNoteForm = () => ({ type: RESET_MEETING_NOTE_FORM });

export const loadEvents = (timeZone: string, householdId: string) => async (dispatch): Promise<void> => {
  const dateRange = getDateRange(timeZone, ALL_DATE_RANGE);

  try {
    const events = await fetchFilteredEvents({ timeZone, filterDateRange: dateRange, householdQID: householdId });

    await dispatch(putMeetingNotesEvents(events));
  } catch (error) {
    await errorHandler(dispatch, 'Error while loading Past Events')(error);
  }
};

export const loadAllMeetingNotes = (QID: string) => async (dispatch: Dispatch) => {
  try {
    const meetingNotes = await fetchMeetingNotesWithQID(QID);
    dispatch(putMeetingNotes(meetingNotes));
  } catch (e) {
    errorHandler(dispatch, 'Eror while loading all Meeting Notes')(e);
  }
};

export const createMeetingNote =
  (meetingNote: MeetingNote, meetingNoteFiles: Array<File>) => async (dispatch: Dispatch) => {
    dispatch(setCreateStatus({ loading: true, success: false }));
    try {
      const newMeetingNote = await startMeetingNoteProcess(meetingNote, meetingNoteFiles);
      dispatch(putMeetingNotes(newMeetingNote));

      dispatch(setCreateStatus({ loading: false, success: true }));
    } catch (e) {
      errorHandler(dispatch, 'Error while creating Meeting Note')(e);
    } finally {
      dispatch(setCreateStatus({ loading: false }));
    }
  };

export const deleteMeetingNote = (id: number) => async (dispatch: Dispatch) => {
  dispatch(setLoading(true));
  try {
    await deleteMeetingNoteGraph(id);
    dispatch(removeMeetingNote(id));
  } catch (e) {
    errorHandler(dispatch, 'Error while deleting Meeting Note')(e);
  } finally {
    dispatch(setLoading(false));
  }
};

export const updateNote = (meetingNote: MeetingNote) => async (dispatch: Dispatch) => {
  dispatch(setCreateStatus({ loading: true, success: false }));
  try {
    const newMeetingNote = await updateMeetingNote(meetingNote);
    dispatch(editMeetingNote(newMeetingNote));
    dispatch(setCreateStatus({ loading: false, success: true }));
  } catch (e) {
    errorHandler(dispatch, 'Error while Updating Meeting Note')(e);
  } finally {
    dispatch(setCreateStatus({ loading: false }));
  }
};

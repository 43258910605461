import { createAction } from '@reduxjs/toolkit';
import {
  deleteQLog,
  deleteTask,
  generateLog,
  generateTask,
  getAllLogs,
  QLog,
  QTask,
  TASK_COMPLETED,
  TASK_IN_PROGRESS,
  updateLog,
  updateTask,
} from '@qwealth/qdata';
import { deleteFiles, getPathContents } from '@qwealth/qvault';
import { errorHandler } from 'services/axiosService';
import { deleteHouseholdActivity } from './householdActivities';
import { getCallLogFilter, getTasksByExtendedFilters, sortActivitiesByDate } from 'utils/activityFilterHelper';
import { QFeedActivity } from 'data/models/Activity';
import { QFeedActivityFilters } from '../models/ActivityFilters';
import { AppThunkDispatch, RootState } from '../store';
import { getTaskAttachmentPath } from 'data/helpers/taskHelper';
import { flattenList } from 'utils/DataUtil';
import { getLogAttachmentPath } from '../helpers/logHelper';

const PUT_ALL_ACTIVITIES = 'PUT_ALL_ACTIVITIES';
const SET_ACTIVITIES_LOADING = 'SET_ACTIVITIES_LOADING';
const PUT_ACTIVITY = 'PUT_ACTIVITY';
const UPDATE_ACTIVITY = 'UPDATE_ACTIVITY';
const REMOVE_ACTIVITY = 'REMOVE_ACTIVITY';

export const setActivitiesLoading = createAction<boolean>(SET_ACTIVITIES_LOADING);
export const putActivities = createAction<Array<QFeedActivity>>(PUT_ALL_ACTIVITIES);
export const putActivity = createAction<QFeedActivity>(PUT_ACTIVITY);
export const updateActivity = createAction<QFeedActivity>(UPDATE_ACTIVITY);
export const removeActivity = createAction<number | string | undefined>(REMOVE_ACTIVITY);

export const loadAllActivities =
  (activityFiltersAndEmail: QFeedActivityFilters) => async (dispatch: AppThunkDispatch, getState: () => RootState): Promise<void> => {
    if (getState().activities.isLoading) {
      // TODO: find better way to cancel previous promises
      return Promise.reject();
    }

    const { filterActivities, sortDirection } = activityFiltersAndEmail;

    dispatch(setActivitiesLoading(true));
    const promises: Array<Promise<Array<QFeedActivity>>> = [];
    if (filterActivities.includes('logs')) {
      promises.push(getAllLogs(getCallLogFilter(activityFiltersAndEmail)));
    }

    if (filterActivities.includes('tasks')) {
      promises.push(getTasksByExtendedFilters(activityFiltersAndEmail));
    }

    await Promise
      .all(promises)
      .then(flattenList)
      .then(activities => activities.sort(sortActivitiesByDate(sortDirection)))
      .then(activityList => dispatch(putActivities(activityList)))
      .catch(errorHandler(dispatch, 'Loading activities failed'))
      .finally(() => dispatch(setActivitiesLoading(false)));
  };

const createActivityLog = (qLog: QLog) => async (dispatch: AppThunkDispatch): Promise<QLog | null> => {
  try {
    const createdLog = await generateLog(qLog);
    dispatch(putActivity(createdLog));

    return createdLog;
  } catch (error) {
    await errorHandler(dispatch, 'Creating activity failed')(error);
    return null;
  }
};

const deleteKeysFromPath = async (path: string): Promise<void> => {
  let keys: string[] = [];
  await getPathContents(path).then(contentList => {
    if (contentList) {
      keys = contentList
        .filter(({ key}) => key?.includes(path))
        .map(({ key}) => key!);
    }
  });

  if (keys.length > 0) {
    await deleteFiles(keys);
  }
}

export const deleteLogAttachment = async (log: QLog): Promise<void> => {
  if (log?.id && log?.householdQID) {
    const path = getLogAttachmentPath(log.householdQID, log.id);
    await deleteKeysFromPath(path);
  }
}

export const deleteCallLog = (log: QLog) => async (dispatch: AppThunkDispatch): Promise<void> => {
  try {
    if (!log.id) {
      await errorHandler(dispatch, 'Failed to delete call log')();
      return;
    }
    await deleteQLog(log.id);
    dispatch(deleteHouseholdActivity(log));
    dispatch(removeActivity(log.id));
    await deleteLogAttachment(log);
  } catch (err) {
    await errorHandler(dispatch, 'Failed to delete call log')(err);
  }
};

export const saveActivityLog = (log: QLog) => async (dispatch: AppThunkDispatch): Promise<QLog | null> =>
  log.id ? await dispatch(updateActivityLog(log)) : await dispatch(createActivityLog(log));

const updateActivityLog = (log: QLog) => async (dispatch): Promise<QLog | null> => {
  try {
    const updatedLog = await updateLog(log);
    dispatch(updateActivity(updatedLog));
    return updatedLog;
  } catch (error) {
    await errorHandler(dispatch, 'Creating activity failed')(error);
    return null;
  }
};

export const saveActivityTask = (task: QTask) => async (dispatch: AppThunkDispatch): Promise<QTask | null> =>
  task.id ? await dispatch(updateActivityTask(task)) : await dispatch(createActivityTask(task));

const createActivityTask = (task: QTask) => async (dispatch): Promise<QTask | null> => {
  try {
    const createdTask = await generateTask(task);
    await dispatch(putActivity(createdTask));
    return createdTask;
  } catch (error) {
    await errorHandler(dispatch, 'Creating activity failed')(error);
    return null;
  }
};

const updateActivityTask = (task: QTask) => async (dispatch): Promise<QTask | null> => {
  try {
    const updatedTask = await updateTask(task);
    dispatch(updateActivity(updatedTask));
    return updatedTask;
  } catch (error) {
    await errorHandler(dispatch, 'Updating activity failed')(error);
    return null;
  }
};

export const resetTask = (task: QTask) => async (dispatch: AppThunkDispatch): Promise<void> => {
  const completedTask: QTask = { ...task, status: TASK_IN_PROGRESS };

  try {
    const updatedTask = await updateTask(completedTask);
    dispatch(updateActivity(updatedTask));
  } catch (error) {
    await errorHandler(dispatch, 'Could not reset task')(error);
  }
};

export const completeTask = (task: QTask) => async (dispatch: AppThunkDispatch): Promise<void> => {
  const completedTask: QTask = { ...task, status: TASK_COMPLETED };

  try {
    const updatedTask = await updateTask(completedTask);
    dispatch(updateActivity(updatedTask));
  } catch (error) {
    await errorHandler(dispatch, 'Could not complete task')(error);
  }
};

export const deleteTaskAttachments = async (task: QTask): Promise<void> => {
  if (task.id && task.householdQID) {
    const path = getTaskAttachmentPath(task.householdQID, task.id);
    await deleteKeysFromPath(path);
  }
}

export const deleteQFeedTask = (task: QTask) => async (dispatch: AppThunkDispatch): Promise<void> => {
  try {
    await deleteTask(task);
    dispatch(removeActivity(task.id));
    await deleteTaskAttachments(task);
  } catch (error) {
    await errorHandler(dispatch, 'Could not delete task')(error);
  }
};

import { createReducer } from '@reduxjs/toolkit';
import { AnnualReview } from '@qwealth/qdata';
import { putAnnualReview, setAnnualReviews } from 'data/actions/annualReview';

export default createReducer([] as AnnualReview[], (builder) => {
  builder.addCase(setAnnualReviews, (state, action) => action.payload);

  builder.addCase(putAnnualReview, (state, action) => {
    const annualReview = action.payload;
    if (!annualReview) {
      return state;
    }

    return state.map(review => (review.id !== annualReview.id ? review : annualReview));
  });
});

import { omit } from 'lodash';
import { addRelatedContact, PostResponse, RelatedContact } from '@qwealth/qdata';
import { saveRelatedContactDocument } from '@qwealth/kyc';

export type RelatedContactForm = RelatedContact & {
  pending: Array<File>;
}

export const saveRelatedContact = async (relatedContact: RelatedContactForm): Promise<RelatedContact[]> => {
  const s3DocumentLocation = await saveRelatedContactDocument(relatedContact, relatedContact.ownerQid, relatedContact.relationshipType);

  const response = await addRelatedContact({
    ...omit(relatedContact, 'pending'),
    s3DocumentLocation
  });

  // @ts-ignore
  const data: PostResponse = response.data as PostResponse;

  const savedRelatedContacts: RelatedContact[] = [data.relatedContact];
  if (data.trustedRelatedContact) {
    savedRelatedContacts.push(data.trustedRelatedContact);
  }
  if (data.authorizedIndividual) {
    savedRelatedContacts.push(data.authorizedIndividual);
  }
  return savedRelatedContacts;
}
import { Message, Recipient } from '@microsoft/microsoft-graph-types';
import moment from 'moment';
import { omit } from 'lodash';
import { QMessageMetadata } from '@qwealth/qdata';
import { S3Item } from '@qwealth/qvault';
import { v4 as uuidv4 } from 'uuid';

const toStringBoolean = (value?: number) => (value === 1 ? 'true' : 'false');

// amazon metadata headers must be lowercase per the documentation. using kebab case to keep with AWS convention
export const toMetadata = (message: QMessageMetadata): { [key: string]: string } => ({
  ...omit(message, ['isMeaningfulInteraction', 'isSignificantChange', 'isPortfolioReview']),
  to: message.to.join(','),
  'is-meaningful-interaction': toStringBoolean(message.isMeaningfulInteraction),
  'is-significant-change': toStringBoolean(message.isSignificantChange),
  'is-portfolio-review': toStringBoolean(message.isPortfolioReview),
});

export const dateFormat = 'ddd, MMM Do, YYYY h:mm a';

export const formatMessageDate = (message: Message, formatFlag?: boolean): string => {
  const { receivedDateTime, sentDateTime } = message;
  const rawDate = receivedDateTime || sentDateTime;
  const date = rawDate ? moment(rawDate) : moment();
  return formatFlag ? date.format(dateFormat) : date.format();
};

export const recipientToEmail = (recipient: Recipient): string =>
  recipient.emailAddress?.address || '';

export const fromMessageToMetadata = (message: Message): { [key: string]: string } => {
  const { from, toRecipients, subject } = message;
  const to = toRecipients?.map(recipientToEmail).join(',') || '';
  return {
    from: from?.emailAddress?.address || '',
    subject: subject ? encodeURI(subject) : '',
    date: formatMessageDate(message),
    to,
  };
};

const fromStringBoolean = (value: string) => (value === 'true' ? 1 : 0);

export interface S3MessageMetadata {
  from?: string;
  subject?: string;
  to?: string;
  date?: string;
  notes?: string;
}

// amazon metadata headers must be lowercase per the documentation. using kebab case to keep with AWS convention
export const fromS3ItemToMetadata = (item: S3Item): QMessageMetadata => {
  const { key = {} } = item;
  const metadata: S3MessageMetadata = item.metadata ?? {};
  const { to, subject, notes } = metadata;
  const from = metadata.from ?? '';
  const date = metadata.date ?? '';
  return {
    id: key || uuidv4(),
    to: to?.split(',') ?? [],
    isMeaningfulInteraction: fromStringBoolean(metadata['is-meaningful-interaction']),
    isSignificantChange: fromStringBoolean(metadata['is-significant-change']),
    isPortfolioReview: fromStringBoolean(metadata['is-portfolio-review']),
    // use decodeURI to parse out special characters
    subject: subject ? decodeURI(subject) : '',
    from,
    notes,
    date,
  };
};

export const formatSubject = (subject: string): string =>
  subject?.toLowerCase().includes('re') ? subject : `Re: ${subject}`;

import React from 'react';
import * as PropTypes from 'prop-types';
import { AppLayout, Box } from '@qwealth/qcore';

export default function QApp(props) {
  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      style={{ cursor: 'pointer' }}
    >
      <div>
        <img onClick={props.onClick} src={props.img} alt={props.alt} />
      </div>
      <AppLayout.SideNavCollapsedHidden>
        <Box fontSize="12px">{props.name}</Box>
      </AppLayout.SideNavCollapsedHidden>
    </Box>
  );
}

QApp.propTypes = {
  name: PropTypes.string.isRequired,
  img: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
  onClick: PropTypes.func,
};

QApp.defaultProps = {
  onClick: () => {},
};

import { dateFormat, QTask, TASK_NOT_STARTED, TASK_PRIORITY_MEDIUM, TASK_TYPES } from '@qwealth/qdata';
import moment from 'moment/moment';
import { getPartnerShortCode } from '@qwealth/qcore';

export const DEFAULT_TASK: QTask = {
  assignee: '',
  details: '',
  dueDate: moment().format(dateFormat),
  title: '',
  householdQID: undefined,
  isInternal: 1,
  partnerID: -1,
  status: TASK_NOT_STARTED,
  taskType: TASK_TYPES[0],
  createdBy: '',
  priority: TASK_PRIORITY_MEDIUM,
};

export const getTaskAttachmentPath = (householdId: string, id: number): string => {
  const shortCode = getPartnerShortCode(householdId);
  return `${shortCode}/${householdId}/Internal/Task Attachments/${id}/`;
};
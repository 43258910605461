import axios from 'axios';
import { omit } from 'lodash';
import { Dispatch } from 'redux';
import moment from 'moment';
import { createAction } from '@reduxjs/toolkit';

import { responseEntityMapper, responseListMapper } from '@qwealth/qcore';
import {
  FeeRerun,
  FeeRun,
  FeeRunTrigger,
  FeeRunTriggerType,
  FeesPostFile,
  FeesRecord,
  FeesWaiveDispute,
  GroupTriggerType,
  IACode,
  PartnerTriggerType,
  FeeReviewsQueryParams,
} from '@qwealth/qdata';

import { errorHandler, REACT_APP_QWEALTH_API } from 'services/axiosService';
import { AppThunkDispatch } from '../store';
import { QQueue } from '../models/qqueue';
import { fetchFeeReviews } from '../data-layer/fees'

const PUT_ALL_FEES_REVIEWS = 'PUT_ALL_FEES_REVIEWS';
const PUT_ALL_WAIVE_DISPUTES = 'PUT_ALL_WAIVE_DISPUTES';
const PUT_ALL_FEES_RUNS = 'PUT_ALL_FEES_RUNS';
const SET_WAIVE_DISPUTE_TYPE_IN_REVIEW = 'SET_WAIVE_DISPUTE_TYPE_IN_REVIEW';
const PUT_WAIVE_DISPUTE = 'PUT_WAIVE_DISPUTE';
const REMOVE_WAIVE_DISPUTE = 'REMOVE_WAIVE_DISPUTE';
const PUT_FEES_RUN = 'PUT_FEES_RUN';

export const putAllFeesReviews = createAction<FeesRecord[]>(PUT_ALL_FEES_REVIEWS);
export const putAllWaiveDisputes = createAction<FeesWaiveDispute[]>(PUT_ALL_WAIVE_DISPUTES);
export const putAllFeeRuns = createAction<FeeRun[]>(PUT_ALL_FEES_RUNS);
export const putWaiveDispute = createAction<FeesWaiveDispute>(PUT_WAIVE_DISPUTE);
export const removeWaiveDispute = createAction<FeesWaiveDispute>(REMOVE_WAIVE_DISPUTE);
export const putFeeRun = createAction<FeeRun>(PUT_FEES_RUN);
export const setWaiveDisputeTypeInReview = createAction<FeesWaiveDispute>(SET_WAIVE_DISPUTE_TYPE_IN_REVIEW);

export const loadFeeReviews =
  (params: FeeReviewsQueryParams = {}) =>
  async (dispatch: Dispatch): Promise<void> => {
    try {
      const feesReviews = await fetchFeeReviews(params);
      dispatch(putAllFeesReviews(feesReviews));
    } catch (e) {
      await errorHandler(dispatch, 'API Error: Failed Fetching Fee Reviews')(e);
    }
  };

export const fetchWaivesDisputes =
  (params: { feeRunID?: number } = {}) =>
  async (dispatch: Dispatch): Promise<FeesWaiveDispute[]> => {
    try {
      const response = await axios.get(`${REACT_APP_QWEALTH_API}/fees/review`, { params });
      const waiveDisputes: FeesWaiveDispute[] = response.data;
      dispatch(putAllWaiveDisputes(waiveDisputes));
      return waiveDisputes;
    } catch (e) {
      await errorHandler(dispatch, 'API Error: Failed Fetching Waives/Changes')(e);
      return [];
    }
  };

export const addOrUpdateWaiveDispute =
  (aggregateWaiveDispute: FeesWaiveDispute, setFeeReviewStatus = false) =>
  async (dispatch: Dispatch): Promise<FeesWaiveDispute> => {
    const baseWaiveDispute = omit(aggregateWaiveDispute, [
      'id',
      'accountHouseholdName',
      'accountHouseholdQID',
      'accountNumber',
      'accountOwnersName',
      'accountOwnersQID',
      'jobId',
      'partnerID',
      'periodStart',
      'runStatus',
      'uuid',
    ]);

    const response = !aggregateWaiveDispute.id
      ? await axios.post(`${REACT_APP_QWEALTH_API}/fees/review`, baseWaiveDispute)
      : await axios.patch(
          `${REACT_APP_QWEALTH_API}/fees/review/${aggregateWaiveDispute.id}`,
          baseWaiveDispute,
        );
    try {
      const newBaseWaiveDispute = responseEntityMapper<FeesWaiveDispute>(response);
      const updatedWaiveDispute = { ...aggregateWaiveDispute, ...newBaseWaiveDispute };
      dispatch(putWaiveDispute(updatedWaiveDispute));
      if (setFeeReviewStatus) dispatch(setWaiveDisputeTypeInReview(newBaseWaiveDispute));
      return Promise.resolve(updatedWaiveDispute);
    } catch (e) {
      await errorHandler(dispatch, 'API Error: Failed Adding/Updating a Waive/Change')(e);
      return Promise.reject(null);
    }
  };

export const deleteWaivesDispute =
  (feesWaiveDispute: FeesWaiveDispute) =>
  (dispatch: Dispatch): void => {
    axios
      .delete(`${REACT_APP_QWEALTH_API}/fees/review/${feesWaiveDispute.id}`)
      .then(() => dispatch(removeWaiveDispute(feesWaiveDispute)))
      .catch(errorHandler(dispatch, 'API Error: Failed Deleting a Waive/Change'));
  };

export const fetchFeeRuns =
  () =>
  async (dispatch: Dispatch): Promise<FeeRun[]> => {
    try {
      const response = await axios.get(`${REACT_APP_QWEALTH_API}/fees/runs/`);
      const feeRuns = responseListMapper<FeeRun>(response);
      dispatch(putAllFeeRuns(feeRuns));
      return feeRuns;
    } catch (e) {
      await errorHandler(dispatch, 'API Error: Failed Fetching Fee Runs')(e);
      return [];
    }
  };

export const addOrUpdateFeeRun =
  (feeRun: Partial<FeeRun>) =>
  async (dispatch: Dispatch): Promise<FeeRun> => {
    const feeRunData = omit(feeRun, ['id']);

    const response = !feeRun.id
      ? await axios.post(`${REACT_APP_QWEALTH_API}/fees/runs`, feeRunData)
      : await axios.patch(`${REACT_APP_QWEALTH_API}/fees/runs/${feeRun.id}`, feeRunData);
    try {
      const feeRun = responseEntityMapper<FeeRun>(response);
      dispatch(putFeeRun(feeRun));
      return Promise.resolve(feeRun);
    } catch (e) {
      await errorHandler(dispatch, 'API Error: Failed Adding/Updating a Fee Run')(e);
      return Promise.reject(null);
    }
  };

export const fetchAllIaCodes = async (dispatch: Dispatch): Promise<IACode[]> => {
  try {
    const response = await axios.get(`${REACT_APP_QWEALTH_API}/iaCodes/`);
    return responseListMapper<IACode>(response);
  } catch (e) {
    await errorHandler(dispatch, 'API Error: Failed Fetching All IA Codes')(e);
    return [];
  }
};

export const fetchPostFile =
  (feeRunId: number) =>
  async (dispatch: Dispatch): Promise<FeesPostFile[]> => {
    try {
      const response = await axios.get(`${REACT_APP_QWEALTH_API}/fees/postfile/feeRun/${feeRunId}`);
      return responseListMapper<FeesPostFile>(response);
    } catch (e) {
      await errorHandler(dispatch, 'API Error: Failed Fetching a Post File')(e);
      return [];
    }
  };

export const triggerFeeRun =
  (feeRunTriggerData: FeeRunTrigger, type: FeeRunTriggerType) =>
  async (dispatch: Dispatch): Promise<number> => {
    let endpoint = '';
    if (type === PartnerTriggerType) {
      endpoint = '/fees/processSingleFeePartner/';
    } else if (type === GroupTriggerType) {
      endpoint = '/fees/processSingleFeeGroup/';
    }

    return await axios
      .post(`${REACT_APP_QWEALTH_API}${endpoint}`,
        feeRunTriggerData,
        {
          timeout: moment.duration(2, 'minutes').asMilliseconds()
        })
      .then(response => response.status)
      .catch(error => {
        errorHandler(dispatch, 'API Error: Failed Triggering a Fee Run')(error);
        return Promise.reject(null);
      });
  };

export const triggerPostFileGeneration =
  (feeRunIds: number[]) => (dispatch: Dispatch): void => {
    try {
      Promise.all(
        feeRunIds.map(feeRunId =>
          axios.post(`${REACT_APP_QWEALTH_API}/fees/processPostFileData/`, { feeRunId }),
        ),
      );
    } catch (e) {
      errorHandler(dispatch, 'API Error: Failed Triggering Post File Generation')(e);
    }
  };

export const triggerFeeRerun =
  (feeRerunData: FeeRerun) =>
  async (dispatch: Dispatch): Promise<number> => {
    try {
      return await axios
        .post(`${REACT_APP_QWEALTH_API}/fees/changesDispute/`, feeRerunData)
        .then(response => response.status);
    } catch (e) {
      errorHandler(dispatch, 'API Error: Failed Triggering Rerunning Fees')(e);
      return Promise.reject(null);
    }
  };

export const getAllFeeProcess = () => (dispatch: AppThunkDispatch): Promise<Array<QQueue>> =>
  axios
    .get(`${REACT_APP_QWEALTH_API}/fees/processAllFees`)
    .then(response => responseListMapper<QQueue>(response))
    .catch(err => {
      errorHandler(dispatch, 'Could not retrieve QQueue')(err);
      return [];
    });

export const deleteFeeProcess = (id: number) => (dispatch: AppThunkDispatch): Promise<void> =>
  axios
    .delete(`${REACT_APP_QWEALTH_API}/fees/processAllFees/${id}`)
    .catch(errorHandler(dispatch, 'Failed to delete fee run process'));

import {
  PUT_MEETING_NOTE,
  DELETE_MEETING_NOTE,
  PUT_MEETING_NOTES_EVENTS,
  EDIT_MEETING_NOTE,
  RESET_MEETING_NOTES,
  RESET_MEETING_NOTE_FORM,
  SET_LOADING_STATUS,
  SET_CREATE_STATUS,
} from 'data/actions/householdMeetingNotes';
import { MeetingNote } from '../models/meetingNote';
import { CalendarEvent } from '../data-layer/event';

interface State {
  meetingNotes: Array<MeetingNote>;
  events: Array<CalendarEvent>;
  isLoading: boolean;
  status: {
    loading: boolean;
    success: boolean;
    shareSuccess: boolean;
  }
}

const initialState: State = {
  meetingNotes: [],
  events: [],
  isLoading: true,
  status: { loading: false, success: false, shareSuccess: false },
};

export default function (state = initialState, action): State {
  switch (action.type) {
    case PUT_MEETING_NOTE: {
      const { meetingNotes } = action;
      return { ...state, meetingNotes: state.meetingNotes.concat(meetingNotes) };
    }
    case DELETE_MEETING_NOTE: {
      const { id } = action;
      return {
        ...state,
        meetingNotes: state.meetingNotes.filter(mt => mt.id !== id),
      };
    }
    case PUT_MEETING_NOTES_EVENTS: {
      const { events } = action;
      return { ...state, events, isLoading: false };
    }
    case EDIT_MEETING_NOTE: {
      const { meetingNote } = action;
      const { meetingNotes } = state;
      return {
        ...state,
        meetingNotes: meetingNotes.map(mt => {
          if (mt.id === meetingNote.id) {
            return { ...mt, ...meetingNote };
          }
          return mt;
        }),
      };
    }
    case SET_LOADING_STATUS: {
      return { ...state, isLoading: action.isLoading };
    }
    case SET_CREATE_STATUS: {
      return { ...state, status: { ...state.status, ...action.status } };
    }
    case RESET_MEETING_NOTES: {
      return initialState;
    }
    case RESET_MEETING_NOTE_FORM: {
      return { ...state, status: { loading: false, success: false, shareSuccess: false } };
    }
    default:
      return state;
  }
}

import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { BsBell, BsBellFill } from 'react-icons/bs';
import { useNavigate } from 'react-router';
import { Box, styled } from '@qwealth/qcore';
import { selectUnreadMessageCount } from 'data/selectors/messageSelector';

const Badge = styled(Box)`
  position: absolute;
  top: -12px;
  right: -12px;
  background-color: red;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  font-weight: bold;
  text-align: center;
`;

const AlertBadge: FC = (): JSX.Element => {
  const unreadMessageCount = useSelector(selectUnreadMessageCount);

  const navigate = useNavigate();
  return (
    <Box
      style={{ position: 'relative', cursor: 'pointer' }}
      onClick={() => {
        navigate({ pathname: '/alerts', });
      }}
    >
      {unreadMessageCount > 0 ? <BsBellFill size={35} /> : <BsBell size={35}/>}
      {unreadMessageCount > 0 && <Badge><p>{unreadMessageCount}</p></Badge>}
    </Box>
  );
}

export default AlertBadge;

import { updateItemAndGetArrayImmutably } from '@qwealth/qcore';
import { AccountBeneficiary, OpeningAccount, RelinquishingAccount } from '@qwealth/qdata';
import {
  PUT_OPENING_ACCOUNT,
  PUT_OPENING_ACCOUNT_BENEFICIARY,
  PUT_OPENING_RELINQUISHING_ACCOUNT,
  PUT_OPENING_PAYOUT_DETAILS,
} from '@qwealth/kyc';
import {
  PUT_OPENING_ACCOUNTS,
  REMOVE_OPENING_ACCOUNT,
} from '../actions/openingAccounts';

interface IOpeningAccountsState {
  initialized: boolean;
  accounts: Array<OpeningAccount>;
}

const initialState: IOpeningAccountsState = {
  initialized: false,
  accounts: [],
};

export default function (state = initialState, action): IOpeningAccountsState {
  switch (action.type) {
    case PUT_OPENING_ACCOUNTS: {
      // Don't need Archived account ever
      return { ...state, initialized: true, accounts: action.accounts.filter(a=> a.status !== 'Archived') };
    }
    case REMOVE_OPENING_ACCOUNT: {
      const updatedAccounts = state.accounts.filter(({ id }) => id !== action.id);
      return { ...state, accounts: updatedAccounts };
    }
    case PUT_OPENING_ACCOUNT: {
      const { account } = action;
      const accounts: Array<OpeningAccount> = updateItemAndGetArrayImmutably(
        state.accounts,
        (a: OpeningAccount) => a.id === account.id,
        (targetAccount: OpeningAccount) =>
          targetAccount
            ? {
                ...targetAccount,
                ...account,
              }
            : {
                ...account,
                accountBeneficiaries: account.accountBeneficiaries || [],
                relinquishingAccount: account.relinquishingAccount || [],
                payoutAccountDetails: account.payoutAccountDetails || {},
              },
      );
      return { ...state, accounts };
    }
    case PUT_OPENING_ACCOUNT_BENEFICIARY: {
      const { accountBeneficiary, accountID } = action;
      const accounts: Array<OpeningAccount> = updateItemAndGetArrayImmutably(
        state.accounts,
        (a: OpeningAccount) => a.id === accountID,
        (targetAccount: OpeningAccount) =>
          targetAccount
            ? {
                ...targetAccount,
                accountBeneficiaries: updateItemAndGetArrayImmutably(
                  targetAccount.accountBeneficiaries,
                  (b: AccountBeneficiary) => b.id === accountBeneficiary.id || b.id === 0,
                  (targetAccountBeneficiary: AccountBeneficiary) =>
                    targetAccountBeneficiary
                      ? { ...targetAccountBeneficiary, ...accountBeneficiary }
                      : accountBeneficiary,
                ),
              }
            : undefined,
      );
      return { ...state, accounts };
    }
    case PUT_OPENING_RELINQUISHING_ACCOUNT: {
      const { relinquishingAccount, accountID } = action;
      const accounts: Array<OpeningAccount> = updateItemAndGetArrayImmutably(
        state.accounts,
        (a: OpeningAccount) => a.id === accountID,
        (targetAccount: OpeningAccount) =>
          targetAccount
            ? {
                ...targetAccount,
                relinquishingAccount: updateItemAndGetArrayImmutably(
                  targetAccount.relinquishingAccount,
                  (r: RelinquishingAccount) =>
                    (r.relinquishingAccountNumber === relinquishingAccount.relinquishingAccountNumber || r.id === 0),
                  (targetRelinquishingAccount: RelinquishingAccount) =>
                    targetRelinquishingAccount
                      ? { ...targetRelinquishingAccount, ...relinquishingAccount }
                      : relinquishingAccount,
                ),
              }
            : undefined,
      );
      return { ...state, accounts };
    }
    case PUT_OPENING_PAYOUT_DETAILS: {
      const { payoutAccountDetails, accountID } = action;
      const accounts: Array<OpeningAccount> = updateItemAndGetArrayImmutably(
        state.accounts,
        (a: OpeningAccount) => a.id === accountID,
        (targetAccount: OpeningAccount) =>
          targetAccount
            ? {
                ...targetAccount,
                payoutAccountDetails: {
                  ...targetAccount.payoutAccountDetails,
                  ...payoutAccountDetails,
                },
              }
            : undefined,
      );
      return { ...state, accounts };
    }
    default:
      return state;
  }
}

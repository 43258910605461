import { CalendarEvent, fetchEventsByHouseholdId } from 'data/data-layer/event';
import { DateRange } from 'data/models/DateRange';
import { getSharedCalendars } from '../../helpers/calendar';
import { findSharedCalendarId, getAccessibleCalendars } from 'services/microsoft/graphService';

interface Props {
  timeZone: string;
  filterDateRange: DateRange;
  householdQID: string;
}

const fetchFilteredEvents = async ({ timeZone, filterDateRange, householdQID }: Props): Promise<CalendarEvent[]> => {
  let allActivities: Array<CalendarEvent> = [];
  if (!filterDateRange) {
    return allActivities;
  }
  const { fromDate, toDate } = filterDateRange;
  const formatFromDate = fromDate.startOf('day').format();
  const formatToDate = toDate.endOf('day').format();
  try {
    const promises: Array<Promise<CalendarEvent[]>> = [fetchEventsByHouseholdId(
      timeZone,
      formatFromDate,
      formatToDate,
      householdQID,
      'DESC'
    )];

    const visibleSharedCalendarEmails = getSharedCalendars().filter(c => c.visible).map(c => c.email);
    if (visibleSharedCalendarEmails.length > 0) {
      const sharedCalendars = await getAccessibleCalendars();
      visibleSharedCalendarEmails
        .map(email => findSharedCalendarId(sharedCalendars, email))
        .forEach(calendarId => {
          if (calendarId) {
            promises.push(fetchEventsByHouseholdId(
              timeZone, formatFromDate, formatToDate, householdQID, 'DESC', calendarId));
          }
        });
    }
    
    const events = await Promise.all(promises)
      .then(responses => responses.reduce((all, current) => all.concat(current), []));

    allActivities = allActivities.concat(events);
  } catch (error) {
    console.error('failed to retrieve household events', error);
  }

  return allActivities;
};

export default fetchFilteredEvents;

import axios from 'axios';
import { createAction } from '@reduxjs/toolkit';
import { responseEntityMapper, responseListMapper } from '@qwealth/qcore';
import { IUser, toUser, userDto } from '@qwealth/qdata';
import { errorHandler, REACT_APP_QWEALTH_API } from '../../services/axiosService';
import { AppThunkAction } from '../store';

const PUT_USERS = 'PUT_USERS';
const PUT_USER = 'PUT_USER';
const REMOVE_USER = 'REMOVE_USER';
const RESET_USERS = 'RESET_USERS';
const LOADING_USERS = 'LOADING_USERS';

export const putUsers = createAction<IUser[]>(PUT_USERS);
export const putUser = createAction<IUser>(PUT_USER);
export const removeUser = createAction<IUser>(REMOVE_USER);
export const resetUsers = createAction(RESET_USERS);
export const setLoading = createAction<boolean>(LOADING_USERS)

export const loadUsers = (params = {}): AppThunkAction => (dispatch) => {
  dispatch(setLoading(true));
  return axios
    .get(`${REACT_APP_QWEALTH_API}/users`, { params })
    .then((resp) => responseListMapper<userDto>(resp))
    .then((users) => users.map(toUser))
    .then((users) => dispatch(putUsers(users)))
    .catch(errorHandler(dispatch, 'Could not load users'))
    .finally(() => dispatch(setLoading(false)));
}

export const saveUser =
  (user: IUser): AppThunkAction =>
  (dispatch) => {
    const { freshdeskID, iaCodes, insuranceCodes, partnerID, QID, ...apiUser } = user;
    const saveUser: userDto = {
      ...apiUser,
      IAcodes: iaCodes ? iaCodes.join(',') : '',
      insuranceCodes: insuranceCodes ? insuranceCodes.join(',') : '',
      freshdeskID: freshdeskID || '',
      partnerID: partnerID || 0,
      QID: QID || ''
    };

    const save = saveUser.id ? axios.patch : axios.post;
    const path = saveUser.id ? `users/id/${saveUser.id}` : 'users';
    return save(`${REACT_APP_QWEALTH_API}/${path}`, saveUser)
      .then((response) => responseEntityMapper<userDto>(response))
      .then((dto) => dispatch(putUser(toUser(dto))))
      .catch(errorHandler(dispatch, 'Could not save user. API Error'));
  };

export const deleteUser =
  (user: IUser): AppThunkAction =>
  (dispatch) => {
    const { id } = user;

    return axios
      .delete(`${REACT_APP_QWEALTH_API}/users/id/${id}`)
      .then(() => dispatch(removeUser(user)))
      .catch(errorHandler(dispatch, 'Could not delete user. API Error'));
  };

import axios, { AxiosResponse } from 'axios';
import { responseListMapper } from '@qwealth/qcore';
import { IMA, REACT_APP_QWEALTH_API } from '@qwealth/qdata';
import { ImaPayload } from '../models/OpeningAccountFees';

export const createIMA = (householdQid: string, imaPayload: ImaPayload): Promise<void> =>
  axios.post(`${REACT_APP_QWEALTH_API}/pdf/ima`, imaPayload);

export const getHouseholdIMAs = (householdQid: string): Promise<IMA[]> =>
  axios.get(`${REACT_APP_QWEALTH_API}/ima/${householdQid}`)
    .then(response => responseListMapper<IMA>(response));

export const deleteIma = (imaId: number): Promise<void> =>
  axios.delete(`${REACT_APP_QWEALTH_API}/ima/${imaId}`);

export const resendImaDocusign = (docusignEnvelopeId: string): Promise<void> =>
  axios.post(`${REACT_APP_QWEALTH_API}/docusignAction/resend/${docusignEnvelopeId}`);

export const downloadImaPdf = (docusignEnvelopeId: string): Promise<AxiosResponse> =>
  axios.post(`${REACT_APP_QWEALTH_API}/docusignAction/download/${docusignEnvelopeId}`, undefined, { responseType: 'blob' as const });

export const voidImaDocusign = (docusignEnvelopeId: string): Promise<void> =>
  axios.post(`${REACT_APP_QWEALTH_API}/docusignAction/void/${docusignEnvelopeId}`);

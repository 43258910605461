import { createAction } from '@reduxjs/toolkit';
import {
  IMA,
  updateIMA as updateIMAInfo,
} from '@qwealth/qdata';

import { errorHandler } from 'services/axiosService';
import { AppThunkDispatch } from '../store';
import { createIMA, deleteIma, getHouseholdIMAs, resendImaDocusign, voidImaDocusign } from '../data-layer/ima';
import { ImaPayload } from '../models/OpeningAccountFees';

const SET_ACTIVE_IMA = 'SET_ACTIVE_IMA';
const UPDATE_IMA = 'UPDATE_IMA';
const SET_IMA_LIST = 'SET_IMA_LIST';
const REMOVE_IMA = 'REMOVE_IMA';

export const setActiveIma = createAction<IMA>(SET_ACTIVE_IMA);
export const updateIma = createAction<IMA>(UPDATE_IMA);
export const setImaList = createAction<IMA[]>(SET_IMA_LIST);
export const removeImaById = createAction<number>(REMOVE_IMA);

export const createNewIMA = (householdQID: string, imaPayload: ImaPayload) => async (dispatch: AppThunkDispatch): Promise<boolean> => {
  try {
    await createIMA(householdQID, imaPayload);
    return true;
  } catch (e) {
    await errorHandler(dispatch, 'Creating IMA failed')(e);
    return false;
  }
};

export const updateIMA = (payload: IMA) => async (dispatch: AppThunkDispatch): Promise<void> => {
  try {
    const ima = await updateIMAInfo(payload) as IMA;
    dispatch(updateIma(ima));
  } catch (e) {
    await errorHandler(dispatch, 'Updating IMA failed')(e);
  }
};

export const removeIma = (imaId: number) => async (dispatch: AppThunkDispatch): Promise<void> => {
  try {
    await deleteIma(imaId);
    dispatch(removeImaById(imaId));
  } catch (e) {
    await errorHandler(dispatch, 'Deleting IMA failed')(e);
  }
}

export const resendIma = (docusignEnvelopeId: string) => async (dispatch: AppThunkDispatch): Promise<void> => {
  try {
    await resendImaDocusign(docusignEnvelopeId);
  } catch (e) {
    await errorHandler(dispatch, 'Resending IMA docusign failed')(e);
  }
}

export const voidIma = (docusignEnvelopeId: string) => async (dispatch: AppThunkDispatch): Promise<void> => {
  try {
    await voidImaDocusign(docusignEnvelopeId);
  } catch (e) {
    await errorHandler(dispatch, 'Voiding IMA docusign failed')(e);
  }
}

let isLoading = false;
export const fetchIMAList = (QID: string) => async (dispatch: AppThunkDispatch): Promise<void> => {
  try {
    if (isLoading) {
      return Promise.reject();
    }
    isLoading = true;
    const imas = await getHouseholdIMAs(QID);
    dispatch(setImaList(imas));
  } catch (e) {
    await errorHandler(dispatch, 'Fetching IMAs failed')(e);
  } finally {
    isLoading = false;
  }
};

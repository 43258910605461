import { HouseholdMetadata, Note } from '@qwealth/qdata';

export interface HouseholdMetadataState extends HouseholdMetadata {
  notes: Array<Note>;
  isLoading: boolean;
}

export const initialState: HouseholdMetadataState = {
  QID: '',
  addresses: [],
  addressOwnership: [],
  isInvestmentClient: 0,
  name: '',
  partnerID: 0,
  notes: [],
  members: [],
  legalEntities: [],
  isLoading: false,
  iaCode: '',
  insuranceCode: '',
  status: 'Active',
  isDiscretionary: false
};

import { Dispatch } from 'redux';
import { createAction } from '@reduxjs/toolkit';
import { Communication } from '../models/Communication';
import { getCommunicationByQID, saveCommunicationApi } from '../data-layer/communication';
import { errorHandler } from '../../services/axiosService';

const PUT_COMMUNICATION = 'PUT_COMMUNICATION';

export const putCommunication = createAction<Communication>(PUT_COMMUNICATION);

export const loadHouseholdCommunication = (qids: Array<string>) => (dispatch): Promise<void > =>
  Promise.all(qids.map(async QID => dispatch(getCommunicationByQID(QID))))
    .then((communications: Array<Communication>) => {
      communications.forEach(c => {
        if (c) {
          dispatch(putCommunication(c));
        }
      });

      qids
        .filter(
          qid =>
            !communications
              .filter(c => !!c)
              .map(c => c.QID)
              .includes(qid),
        )
        .forEach(QID => {
          dispatch(putCommunication({ QID } as Communication));
        });
    })
    .catch(errorHandler(dispatch, 'Could not load household communication'));

export const saveCommunication = (communication: Partial<Communication>) => (dispatch: Dispatch): Promise<void> =>
  saveCommunicationApi(communication)
    .then(updatedCommunication => dispatch(putCommunication(updatedCommunication)))
    .catch(errorHandler(dispatch, 'Could not save communication. Please contact support.'));

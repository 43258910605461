import React, { FC, ReactElement } from 'react';
import { RFFSelectInput2, useFieldAsState } from '@qwealth/qcore';
import { usePartnerIACodes } from 'hooks/usePartnerIACodes';

const fieldSubscription = { value: true, touched: true, error: true };

const IACodeField: FC = (): ReactElement => {
  const [selectedPartnerId] = useFieldAsState<number>('partnerID');
  const { iaCodeOptions } = usePartnerIACodes(selectedPartnerId as number);

  return (
    <RFFSelectInput2
      name="iaCode"
      label="IA Code*"
      subscription={fieldSubscription}
      inputFieldProps={{
        data: [{ label: 'Select IA Code', value: '' }, ...iaCodeOptions],
        toLabel: opt => opt.label,
        toValue: opt => opt.value
      }}
    />
  );
};

export default IACodeField;

import { useEffect, useState } from 'react';
import { isEmpty } from 'lodash';
import { useSelector } from 'react-redux';
import { useDispatchOnMount } from '@qwealth/qcore';
import { IACode, loadPartnerIaCodes, loadPartners, SelectOption } from '@qwealth/qdata';
import { RootState, useAppDispatch } from 'data/store';

const toOptions = (field: 'iaCode' | 'insuranceCode', array: Array<IACode>): Array<SelectOption<string>> =>
  array.map(code => {
    const discretionaryLabel = field === 'iaCode'
      ? ` | ${code.isDiscretionary === 1 ? 'Disc.' : 'Non-Disc.'}`
      : '';
    return {
      value: code[field] ?? '',
      label: `${code[field]} | ${code.teamMemberName}` + discretionaryLabel,
    };
  });

const getFilteredCodes = (field: 'iaCode' | 'insuranceCode', array: Array<IACode>): Array<IACode> =>
  array
    .filter((c) => c.isOwner === 1 && !!c[field])
    .filter((c, index, array) => array.map(c => c[field]).indexOf(c[field]) === index)
    .sort((a, b) => (a[field] ?? '').localeCompare(b[field] ?? ''))

interface Response {
  iaCodes: Array<IACode>;
  iaCodeOptions: Array<SelectOption<string>>;
  insuranceCodes: Array<IACode>;
  insuranceCodeOptions: Array<SelectOption<string>>;
}

export const usePartnerIACodes = (partnerID: number, includeAllOption?: boolean): Response => {
  const dispatch = useAppDispatch();
  const { initialized, partnerList } = useSelector((state: RootState) => state.partners);
  const [iaCodes, setIaCodes] = useState<Array<IACode>>([]);
  const [iaCodeOptions, setIaCodeOptions] = useState<Array<SelectOption<string>>>([]);
  const [insuranceCodes, setInsuranceCodes] = useState<Array<IACode>>([]);
  const [insuranceCodeOptions, setInsuranceCodeOptions] = useState<Array<SelectOption<string>>>([]);
  useDispatchOnMount(loadPartners, initialized ? undefined : true);

  useEffect(() => {
    const targetPartner = partnerList.find(ptnr => ptnr.id == partnerID);
    let iaCodeOptions: Array<SelectOption<string>> = [];
    let insuranceCodeOptions: Array<SelectOption<string>> = [];
    if (includeAllOption) {
      iaCodeOptions = [{ value: 'All', label: 'All IA\'s' }];
      insuranceCodeOptions = [{ value: 'All', label: 'All Insurance' }];
    }
    if (targetPartner) {
      const { iaCodes, id } = targetPartner;
      if (!iaCodes || iaCodes.length === 0) {
        if (id) {
          dispatch(loadPartnerIaCodes(id));
        }
      } else {
        if (!isEmpty(iaCodes)) {
          const filteredIaCodes = getFilteredCodes('iaCode', iaCodes);
          setIaCodes(filteredIaCodes);
          iaCodeOptions = iaCodeOptions.concat(toOptions('iaCode', filteredIaCodes));

          const filteredInsuranceCodes = getFilteredCodes('insuranceCode', iaCodes);
          setInsuranceCodes(filteredInsuranceCodes);
          insuranceCodeOptions = insuranceCodeOptions.concat(toOptions('insuranceCode', filteredInsuranceCodes));
        }
        setIaCodeOptions(iaCodeOptions);
        setInsuranceCodeOptions(insuranceCodeOptions);
      }
    } else {
      setIaCodes([]);
      setInsuranceCodes([]);
    }
    setIaCodeOptions(iaCodeOptions);
    setInsuranceCodeOptions(insuranceCodeOptions);
  }, [partnerID, partnerList, includeAllOption, dispatch]);

  return { iaCodes, iaCodeOptions, insuranceCodes, insuranceCodeOptions };
};

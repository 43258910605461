import { createAction } from '@reduxjs/toolkit';
import {
  AnnualReview,
  AppThunkDispatch,
  fetchAnnualReviewListWithQIDs,
  updateAnnualReview as patchAnnualReview,
} from '@qwealth/qdata';
import { errorHandler } from 'services/axiosService';

const LOAD_ANNUAL_REVIEWS = 'LOAD_ANNUAL_REVIEWS';
const PUT_ANNUAL_REVIEW = 'PUT_ANNUAL_REVIEW';

export const setAnnualReviews = createAction<AnnualReview[]>(LOAD_ANNUAL_REVIEWS);
export const putAnnualReview = createAction<AnnualReview>(PUT_ANNUAL_REVIEW);

export const loadAnnualReviews = (ids: Array<string>) => async (dispatch: AppThunkDispatch): Promise<void> => {
  try {
    const annualReviews = await fetchAnnualReviewListWithQIDs(ids);
    // @ts-ignore TODO: update fetchAnnualReviewListWithQIDs
    dispatch(setAnnualReviews(annualReviews));
  } catch (e) {
    await errorHandler(dispatch, 'Annual Review load failed')(e);
  }
};

export const updateAnnualReview = (annualReview: AnnualReview) => async (dispatch: AppThunkDispatch): Promise<AnnualReview | null> => {
  try {
    const savedAnnualReview = await patchAnnualReview(annualReview) as AnnualReview;
    dispatch(putAnnualReview(savedAnnualReview));
    return savedAnnualReview;
  } catch (e) {
    await errorHandler(dispatch, 'Annual Review update failed')(e);
    return null;
  }
};

import {
  AutoTransactions,
  fetchAutoTransactionsWithQID,
  generateAutoTransactions,
  getAccountOptionsDetails,
  updateAutoTransactions,
} from '@qwealth/qdata';
import { errorHandler } from '../../services/axiosService';

export const PUT_AUTO_TRANSACTIONS = 'PUT_AUTO_TRANSACTIONS';

export const loadAutoTransactions = (householdId: string) => async dispatch => {
  try {
    const autoTransactions = await fetchAutoTransactionsWithQID(householdId);
    dispatch({
      type: PUT_AUTO_TRANSACTIONS,
      autoTransactions,
    });
  } catch (e) {
    errorHandler(dispatch, 'Autotransactions load failed')(e);
  }
};

export const editAutotransaction = (transactionToEdit: AutoTransactions) => async dispatch => {
  try {
    const autoTransactions = await updateAutoTransactions(transactionToEdit);
    dispatch({
      type: PUT_AUTO_TRANSACTIONS,
      autoTransactions,
    });
  } catch (e) {
    errorHandler(dispatch)(e);
  }
};

export const createAutoTransaction = (newTransaction: AutoTransactions) => async dispatch => {
  try {
    const autoTransactions = await generateAutoTransactions(newTransaction);
    dispatch({
      type: PUT_AUTO_TRANSACTIONS,
      autoTransactions,
    });
  } catch (e) {
    errorHandler(dispatch)(e);
  }
};

export const getAccountsOptions = (householdId: string) => async dispatch => {
  try {
    const accountOptions = await getAccountOptionsDetails(householdId);
    return accountOptions;
  } catch (e) {
    errorHandler(dispatch)(e);
  }
};

import axios from 'axios';
import { Dispatch } from 'redux';
import moment from 'moment';
import { createAction } from '@reduxjs/toolkit';
import { saveKYC, fetchKycWithQIDs, KYC, dateFormat } from '@qwealth/qdata';
import { errorHandler, REACT_APP_QWEALTH_API } from 'services/axiosService';
import { AppThunkDispatch } from '../store';
import { complianceApprovalApi } from '../data-layer/kyc';

const ADD_KYC = 'ADD_KYC';
const LOAD_KYC = 'LOAD_KYC';

export const setKycMap = createAction<Record<string, KYC>>(LOAD_KYC);
export const addKyc = createAction<KYC>(ADD_KYC);

export const unlockKyc = (kycId: number, QID: string, userEmail: string) => async (dispatch: AppThunkDispatch): Promise<void> => {
  try {
    const kyc = await saveKYC({
      id: kycId,
      status: 'In Progress',
      kycLastModifiedBy: userEmail,
    });

    dispatch(addKyc(kyc));
  } catch (error) {
    await errorHandler(dispatch, 'Error while unlocking KYC')(error);
  }
};

export const updateKYC =
  (kyc: Partial<KYC>) =>
  async (dispatch: AppThunkDispatch): Promise<void> => {
    try {
      const newKyc = await saveKYC(kyc);
      dispatch(addKyc(newKyc));
    } catch (e) {
      await errorHandler(dispatch)(e);
    }
  };

export const complianceApproval = (kyc: KYC, email: string) => async (dispatch: AppThunkDispatch): Promise<void> => {
  return complianceApprovalApi(kyc.id!)
    .then(() => dispatch(addKyc({
      ...kyc,
      isComplianceApproved: 1,
      complianceApprovedBy: email,
      complianceApprovalDate: moment().format(dateFormat)
    })))
    .catch(errorHandler(dispatch, 'Failed to update compliance approval.'));
}

let loading = false;
export const loadHouseholdKYC = (memberIds: Array<string>) => async (dispatch: AppThunkDispatch, getState): Promise<void> => {
  const existingQIDs = Object.keys(getState().KYC);
  if (loading || memberIds.every(qid => existingQIDs.includes(qid))) {
    return Promise.reject();
  }
  loading = true;
  try {
    const kycMap = await fetchKycWithQIDs(memberIds);
    dispatch(setKycMap(kycMap));
  } catch (e) {
    await errorHandler(dispatch, 'KYC load failed')(e);
  } finally {
    loading = false;
  }
};

export const generateKycPdf =
  (memberQID: string) =>
  async (dispatch: Dispatch): Promise<string> => {
    try {
      const response = await axios.post(`${REACT_APP_QWEALTH_API}/pdf/annualReview/${memberQID}`);
      return response.data || '';
    } catch (e) {
      await errorHandler(dispatch, 'API Error: Failed Generating KYC PDF file')(e);
      return '';
    }
  };

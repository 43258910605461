import { Dispatch } from 'redux';
import { createAction } from '@reduxjs/toolkit';
import {
  deleteRelatedContact,
  fetchRelatedContactsWithQID,
  RelatedContact
} from '@qwealth/qdata';
import { errorHandler } from 'services/axiosService';
import { AppThunkDispatch } from '../store';
import { RelatedContactForm, saveRelatedContact } from './relatedContact';
import { saveNewRelatedContact } from './contacts';
import { RelationshipFormWithNewContactModel } from '../models/relatedContact';


const PUT_HOUSEHOLD_RELATED_CONTACTS = 'PUT_HOUSEHOLD_RELATED_CONTACTS' as const;
const REMOVE_HOUSEHOLD_RELATED_CONTACT = 'REMOVE_HOUSEHOLD_RELATED_CONTACT' as const;

export const putHouseholdRelatedContacts = createAction<RelatedContact[]>(PUT_HOUSEHOLD_RELATED_CONTACTS);
export const removeHouseholdRelatedContact = createAction<number>(REMOVE_HOUSEHOLD_RELATED_CONTACT);

export const loadAllRelatedContacts = (QIDs: Array<string>) => async (dispatch: AppThunkDispatch): Promise<void> =>
  await fetchRelatedContactsWithQID(QIDs)
    .then(result => result.data)
    .then(relatedContacts => dispatch(putHouseholdRelatedContacts(relatedContacts)))
    .catch(errorHandler(dispatch, 'Related Contacts loading failed'));

export const addRelated = (form: RelationshipFormWithNewContactModel) => async (dispatch: Dispatch): Promise<RelatedContact[]> => {
  try {
    let savedRelatedContacts: RelatedContact[] = [];
    if (form.isNewContact) {
      savedRelatedContacts = await saveNewRelatedContact(form);
    } else {
      savedRelatedContacts = await saveRelatedContact(form as RelatedContactForm);
      dispatch(putHouseholdRelatedContacts(savedRelatedContacts));
    }

    return Promise.resolve(savedRelatedContacts);
  } catch (e) {
    await errorHandler(dispatch, 'Failed to save related contact')(e);
    return Promise.reject(e);
  }
};

export const deleteRelated = (id: number) => async (dispatch: AppThunkDispatch): Promise<void> => {
  try {
    await deleteRelatedContact(id);
    dispatch(removeHouseholdRelatedContact(id));
  } catch (e) {
    await errorHandler(dispatch, 'Failed to delete related contact')(e);
  }
};

import axios from 'axios';
import { Dispatch } from 'redux';
import { REACT_APP_QWEALTH_QC_API } from '@qwealth/qdata';
import { ICognitoUser } from '../models/CognitoUser';
import { PortalMap } from '../models/portalAccess';
import { errorHandler } from '../../services/axiosService';
import { loadPortalAccessList } from '../actions/householdPortalAccess';

export const getCognitoUser = async (email: string): Promise<ICognitoUser> => {
  try {
    return await axios
      .get(`${REACT_APP_QWEALTH_QC_API}/qcentral/users/${email}`)
      .then(response => response.data as ICognitoUser);
  } catch (err) {
    console.error(err);
    return {} as ICognitoUser;
  }
};

export const createCognitoUser =
  (QID: string, primaryEmail: string, householdId: string) =>
  (dispatch: Dispatch): Promise<any> =>
    axios
      .post(`${REACT_APP_QWEALTH_QC_API}/qcentral/users`, {
        accessibleQIDs: [QID, householdId],
        QID,
        householdQID: householdId,
        email: primaryEmail,
      })
      .then(() => {
        // @ts-ignore
        dispatch(loadPortalAccessList([primaryEmail]));
      })
      .catch(
        errorHandler(dispatch, 'Could not create cognito user. Please contact technical support.'),
      );

export const saveCognitoUser =
  (cognitoUser: ICognitoUser) =>
  async (dispatch: Dispatch): Promise<ICognitoUser | null> => {
    try {
      return await axios
        .patch(`${REACT_APP_QWEALTH_QC_API}/qcentral/users/${cognitoUser.email}`, cognitoUser)
        .then(response => response.data as ICognitoUser);
    } catch (err) {
      errorHandler(dispatch, 'Could not save cognito user')(err);
      return null;
    }
  };

export const toggleCognitoUser =
  (email: string, disableFlag: boolean) =>
  (dispatch: Dispatch): Promise<ICognitoUser> => {
    const params = disableFlag ? { disableUser: true } : { enableUser: true };
    return axios
      .put(`${REACT_APP_QWEALTH_QC_API}/qcentral/users/${email}`, null, {
        params,
      })
      .then(response => response.data as ICognitoUser)
      .catch(errorHandler(dispatch, `Could not ${disableFlag ? 'disable' : 'enable'} user.`));
  };

export const resetCognitoPassword =
  (email: string) =>
  (dispatch: Dispatch): Promise<ICognitoUser> =>
    axios
      .put(`${REACT_APP_QWEALTH_QC_API}/qcentral/users/${email}`, null, {
        params: { resetPassword: true },
      })
      .then(response => ({ ...response.data, resetPasswordFlag: true } as ICognitoUser))
      .catch(errorHandler(dispatch, 'Could not reset password'));

export const getPortalMap = async (emails: Array<string>): Promise<PortalMap> =>
  await Promise.all(emails.filter(email => !!email).map(email => getCognitoUser(email))).then(
    cognitoUsers => {
      const map: Record<string, ICognitoUser> = {};
      cognitoUsers.forEach(user => {
        if (user && user.email) {
          map[user.email.toLowerCase()] = user;
        }
      });
      return map;
    },
  );

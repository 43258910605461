import { createReducer } from '@reduxjs/toolkit';
import { RelatedContact } from '@qwealth/qdata';
import { putHouseholdRelatedContacts, removeHouseholdRelatedContact } from '../actions/householdRelatedContacts';

export default createReducer([] as RelatedContact[], (builder) => {
  builder.addCase(putHouseholdRelatedContacts, (state, action) => {
    const contacts: RelatedContact[] = action.payload;

    const updatedContacts: RelatedContact[] = [...state];
    const existingUuids = updatedContacts.map(c => c.uuid);
    contacts.forEach(contact => {
      if (!existingUuids.includes(contact.uuid)) {
        updatedContacts.push(contact);
        existingUuids.push(contact.uuid);
      }
    })
    return updatedContacts;
  });

  builder.addCase(removeHouseholdRelatedContact, (state, action) => {
    const relatedId = action.payload;
    const index = state.findIndex(({ id }) => id === relatedId);

    if (index > -1) {
      return [...state.slice(0, index), ...state.slice(index + 1)];
    }
    return state;
  });
});

import * as Yup from 'yup';
import { ValidationErrors } from 'final-form';
import { yupValidateSync } from '@qwealth/qdata';
import { required } from '@qwealth/kyc';
import { CreateHouseholdForm } from '../models/CreateHouseholdForm';

export const validateHouseholdName = (val?: string): string | undefined => {
  const ValidationSchema = Yup.string().required('Household name is required');
  return yupValidateSync(val, ValidationSchema);
};

export const createHouseholdValidation = ({ clientType, householdName, iaCode, insuranceCode, partnerID }: CreateHouseholdForm): ValidationErrors => {
    const requireInsuranceCode = ['Insurance', 'Both'].includes(clientType ?? '');
    const requireIaCode = ['Investment', 'Both'].includes(clientType ?? '');
    return {
      householdName: required(householdName, 'Household Name'),
      iaCode: requireIaCode ? required(iaCode, 'IA Code') : undefined,
      insuranceCode: requireInsuranceCode ? required(insuranceCode, 'Insurance Code') : undefined,
      partnerID: partnerID === -1 ? 'Partner is required' : undefined,
      clientType: required(clientType, 'Client Type')
    };
};

import { setReportLoading, setReportData } from '../actions/reports';
import { createReducer } from '@reduxjs/toolkit';
import {
  ReportDataModel,
  ReportFlag,
  ReportFlagModel,
  ReportFlags,
  ReportName,
  ReportNames
} from '../models/reports/main';

interface IReportState extends ReportFlagModel, ReportDataModel {
  initialized: boolean;
}

const buildInitialReportState = (): IReportState => {
  const initialReportFlags: ReportFlagModel = <ReportFlagModel>ReportFlags
    .map((flag: ReportFlag) => ({ [flag]: false }))
    .reduce((obj, current) => Object.assign({}, obj, current), {});

  const initialReportData: ReportDataModel = <ReportDataModel>{};
  ReportNames.forEach((name: ReportName) => {
    initialReportData[name] = [];
  });

  return {
    initialized: false,
    ...initialReportData,
    ...initialReportFlags,
  };
}
const initialState: IReportState = buildInitialReportState();

export default createReducer(initialState, (builder) => {
  builder.addCase(setReportLoading, (state, action) => {
    const { reportFlag, isLoading } = action.payload;
    return { ...state, [reportFlag]: isLoading };
  });

  builder.addCase(setReportData, (state, action) => {
    const { reportName, data } = action.payload;
    return { ...state, [reportName]: data };
  });
});

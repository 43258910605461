import { AnyAction } from 'redux';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { configureStore } from '@reduxjs/toolkit';
import rootReducer from './reducers';
import { WebSocketService } from 'services/WebSocketService';

const store = configureStore({
  reducer: rootReducer,
  devTools: process.env.REACT_APP_ENV !== 'production'
});

WebSocketService.setReduxStore(store);

// @ts-ignore
window.store = store; // TODO: for debugging. remove in production

// Infer the `RootState` type from the store itself
export type RootState = ReturnType<typeof store.getState>;
export type AppStore = ReturnType<typeof configureStore>
export type AppThunkDispatch = ThunkDispatch<RootState, any, AnyAction>;
export type AppThunkAction<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  AnyAction
>;

export const useAppDispatch: () => AppThunkDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export default store;

import { createAction } from '@reduxjs/toolkit';
import { getPartnerShortCode } from '@qwealth/qcore';

import {
  generateLog,
  generateTask,
  getAllTasks,
  getAllLogs,
  updateLog,
  updateTask,
  deleteTask,
  QLog,
  QTask,
  TASK_IN_PROGRESS,
  TASK_COMPLETED,
  QMessageMetadata,
} from '@qwealth/qdata';
import { getPathContents } from '@qwealth/qvault';
import { Activity } from '../models/Activity';
import {
  CalendarEvent,
  generateCalendarEvent,
  updateCalendarEvent,
  updateHouseholdCalendarEvent,
} from 'data/data-layer/event';
import { errorHandler } from 'services/axiosService';
import fetchFilteredEvents from './microsoft/helper';
import { getCallLogFilter, getMessageFilter, getTaskFilter, sortActivitiesByDate } from 'utils/activityFilterHelper';
import { HouseholdActivityFilters } from 'data/models/ActivityFilters';
import { fromS3ItemToMetadata } from '../models/QMessageMetadata';
import { AppThunkDispatch } from '../store';
import { deleteTaskAttachments } from './activities';

const PUT_HOUSEHOLD_ACTIVITY = 'PUT_HOUSEHOLD_ACTIVITY';
const PUT_HOUSEHOLD_ACTIVITIES = 'PUT_HOUSEHOLD_ACTIVITIES';
const DELETE_HOUSEHOLD_ACTIVITY = 'DELETE_HOUSEHOLD_ACTIVITY';
const RESET_HOUSEHOLD_ACTIVITIES = 'RESET_HOUSEHOLD_ACTIVITIES';
const SET_ACTIVITY_LOADING = 'SET_ACTIVITY_LOADING';

type ActivityId = {
  id?: number | string;
}
export const deleteHouseholdActivity = createAction<ActivityId>(DELETE_HOUSEHOLD_ACTIVITY);
export const resetHouseholdActivities = createAction(RESET_HOUSEHOLD_ACTIVITIES);
export const putActivity = createAction<Activity>(PUT_HOUSEHOLD_ACTIVITY);
export const putActivities = createAction<Activity[]>(PUT_HOUSEHOLD_ACTIVITIES);
export const setLoading = createAction<boolean>(SET_ACTIVITY_LOADING);

const getEmails = (activityFilters: HouseholdActivityFilters): Promise<QMessageMetadata[]> => {
  const { householdQID } = activityFilters;
  const partnerShortCode = getPartnerShortCode(householdQID);
  const emailsPath = `${partnerShortCode}/${householdQID}/Internal/Emails/`;
  return getPathContents(emailsPath, true)
    .then(fileList => fileList.map(fromS3ItemToMetadata).filter(p => p.id !== emailsPath))
    .then(getMessageFilter(activityFilters))
    .catch((err) => {
      console.error(err);
      return [];
    });
}

export const loadActivities = (activityFilters: HouseholdActivityFilters) => async (dispatch: AppThunkDispatch): Promise<void> => {
  const { filterActivities } = activityFilters;
  dispatch(setLoading(true));
  dispatch(resetHouseholdActivities());

  const promises: Array<Promise<Array<Activity>>> = [];
  const hasEventType = filterActivities.includes('events');
  if (hasEventType && activityFilters.timeZone) {
    promises.push(fetchFilteredEvents(activityFilters));
  }

  const hasTaskType = filterActivities.includes('tasks');
  if (hasTaskType) {
    promises.push(getAllTasks(getTaskFilter(activityFilters)))
  }

  const hasLogType = filterActivities.includes('logs');
  if (hasLogType) {
    promises.push(getAllLogs(getCallLogFilter(activityFilters)));
  }

  const hasEmailType = filterActivities.includes('emails');
  if (hasEmailType) {
    promises.push(getEmails(activityFilters));
  }

  await Promise.all(promises)
    .then((results) => results.reduce((all, current) => all.concat(current), []))
    .then(activities => activities.sort(sortActivitiesByDate(activityFilters.sortDirection)))
    .then(all => dispatch(putActivities(all)))
    .finally(() => dispatch(setLoading(false)));
};

const createHouseholdTask = (task: QTask) => async (dispatch: AppThunkDispatch): Promise<QTask | null> => {
  dispatch(setLoading(true));

  try {
    const createdTask = await generateTask(task);
    dispatch(putActivity(createdTask));
    return createdTask;
  } catch (error) {
    await errorHandler(dispatch, 'Could not create task')(error);
    return null;
  } finally {
    dispatch(setLoading(false));
  }
};

const createHouseholdLog = (qLog: QLog) => async (dispatch: AppThunkDispatch): Promise<QLog | null> => {
  dispatch(setLoading(true));
  try {
    const createdLog = await generateLog(qLog);
    dispatch(putActivity(createdLog));

    return createdLog;
  } catch (error) {
    await errorHandler(dispatch, 'Could not create household log')(error);
    return null;
  } finally {
    dispatch(setLoading(false));
  }
};

export const saveHouseholdLog = (callLog: QLog) => async (dispatch: AppThunkDispatch): Promise<QLog | null> =>
  callLog.id ? await dispatch(updateHouseholdLog(callLog)) : await dispatch(createHouseholdLog(callLog));

const updateHouseholdLog = (callLog: QLog) => async (dispatch: AppThunkDispatch): Promise<QLog | null> => {
  dispatch(setLoading(true));
  try {
    const updatedCallLog = await updateLog(callLog);
    dispatch(putActivity(updatedCallLog));
    return updatedCallLog;
  } catch (error) {
    await errorHandler(dispatch, 'Failed to update household log')(error);
    return null;
  } finally {
    dispatch(setLoading(false));
  }
};

export const completeHouseholdTask = (task: QTask) => async (dispatch: AppThunkDispatch): Promise<void> => {
  dispatch(setLoading(true));
  const completedTask: QTask = { ...task, status: TASK_COMPLETED };

  try {
    const updatedTask = await updateTask(completedTask);
    dispatch(putActivity(updatedTask));
  } catch (error) {
    await errorHandler(dispatch, 'Failed to complete household task')(error);
  } finally {
    dispatch(setLoading(false));
  }
};

export const resetHouseholdTask = (task: QTask) => async (dispatch: AppThunkDispatch): Promise<void> => {
  dispatch(setLoading(true));
  const resetTask: QTask = { ...task, status: TASK_IN_PROGRESS };

  try {
    const updatedTask = await updateTask(resetTask);
    dispatch(putActivity(updatedTask));
  } catch (error) {
    await errorHandler(dispatch, 'Failed to reset household task')(error);
  } finally {
    dispatch(setLoading(false));
  }
};

export const saveHouseholdTask = (task: QTask) => async (dispatch: AppThunkDispatch): Promise<QTask | null> =>
  task.id ? await dispatch(updateHouseholdTask(task)) : await dispatch(createHouseholdTask(task))

const updateHouseholdTask = (task: QTask) => async (dispatch: AppThunkDispatch): Promise<QTask | null> => {
  dispatch(setLoading(true));
  try {
    const updatedTask = await updateTask(task);
    dispatch(putActivity(updatedTask));
    return updatedTask;
  } catch (error) {
    await errorHandler(dispatch, 'Failed to update household task')(error);
    return null;
  } finally {
    dispatch(setLoading(false));
  }
};

export const deleteHouseholdTask = (task: QTask) => async (dispatch: AppThunkDispatch): Promise<void> => {
  dispatch(setLoading(true));
  try {
    await deleteTask(task);
    dispatch(deleteHouseholdActivity(task));
    await deleteTaskAttachments(task);
  } catch (error) {
    await errorHandler(dispatch, 'Failed to deleted household task')(error);
  } finally {
    dispatch(setLoading(false));
  }
};

export const createHouseholdEvent = (event, householdId: string, fromCalendarId) => async (dispatch: AppThunkDispatch): Promise<void> => {
  dispatch(setLoading(true));

  try {
    const postedEvent = await generateCalendarEvent(event, fromCalendarId);
    const update = {
      singleValueExtendedProperties: [
        {
          id: 'String {00020329-0000-0000-C000-000000000046} Name HouseholdId',
          value: householdId,
        },
      ],
    };

    await updateHouseholdCalendarEvent(update, postedEvent.id!);

    dispatch(putActivity(postedEvent));
  } catch (error) {
    await errorHandler(dispatch)(error);
  } finally {
    dispatch(setLoading(false));
  }
};

export const updateHouseholdEvent = event => async (dispatch: AppThunkDispatch): Promise<CalendarEvent | null> => {
  dispatch(setLoading(true));

  try {
    const patchedEvent = await updateCalendarEvent(event, event.id);
    dispatch(putActivity(patchedEvent));
    return patchedEvent;
  } catch (error) {
    await errorHandler(dispatch)(error);
    return null;
  } finally {
    dispatch(setLoading(false));
  }
};

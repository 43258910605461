import { createAction } from '@reduxjs/toolkit';
import {
  createQBias,
  fetchHouseholdQBiasWithQID,
  QBias,
} from '@qwealth/qdata';
import { errorHandler } from '../../services/axiosService';
import { AppThunkDispatch } from '../store';

const PUT_HOUSEHOLD_QBIAS = 'PUT_HOUSEHOLD_QBIAS';
export const putHouseholdQbias = createAction<QBias[]>(PUT_HOUSEHOLD_QBIAS);

export const addQBias =
  (data: QBias) =>
  async (dispatch: AppThunkDispatch): Promise<void> => {
    try {
      await createQBias(data);
    } catch (e) {
      await errorHandler(dispatch, 'Could not create qbias')(e);
    }
  };

export const getQBias =
  (qId: string) =>
  async (dispatch: AppThunkDispatch): Promise<void> => {
    try {
      const qBiasList = await fetchHouseholdQBiasWithQID(qId);
      dispatch(putHouseholdQbias(qBiasList));
    } catch (e) {
      await errorHandler(dispatch)(e);
    }
  };

import React, { ReactElement, useState } from 'react';
import { Box, Button, Modal } from '@qwealth/qcore';
import { BsTrash } from 'react-icons/bs';

interface Props {
  actionHandler: () => void;
  actionLabel?: string;
  actionTitle?: string;
  actionDisabled? : boolean;
  Icon?: ReactElement;
  target?: string;
  body?: string | React.ReactElement;
  ActionButtonRenderer?: (openHandler: () => void) => React.ReactElement | null;
  buttonProps?: any;
  onCloseHandler?: () => void;
  size?: 'xl' | 'lg';
}

/**
 * @deprecated The component is deprecated and we should be using the one in qcore
 */
const ActionConfirmation: React.FC<Props> = ({
  target,
  actionHandler,
  actionLabel,
  actionDisabled,
  actionTitle,
  body,
  Icon,
  ActionButtonRenderer,
  buttonProps,
  onCloseHandler,
  size
}) => {
  const [showConfirmation, setShowConfirmation] = useState(false);
  const onClose = () => {
    onCloseHandler && onCloseHandler();
    setShowConfirmation(false);
  }

  return (
    <>
      {ActionButtonRenderer ? (
        ActionButtonRenderer(() => setShowConfirmation(true))
      ) : (
        <Button
          id="confirmationButton"
          variant="icon"
          backgroundColor="transparent"
          onClick={() => setShowConfirmation(true)}
          {...buttonProps}
        >
          {Icon || <BsTrash />}
        </Button>
      )}
      <Modal title={actionTitle || 'Delete Confirmation'} onClose={onClose} show={showConfirmation} size={size}>
        <Modal.Footer>
          <Modal.Body>
            <p>{body ?? `Are you sure you want to ${target}?`}</p>
          </Modal.Body>
          <Box flexDirection="row" display="flex" gap="large">
            <Button
              onClick={() => {
                actionHandler();
                onClose();
              }}
              disabled={actionDisabled}
            >
              {actionLabel || 'Delete'}
            </Button>
            <Button variant="outline" onClick={onClose}>
              Cancel
            </Button>
          </Box>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ActionConfirmation;

import { findIana } from 'windows-iana';
import moment from 'moment';
import { QLog, QMessageMetadata, QTask } from '@qwealth/qdata';
import { DateRange } from '../data/models/DateRange';
import { ActivityFilters } from '../data/models/ActivityFilters';
import { CalendarEvent } from '../data/data-layer/event';
import { Activity } from '../data/models/Activity';
import { ALL_DATE_RANGE } from 'data/refData/acitivites';
import { callLogSearch, emailSearch, eventSearch, qtaskSearch } from './activityFilterHelper';

export const getDateRange = (timeZone: string, value: string): DateRange => {
  const [ianaTimeZone] = findIana(timeZone);

  const fromDate = moment.tz(ianaTimeZone.valueOf()).startOf('day');
  const toDate = moment.tz(ianaTimeZone.valueOf());

  switch (value) {
    case ALL_DATE_RANGE:
      fromDate.subtract(2, 'years').startOf('day');
      toDate.add(2, 'years').endOf('day');
      break;
    case 'all_past':
      fromDate.subtract(2, 'years').startOf('day');
      toDate.subtract(1, 'day').endOf('day');
      break;
    case 'this_week':
      fromDate.startOf('week');
      toDate.endOf('week');
      break;
    case 'all_upcoming':
      toDate.add(2, 'years').endOf('day');
      break;
    default:
      break;
  }

  return { fromDate, toDate };
};

export const filterActivities = (activities: Array<Activity>, { filterActivities, filterQuery}: ActivityFilters): Array<Activity> =>
  activities.filter(activity => {
    if ('taskType' in activity) {
      return filterActivities.includes('tasks') && qtaskSearch(filterQuery, activity as QTask);
    } else if ('callDate' in activity) {
      return filterActivities.includes('logs') && callLogSearch(filterQuery, activity as QLog);
    } else if ('to' in activity) {
      return filterActivities.includes('emails') && emailSearch(filterQuery, activity as QMessageMetadata);
    } else if ('attendees' in activity) {
      return filterActivities.includes('events') && eventSearch(filterQuery, activity as CalendarEvent);
    }
    return false;
  });

import { createReducer } from '@reduxjs/toolkit';
import { updateItemAndGetArrayImmutably } from '@qwealth/qcore';
import { Activity } from '../models/Activity';
import {
  setLoading,
  putActivity,
  putActivities,
  deleteHouseholdActivity,
  resetHouseholdActivities,
} from '../actions/householdActivities';

interface State {
  isLoading: boolean;
  all: Array<Activity>;
}

const initialState: State = {
  all: [],
  isLoading: false,
};

export default createReducer(initialState, (builder) => {
  builder.addCase(setLoading, (state, action) => ({...state, isLoading: action.payload}));
  
  builder.addCase(putActivity, (state, action) => {
    const activity = action.payload;
    const all = updateItemAndGetArrayImmutably(
      state.all,
      u => u.id === activity.id,
      targetItem => (targetItem ? { ...targetItem, ...activity } : activity),
    );
    return { ...state, all };
  });

  builder.addCase(putActivities, (state, action) => ({...state, all: action.payload}));

  builder.addCase(deleteHouseholdActivity, (state, action) => ({
    ...state,
    all: state.all.filter(({id}) => id !== action.payload.id)
  }));

  builder.addCase(resetHouseholdActivities, (state) => ({...state, all: []}));
});

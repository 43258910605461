import { Action } from 'redux';
import { Case } from '@qwealth/qdata';
import { ActivePage, CaseCreatePageForm, CaseItem, PageStatus } from '../../reducers/cases/state';

export const UPDATE_CASES_LIST_PAGE = 'UPDATE_CASES_LIST_PAGE';
export const UPDATE_CASES_LIST_PAGE_STATUS = 'UPDATE_CASES_LIST_PAGE_STATUS';
export const UPDATE_ACTIVE_PAGE = 'UPDATE_ACTIVE_PAGE';
export const UPDATE_CREATE_CASE_FORM_FIELD = 'UPDATE_CREATE_CASE_FORM_FIELD';
export const UPDATE_CREATE_CASE_PAGE_STATUS = 'UPDATE_CREATE_CASE_PAGE_STATUS';
export const UPDATE_CASE_DETAIL_PAGE = 'UPDATE_CASE_DETAIL_PAGE';
export const UPDATE_CASE_DETAIL_FORM_FIELD = 'UPDATE_CASE_DETAIL_FORM_FIELD';
export const UPDATE_CASE_DETAIL_PAGE_STATUS = 'UPDATE_CASE_DETAIL_PAGE_STATUS';
export const ADD_MISSING_CASE_TO_LIST = 'ADD_MISSING_CASE_TO_LIST';
export const RESET_STATE = 'RESET_STATE';

export type ResetStateAction = Action<typeof RESET_STATE>;

export interface UpdateCasesListAction extends Action<typeof UPDATE_CASES_LIST_PAGE> {
  cases: Array<CaseItem>;
  hasNextPage: boolean;
  pageNo: number;
}

export interface UpdateCaseDetailAction extends Action<typeof UPDATE_CASE_DETAIL_PAGE> {
  form: Partial<CaseCreatePageForm>;
}

export interface UpdateCasesListPageStatusAction
  extends Action<typeof UPDATE_CASES_LIST_PAGE_STATUS> {
  status: PageStatus;
}

export interface UpdateCreateCasePageStatusAction
  extends Action<typeof UPDATE_CREATE_CASE_PAGE_STATUS> {
  status: PageStatus;
}

export interface UpdateCaseDetailPageStatusAction
  extends Action<typeof UPDATE_CASE_DETAIL_PAGE_STATUS> {
  status: PageStatus;
}

export interface AddMissingCaseToListAction extends Action<typeof ADD_MISSING_CASE_TO_LIST> {
  ticket: Case;
}

export interface UpdateActivePageAction extends Action<typeof UPDATE_ACTIVE_PAGE> {
  activePage: ActivePage;
}

export interface UpdateCreateCaseFormFieldAction
  extends Action<typeof UPDATE_CREATE_CASE_FORM_FIELD> {
  field: keyof CaseCreatePageForm;
  value: string | number;
}
export interface UpdateCaseDetailFormFieldAction
  extends Action<typeof UPDATE_CASE_DETAIL_FORM_FIELD> {
  field: keyof CaseCreatePageForm;
  value: string | number;
}

import axios from 'axios';
import { Dispatch } from 'redux';
import { responseEntityMapper, responseListMapper } from '@qwealth/qcore';
import { OpeningAccount, IPersonDto } from '@qwealth/qdata';
import { errorHandler, REACT_APP_QWEALTH_API } from 'services/axiosService';

export const PUT_OPENING_ACCOUNTS = 'PUT_OPENING_ACCOUNTS';
export const REMOVE_OPENING_ACCOUNT = 'REMOVE_OPENING_ACCOUNT';

export const putOpeningAccounts = accounts => ({ type: PUT_OPENING_ACCOUNTS, accounts });

export const removeOpeningAccount = (id: number) => ({ type: REMOVE_OPENING_ACCOUNT, id });

export const loadOpeningAccounts = (householdQID: string) => (dispatch: Dispatch) => {
  return axios
    .get(`${REACT_APP_QWEALTH_API}/newAccount/hhQID/${householdQID}`)
    .then(response => responseListMapper<OpeningAccount>(response))
    .then(openingAccounts => openingAccounts.map(account => {
      if (Array.isArray(account.payoutAccountDetails)) {
        if (account.payoutAccountDetails.length > 0) {
          account.payoutAccountDetails = account.payoutAccountDetails[0];
        } else {
          // @ts-ignore
          account.payoutAccountDetails = {};
        }
      }
      return account;
    }))
    .then((openingAccounts) => {
      dispatch(putOpeningAccounts(openingAccounts))
    })
    .catch(errorHandler(dispatch));
};

export const getContactRequest = (contactQID: string): Promise<IPersonDto> =>
  new Promise((resolve, reject) => {
    axios
      .get(`${REACT_APP_QWEALTH_API}/persons/${contactQID}`)
      .then(resp => responseEntityMapper<IPersonDto>(resp))
      .then(contact => resolve(contact))
      .catch(e => reject(e));
  });

export const deleteOpeningAccount = (id: number) => dispatch =>
  axios
    .delete(`${REACT_APP_QWEALTH_API}/newAccount/${id}`)
    .then(() => dispatch(removeOpeningAccount(id)))
    .catch(errorHandler(dispatch, 'Could not delete opening account. Please contact support.'));

export const deleteRelinquishingAccount = (id: number) => dispatch => {
  axios
    .delete(`${REACT_APP_QWEALTH_API}/relinquishingAccount/${id}`)
    .catch(errorHandler(dispatch, 'Could not delete relinquishing account. Please contact support.'));
}

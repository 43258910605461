import { addPortalAccess } from '../actions/householdPortalAccess';
import { PortalMap } from '../models/portalAccess';
import { createReducer } from '@reduxjs/toolkit';

const reducer = createReducer({} as PortalMap, (builder) => {
  builder.addCase(addPortalAccess, (state, action) =>
    ({ ...state, ...action.payload }));
});

export default reducer;

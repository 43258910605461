import { Box, styled, themeGet } from '@qwealth/qcore';

const InputForm = styled(Box)`
  display: flex;
  flex-direction: column;
  margin-bottom: ${themeGet('space.large')};
`;

export const FIELD_SUBSCRIPTION = { value: true, touched: true, error: true };

export const SAVE = 'save';
export const DISABLE = 'disable';
export const DELETE = 'delete';

export default InputForm;

export const INVESTMENT_CLIENT_TYPE = 'Investment';
export const INSURANCE_CLIENT_TYPE = 'Insurance';
export const BOTH_CLIENT_TYPE = 'Both';

export const ClientTypes = [INVESTMENT_CLIENT_TYPE, INSURANCE_CLIENT_TYPE, BOTH_CLIENT_TYPE] as const;

export type ClientType = typeof ClientTypes[number];

export type CreateHouseholdForm = {
  partnerID: number;
  insuranceCode: string;
  iaCode: string;
  householdName: string;
  clientType?: ClientType;
}

import { Dispatch } from 'redux';
import * as Sentry from '@sentry/react';
import { putNotification } from '@qwealth/qdata';

export const { REACT_APP_QWEALTH_API, REACT_APP_QWEALTH_QC_API } = process.env;

const isIgnorableError = error => {
  // We can place any errors that can be silently ignored here
  return error === 'duplicate_request_intercepted';
};

export const errorHandler = (dispatch: Dispatch, errorText?: string) => (error?: any): Promise<any> => {
  Sentry.captureException(error);

  if (!isIgnorableError(error)) {
    console.error(error);

    const apiError = error?.response?.data?.message ?? error?.response?.data;

    return Promise.resolve(dispatch(putNotification(apiError || errorText || 'API Error. Please contact support', 'Error')));
  }
  console.warn(error);
  return Promise.reject(error);
};

import { deleteItemAndGetArrayImmutably } from '@qwealth/qcore';
import { FeesExclusion, defaultFeesExclusion } from '@qwealth/qdata';
import {
  PUT_ALL_FEES_EXCLUSIONS,
  PUT_ACTIVE_FEES_EXCLUSION,
  PUT_NEWLY_CREATED_FEES_EXCLUSION,
  RESET_FEES_EXCLUSION,
  REMOVE_FEES_EXCLUSION,
} from '../actions/feesExclusions';

interface IFeesExclusionsState {
  initialized: boolean;
  feesExclusionList: Array<FeesExclusion>;
  feesExclusion: FeesExclusion;
  newlyCreatedFeesExclusion: FeesExclusion | null;
}

const initialState: IFeesExclusionsState = {
  initialized: false,
  feesExclusionList: [],
  feesExclusion: defaultFeesExclusion,
  newlyCreatedFeesExclusion: null,
};

export default function (state = initialState, action): IFeesExclusionsState {
  switch (action.type) {
    case PUT_ALL_FEES_EXCLUSIONS: {
      return { ...state, initialized: true, feesExclusionList: action.feesExclusions };
    }
    case PUT_ACTIVE_FEES_EXCLUSION: {
      const { feesExclusion }: { feesExclusion: FeesExclusion } = action;
      return { ...state, feesExclusion };
    }
    case PUT_NEWLY_CREATED_FEES_EXCLUSION: {
      const { newlyCreatedFeesExclusion } = action;
      return { ...state, newlyCreatedFeesExclusion };
    }
    case RESET_FEES_EXCLUSION: {
      return {
        ...state,
        feesExclusion: defaultFeesExclusion,
        newlyCreatedFeesExclusion: null,
      };
    }
    case REMOVE_FEES_EXCLUSION: {
      const { id } = action;
      const feesExclusionList: Array<FeesExclusion> = deleteItemAndGetArrayImmutably(
        state.feesExclusionList,
        (s: FeesExclusion) => s.id === id,
      );
      return { ...state, feesExclusionList };
    }
    default:
      return state;
  }
}

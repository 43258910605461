import { Reducer } from 'redux';
import { reducerFactory } from '@qwealth/qdata';

import {
  ADD_MISSING_CASE_TO_LIST,
  RESET_STATE,
  UPDATE_ACTIVE_PAGE,
  UPDATE_CASE_DETAIL_PAGE,
  UPDATE_CASES_LIST_PAGE,
  UPDATE_CASES_LIST_PAGE_STATUS,
  UPDATE_CREATE_CASE_FORM_FIELD,
  UPDATE_CREATE_CASE_PAGE_STATUS,
  UPDATE_CASE_DETAIL_FORM_FIELD,
  UPDATE_CASE_DETAIL_PAGE_STATUS,
  UpdateActivePageAction,
  UpdateCasesListAction,
  UpdateCaseDetailAction,
  UpdateCasesListPageStatusAction,
  UpdateCreateCaseFormFieldAction,
  UpdateCreateCasePageStatusAction,
  UpdateCaseDetailFormFieldAction,
  UpdateCaseDetailPageStatusAction,
  ResetStateAction,
  AddMissingCaseToListAction,
} from 'data/actions/cases/actionTypes';
import { CaseCreatePageForm, CasesModalState, defaultState } from './state';
import {
  UpdateActivePageMutation,
  UpdateCasesListPageMutation,
  UpdateCasesListPageStatusMutation,
  UpdateCreateCaseFormFieldMutation,
  UpdateCreateCasePageStatusMutation,
  UpdateCaseDetailFormFieldMutation,
  UpdateCaseDetailPageStatusMutation,
  UpdateNewCasesMutation,
} from './mutations';
import { SUBCATEGORIES } from './constants';

const updateCasesListPage: Reducer<UpdateCasesListPageMutation, UpdateCasesListAction> = (
  state = defaultState,
  { cases, pageNo, hasNextPage },
) => {
  const tickets = pageNo === 1 ? cases : state.casesListPage.cases.concat(cases);

  return {
    ...state,
    casesListPage: {
      ...state.casesListPage,
      cases: tickets,
      hasNextPage,
      pageNo,
    },
  };
};

const updateCaseDetailPage: Reducer<UpdateCaseDetailFormFieldMutation, UpdateCaseDetailAction> = (
  state = defaultState,
  { form },
) => ({
  ...state,
  caseViewPage: {
    form,
  },
});

const addMissingCaseToListPage: Reducer<UpdateNewCasesMutation, AddMissingCaseToListAction> = (
  state = defaultState,
  { ticket },
) => ({
  ...state,
  casesListPage: {
    ...state.casesListPage,
    newCases: [ticket, ...state.casesListPage.newCases],
  },
});

const updateCasesListPageStatus: Reducer<
  UpdateCasesListPageStatusMutation,
  UpdateCasesListPageStatusAction
> = (state = defaultState, { status }) => ({
  ...state,
  casesListPage: {
    ...state.casesListPage,
    status,
  },
});

const updateCreateCasePageStatus: Reducer<
  UpdateCreateCasePageStatusMutation,
  UpdateCreateCasePageStatusAction
> = (state = defaultState, { status }) => ({
  ...state,
  caseCreatePage: {
    ...state.caseCreatePage,
    status,
  },
});

const updateCaseDetailPageStatus: Reducer<
  UpdateCaseDetailPageStatusMutation,
  UpdateCaseDetailPageStatusAction
> = (state = defaultState, { status }) => ({
  ...state,
  caseViewPage: {
    ...state.caseViewPage,
    status,
  },
});

const setActivePage: Reducer<UpdateActivePageMutation, UpdateActivePageAction> = (
  state = defaultState,
  { activePage },
) => {
  const pages: Partial<CasesModalState> = {};

  if (activePage === 'list') {
    pages.caseCreatePage = defaultState.caseCreatePage;
    pages.caseViewPage = defaultState.caseViewPage;
  }

  return {
    ...state,
    ...pages,
    activePage,
  };
};

const resetLocalState: Reducer<CasesModalState, ResetStateAction> = () => defaultState;

const setCreateCaseFormField: Reducer<
  UpdateCreateCaseFormFieldMutation,
  UpdateCreateCaseFormFieldAction
> = (state = defaultState, { field, value }) => {
  const sideEffects: Partial<CaseCreatePageForm> = {};

  if (field === 'category') {
    const [subcategory] = (SUBCATEGORIES[value] as Array<string>) || [];

    sideEffects.subcategory = subcategory;
  }

  if (field === 'files') {
    const stateCopy = { ...state };
    stateCopy.caseCreatePage.form = {
      ...state.caseCreatePage.form,
      // @ts-ignore
      files: [...state.caseCreatePage.form.files, ...value],
    };
    return stateCopy;
  }

  return {
    ...state,
    caseCreatePage: {
      ...state.caseCreatePage,
      form: {
        ...state.caseCreatePage.form,
        [field]: value,
        ...sideEffects,
      },
    },
  };
};

const setCaseDetailFormField: Reducer<
  UpdateCaseDetailFormFieldMutation,
  UpdateCaseDetailFormFieldAction
> = (state = defaultState, { field, value }) => {
  const sideEffects: Partial<CaseCreatePageForm> = {};

  if (field === 'category') {
    const [subcategory] = (SUBCATEGORIES[value] as Array<string>) || [];

    sideEffects.subcategory = subcategory;
  }

  // if (field === 'files') {
  //   const stateCopy = {...state};
  //   stateCopy.caseViewPage.form = {
  //     ...state.caseViewPage.form,
  //     // @ts-ignore
  //     files: [...state.caseViewPage.form.files, ...value],
  //   };
  //   return stateCopy;
  // }

  return {
    ...state,
    caseViewPage: {
      ...state.caseViewPage,
      form: {
        ...state.caseViewPage.form,
        [field]: value,
        ...sideEffects,
      },
    },
  };
};

export default reducerFactory(
  {
    [ADD_MISSING_CASE_TO_LIST]: addMissingCaseToListPage,
    [RESET_STATE]: resetLocalState,
    [UPDATE_ACTIVE_PAGE]: setActivePage,
    [UPDATE_CASE_DETAIL_PAGE]: updateCaseDetailPage,
    [UPDATE_CASES_LIST_PAGE]: updateCasesListPage,
    [UPDATE_CASES_LIST_PAGE_STATUS]: updateCasesListPageStatus,
    [UPDATE_CREATE_CASE_FORM_FIELD]: setCreateCaseFormField,
    [UPDATE_CASE_DETAIL_FORM_FIELD]: setCaseDetailFormField,
    [UPDATE_CREATE_CASE_PAGE_STATUS]: updateCreateCasePageStatus,
    [UPDATE_CASE_DETAIL_PAGE_STATUS]: updateCaseDetailPageStatus,
  },
  defaultState,
);

export function immutablySwapItems<T>(items: Array<T>, firstIndex: number, secondIndex: number, updatedText?: string): T[] {
  const results: Array<T> = [...items];
  const firstItem = items[firstIndex];
  const secondItem = { ...items[secondIndex] };
  if (updatedText) {
    // @ts-ignore
    secondItem.text = updatedText;
  }
  results[firstIndex] = secondItem;
  if (firstIndex !== secondIndex) {
    results[secondIndex] = firstItem;
  }
  return results;
}

export const isJsonString = (str): boolean => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
};

import axios from 'axios';
import { errorHandler, REACT_APP_QWEALTH_API } from 'services/axiosService';
import { responseEntityMapper } from '@qwealth/qcore';
import { Communication } from '../models/Communication';
import { AppThunkDispatch } from '../store';

export const getCommunicationByQID =
  (QID: string) =>
  (dispatch: AppThunkDispatch): Promise<Communication | undefined> =>
    axios
      .get(`${REACT_APP_QWEALTH_API}/persons/communication/${QID}`)
      .then(response => responseEntityMapper<Communication>(response))
      .catch(err => {
        errorHandler(dispatch, 'Could not get communication')(err);
        return undefined;
      });

export const saveCommunicationApi = (communication: Partial<Communication>): Promise<Communication> => {
  if (communication.id) {
    return axios
      .patch(`${REACT_APP_QWEALTH_API}/persons/communication/${communication.QID}`, communication)
      .then(response => responseEntityMapper<Communication>(response));
  }
  return axios
    .post(`${REACT_APP_QWEALTH_API}/persons/communication`, communication)
    .then(response => responseEntityMapper<Communication>(response));
};

import { Dispatch } from 'redux';
import moment from 'moment-timezone';
import { findIana } from 'windows-iana';
import { errorHandler } from 'services/axiosService';
import { findSharedCalendarId, getAccessibleCalendars } from 'services/microsoft/graphService';
import { CalendarEvent, fetchEvents, generateCalendarEvent, updateCalendarEvent } from 'data/data-layer/event';
import { JSDateRange } from '../../models/DateRange';

export const SET_CALENDAR_EVENTS_LOADING = 'SET_CALENDAR_EVENTS_LOADING';
export const PUT_EVENTS = 'PUT_EVENTS';
export const PUT_EVENT = 'PUT_EVENT';
export const DELETE_EVENT = 'DELETE_EVENT';

const setCalendarEventsLoading = loading => ({ type: SET_CALENDAR_EVENTS_LOADING, loading });
const putEvents = events => ({ type: PUT_EVENTS, events });
const putEvent = event => ({ type: PUT_EVENT, event });

const colors = ['#cc433c', '#79B94e', '#d39B48', '#5ec6c2'];

const toCalendarEvent = (event, color?: string) => ({
  data: event,
  id: event.id,
  title: event.subject.label,
  location: event.location?.displayName?.label || 'N/A',
  start: new Date(event.start.dateTime),
  end: new Date(event.end.dateTime),
  color,
  allDay: event.allDay,
});

export const loadCalendarEvents = (
  timeZone: string,
  { start, end }: JSDateRange,
  sharedCalendarArray: Array<string>
): (dispatch: Dispatch) => Promise<void> => {
  const timeZones = findIana(timeZone || 'Eastern Standard Time');
  const ianaTimeZone = timeZones[0];
  const startDateTime = moment(start).tz(ianaTimeZone).format();
  const endDateTime = moment(end).tz(ianaTimeZone).format();

  return async (dispatch: Dispatch) => {
    try {
      dispatch(setCalendarEventsLoading(true));
      dispatch(putEvents([]));

      let allEvents: Array<any> = [];
      const events = await fetchEvents(timeZone, startDateTime, endDateTime)
        .then(events => events.map(event => toCalendarEvent(event)))
        .catch(err => {
          errorHandler(dispatch, 'Could not load calendar events')(err);
          return [];
        });

      allEvents = allEvents.concat(events);

      if (sharedCalendarArray && sharedCalendarArray.length > 0) {
        const calendars = await getAccessibleCalendars();

        const colorMap = sharedCalendarArray.reduce((map, email) => {
          const calendarId = findSharedCalendarId(calendars, email);
          if (calendarId) {
            map[calendarId] = colors[sharedCalendarArray.indexOf(email)] || 'gray';
          }
          return map;
        }, {});

        const promises: Array<Promise<CalendarEvent[]>> = sharedCalendarArray.map(email => {
          const calendarId = findSharedCalendarId(calendars, email);
          if (calendarId) {
            return fetchEvents(timeZone, startDateTime, endDateTime, calendarId);
          }
          return Promise.resolve([]);
        });
        const events = await Promise.all(promises)
          .then(promises => promises.reduce((allEvents, events) => allEvents.concat(events), []))
          .then(events => events.map(event => toCalendarEvent(event, colorMap[event.calendarId ?? ''])))
          .catch(err => {
            errorHandler(dispatch, 'Could not load shared calendar')(err);
            return [];
          });

        allEvents = allEvents.concat(events);
      }
      dispatch(putEvents(allEvents));
    } catch (error) {
      errorHandler(dispatch, 'Failed to load calendar events')(error);
    } finally {
      dispatch(setCalendarEventsLoading(false));
    }
  };
};

export const createCalendarEvent = (event: CalendarEvent, calendarId?: string) => async (dispatch: Dispatch): Promise<void> => {
  try {
    const postedEvent = await generateCalendarEvent(event, calendarId);
    dispatch(
      putEvent({
        data: postedEvent,
        id: postedEvent.id,
        title: postedEvent.subject.label,
        start: postedEvent.start.dateTime.toDate(),
        end: postedEvent.end.dateTime.toDate(),
        allDay: postedEvent.allDay,
      }),
    );
  } catch (error) {
    errorHandler(dispatch, 'Could not create calendar event')(error);
  }
};

export const updateEvent = (event: CalendarEvent, calendarId?: string) => async (dispatch: Dispatch): Promise<void> => {
  try {
    const patchedEvent = await updateCalendarEvent(event, event.id!, calendarId);

    dispatch(
      putEvent({
        data: patchedEvent,
        id: patchedEvent.id,
        title: patchedEvent.subject.label,
        start: patchedEvent.start.dateTime.toDate(),
        end: patchedEvent.end.dateTime.toDate(),
        allDay: patchedEvent.allDay,
      }),
    );
  } catch (error) {
    errorHandler(dispatch)(error);
  }
};

export const createCalendarHouseholdEvent = (event, householdId: string, calendarId?: string) => async (dispatch: Dispatch): Promise<void> => {
  try {
    const postedEvent = await generateCalendarEvent(event, calendarId);

    const start = new Date(event.start.dateTime);
    const end = new Date(event.end.dateTime);

    dispatch(
      putEvent({
        data: postedEvent,
        id: postedEvent.id,
        title: postedEvent.subject.label,
        start,
        end,
        allDay: postedEvent.allDay,
      }),
    );
  } catch (error) {
    errorHandler(dispatch, 'Could not create calendar event')(error);
  }
};

export const deleteEvent = (id: string) => ({ type: DELETE_EVENT, id });

import axios from 'axios';
import moment from 'moment';
import { createAction } from '@reduxjs/toolkit';
import { responseEntityMapper, responseListMapper } from '@qwealth/qcore';
import { DocusignEnvelope, OpeningAccount, DocusignRegistration, dateFormat } from '@qwealth/qdata';
import { putOpeningAccount, saveOrUpdateAccountRequest } from '@qwealth/kyc';
import { errorHandler, REACT_APP_QWEALTH_API } from '../../services/axiosService';
import { removeOpeningAccount } from './openingAccounts';
import { AppThunkDispatch } from '../store';

const PUT_DOCUSIGN_ENVELOPES = 'PUT_DOCUSIGN_ENVELOPES';
const PUT_ACTIVE_DOCUSIGN_ENVELOPE = 'PUT_ACTIVE_DOCUSIGN_ENVELOPE';
const REMOVE_ACTIVE_DOCUSIGN_ENVELOPE = 'REMOVE_ACTIVE_DOCUSIGN_ENVELOPE';
const PUT_REGISTRATION_ON_ACTIVE_ENVELOPE = 'PUT_REGISTRATION_ON_ACTIVE_ENVELOPE';

const getErrorMessage = (e): string | undefined => e?.response?.data?.message ?? e?.response?.data;

export const putDocusignEnvelopes = createAction<DocusignEnvelope[]>(PUT_DOCUSIGN_ENVELOPES);
export const removeActiveDocusignEnvelope = createAction(REMOVE_ACTIVE_DOCUSIGN_ENVELOPE);
export const putActiveDocusignEnvelope = createAction<DocusignEnvelope>(
  PUT_ACTIVE_DOCUSIGN_ENVELOPE
);
export const putRegistrationOnActiveEnvelope = createAction<DocusignRegistration>(
  PUT_REGISTRATION_ON_ACTIVE_ENVELOPE
);

export const loadAllDocusignEnvelopes = () => (dispatch: AppThunkDispatch) =>
  axios
    .get(`${REACT_APP_QWEALTH_API}/docusign/envelopes`)
    .then((resp) => responseListMapper<DocusignEnvelope>(resp))
    .then((envelopes) => dispatch(putDocusignEnvelopes(envelopes)))
    .catch((e) => errorHandler(dispatch, 'Docusign envelope loading error')(e));

export const loadDocusignEnvelopeForHousehold =
  (householdQID: string) =>
    (dispatch: AppThunkDispatch): Promise<any> =>
      axios
        .get(`${REACT_APP_QWEALTH_API}/docusign/hhLatestEnvelope/${householdQID}`)
        .then((resp) => responseEntityMapper<DocusignEnvelope>(resp))
        .then((envelope) => {
          if (
            envelope &&
            ['Pending Approval', 'Active', 'Completed'].includes(envelope.envelopeStatus)
          ) {
            envelope.nbinRegistration = stripTimeFromRegistrationsNotes(envelope.nbinRegistration);
            dispatch(putActiveDocusignEnvelope(envelope));
          } else {
            dispatch(removeActiveDocusignEnvelope());
          }
        })
        .catch((e) => errorHandler(dispatch, 'API Error: Failed retrieving docusign envelope for household')(e));

export const sendDocusignEnvelopeForApproval =
  ({
     householdQID,
     userID,
     url,
     accounts,
     isAllFeeGroupAndSchedule
   }: {
    householdQID: string;
    userID: string;
    url: string;
    accounts: Array<OpeningAccount>;
    isAllFeeGroupAndSchedule: boolean;
  }) =>
    (dispatch: AppThunkDispatch) =>
      axios
        .post(`${REACT_APP_QWEALTH_API}/docusign/embedded/sending`, {
          QID: householdQID,
          return_url: url,
          approver: userID,
          status: 'sent',
          opening_accounts: accounts,
          is_all_fee_group_and_schedule: isAllFeeGroupAndSchedule
        }, { timeout: moment.duration(2, 'minutes').asMilliseconds() })
        .then(() => {
          const promises: Array<Promise<OpeningAccount>> = [
            dispatch(loadDocusignEnvelopeForHousehold(householdQID)),
            ...accounts.map((openingAccount) => {
              const accountData: OpeningAccount = { ...openingAccount, status: 'Pending Approval' };
              return dispatch(saveOrUpdateAccountRequest(accountData));
            })
          ];
          return Promise.all(promises);
        })
        .catch((e) => Promise.reject(getErrorMessage(e)));

export const cancelDocusignEnvelope =
  ({
     docusignEnvelopeId,
     accounts
   }: {
    docusignEnvelopeId: string;
    accounts: Array<OpeningAccount>;
  }) =>
    (dispatch: AppThunkDispatch) =>
      axios
        .post(`${REACT_APP_QWEALTH_API}/docusign/envelope/cancel`, {
          envelope_id: docusignEnvelopeId,
          envelopeStatus: 'Voided',
          reason: 'No reason just cancel the envelope for now'
        })
        .then((resp) => responseEntityMapper<DocusignEnvelope>(resp))
        .then((envelope) => dispatch(putActiveDocusignEnvelope(envelope)))
        .then(() => {
          const promises: Array<Promise<OpeningAccount>> = [
            ...accounts.map((openingAccount) => {
              const accountData: OpeningAccount = { ...openingAccount, status: 'Created' };
              dispatch(putOpeningAccount(accountData));
              return dispatch(saveOrUpdateAccountRequest(accountData));
            })
          ];
          return Promise.all(promises);
        })
        .catch((e) => Promise.reject(getErrorMessage(e)));

export const resendDocusignEnvelope =
  ({ docusignEnvelopeId }: { docusignEnvelopeId: string }) =>
    (dispatch: AppThunkDispatch) =>
      axios
        .post(`${REACT_APP_QWEALTH_API}/docusign/envelope/resend`, {
          envelope_id: docusignEnvelopeId
        })
        .then((resp) => responseEntityMapper<DocusignEnvelope>(resp))
        .then((envelope) => dispatch(putActiveDocusignEnvelope(envelope)))
        .catch((e) => Promise.reject(getErrorMessage(e)));

export const archiveDocusignEnvelope =
  ({ envelopeId, accounts }: { envelopeId: number; accounts: Array<OpeningAccount> }) =>
    (dispatch: AppThunkDispatch) =>
      axios
        .patch(`${REACT_APP_QWEALTH_API}/docusign/envelope/${envelopeId}`, {
          envelopeStatus: 'Archived'
        })
        .then((resp) => responseEntityMapper<DocusignEnvelope>(resp))
        .then(() => dispatch(removeActiveDocusignEnvelope()))
        .then(() => {
          const promises: Array<Promise<OpeningAccount>> = [
            ...accounts.map((openingAccount) => {
              const accountData: OpeningAccount = { ...openingAccount, status: 'Archived' };
              if (accountData.id) {
                dispatch(removeOpeningAccount(accountData.id));
              }

              return dispatch(saveOrUpdateAccountRequest(accountData));
            })
          ];
          return Promise.all(promises);
        })
        .catch((e) => Promise.reject(getErrorMessage(e)));

export const saveDocusignEnvelope =
  (envelope: Partial<DocusignEnvelope>) => (dispatch: AppThunkDispatch) => {
    const request = !envelope.id
      ? axios.post(`${REACT_APP_QWEALTH_API}/docusign/envelope/`, envelope)
      : axios.patch(`${REACT_APP_QWEALTH_API}/docusign/envelope/${envelope.id}`, envelope);
    return request
      .then((response) => responseEntityMapper<DocusignEnvelope>(response))
      .then((response) => {
        const newEnvelope = { ...envelope, ...response };
        dispatch(putActiveDocusignEnvelope(newEnvelope));
        return Promise.resolve(newEnvelope);
      })
      .catch((e) => {
        errorHandler(dispatch)(e);
        return Promise.reject(null);
      });
  };

export const saveDocusignRegistration =
  (registration: Partial<DocusignRegistration>) => (dispatch: AppThunkDispatch) => {
    const request = !registration.id
      ? axios.post(`${REACT_APP_QWEALTH_API}/nbinRegistration/`, registration)
      : axios.patch(`${REACT_APP_QWEALTH_API}/nbinRegistration/${registration.id}`, registration);
    return request
      .then((response) => responseEntityMapper<DocusignRegistration>(response))
      .then((newRegistration) => {
        newRegistration = stripTimeFromRegistrationNotes(newRegistration);
        dispatch(putRegistrationOnActiveEnvelope(newRegistration));
        return Promise.resolve(newRegistration);
      })
      .catch((e) => errorHandler(dispatch, 'API Error: Failed saving registration')(e));
  };

export const stripTimeFromRegistrationNotes = (registration: DocusignRegistration): DocusignRegistration => ({
  ...registration,
  nbinRegistrationNotes: registration.nbinRegistrationNotes.map(note => ({
    ...note,
    startTime: moment(note.startTime).format(dateFormat),
  })),
});
  
export const stripTimeFromRegistrationsNotes = (registrations: DocusignRegistration[]): DocusignRegistration[] =>
  registrations.map(registration => stripTimeFromRegistrationNotes(registration));

export const downloadDocusign2Forms = async (payload: Record<'newAccountUuids', string[]>) => {
  const config = {responseType: 'blob' as const};
  try {
    const response = await axios
      .post(`${REACT_APP_QWEALTH_API}/docusign2/download`, payload, config);
    return await response.data;
  } catch (e) {
    // @ts-ignore
    const errorText = await e?.response?.data?.text();
    return Promise.reject(errorText);
  }
}

export const sendUsingDocusign2 = async (payload: Record<'newAccountUuids', string[]>) => {
  try {
    const response = await axios
      .post(`${REACT_APP_QWEALTH_API}/pdf/imaD2`, payload);
    return response.data.docusign_envelope_ids;
  } catch (e) {
    return await Promise.reject(getErrorMessage(e));
  }
}

export const loadEnvelopeByDocusignEnvelopeId = (envelopeId: string) => async (dispatch: AppThunkDispatch) => {
  return axios
    .get(`${REACT_APP_QWEALTH_API}/docusign/envelopeByDocusignEnvelopeId/${envelopeId}`)
    .then((response) => responseEntityMapper<DocusignEnvelope>(response))
    .then((envelope) => {
      if (envelope && ['Pending Approval', 'Active', 'Completed'].includes(envelope.envelopeStatus)) {
        envelope.nbinRegistration = stripTimeFromRegistrationsNotes(envelope.nbinRegistration);
        dispatch(putActiveDocusignEnvelope(envelope));
      } else {
        dispatch(removeActiveDocusignEnvelope());
      }
    })
    .catch((e) => errorHandler(dispatch, 'API Error: Failed fetching envelope by docusignEnvelopeId')(e));
}

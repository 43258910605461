import { Dispatch } from 'redux';
import axios from 'axios';
import { omit } from 'lodash';
import { responseEntityMapper, responseListMapper } from '@qwealth/qcore';
import { ProspectFlow, ProspectStage, ProspectTask } from '@qwealth/qdata';
import { errorHandler, REACT_APP_QWEALTH_API } from 'services/axiosService';
import { AppThunkDispatch } from '../store';

export const PUT_ALL_PROSPECT_FLOWS = 'PUT_ALL_PROSPECT_FLOWS';
export const PUT_ACTIVE_PROSPECT_FLOW = 'PUT_ACTIVE_PROSPECT_FLOW';
export const PUT_NEWLY_CREATED_PROSPECT_FLOW = 'PUT_NEWLY_CREATED_PROSPECT_FLOW';
export const RESET_PROSPECT_FLOW = 'RESET_PROSPECT_FLOW';
export const PUT_STAGE_ON_ACTIVE_PROSPECT_FLOW = 'PUT_STAGE_ON_ACTIVE_PROSPECT_FLOW';
export const DELETE_STAGE_FROM_ACTIVE_PROSPECT_FLOW = 'DELETE_STAGE_FROM_ACTIVE_PROSPECT_FLOW';
export const PUT_TASK_ON_ACTIVE_PROSPECT_FLOW_STAGE = 'PUT_TASK_ON_ACTIVE_PROSPECT_FLOW_STAGE';
export const DELETE_TASK_FROM_ACTIVE_PROSPECT_FLOW_STAGE =
  'DELETE_TASK_FROM_ACTIVE_PROSPECT_FLOW_STAGE';

const putProspectFlows = prospectFlows => ({ type: PUT_ALL_PROSPECT_FLOWS, prospectFlows });
const putActiveProspectFlow = prospectFlow => ({ type: PUT_ACTIVE_PROSPECT_FLOW, prospectFlow });
const putStageOnActiveProspectFlow = prospectStage => ({
  type: PUT_STAGE_ON_ACTIVE_PROSPECT_FLOW,
  prospectStage,
});
const deleteStageFromActiveProspectFlow = id => ({
  type: DELETE_STAGE_FROM_ACTIVE_PROSPECT_FLOW,
  id,
});
const putTaskOnActiveProspectFlowStage = (prospectTask, prospectStageId) => ({
  type: PUT_TASK_ON_ACTIVE_PROSPECT_FLOW_STAGE,
  prospectTask,
  prospectStageId,
});
const deleteTaskFromActiveProspectFlowStage = (id, prospectStageId) => ({
  type: DELETE_TASK_FROM_ACTIVE_PROSPECT_FLOW_STAGE,
  id,
  prospectStageId,
});
export const putNewlyCreatedProspectFlow = newlyCreatedProspectFlow => ({
  type: PUT_NEWLY_CREATED_PROSPECT_FLOW,
  newlyCreatedProspectFlow,
});
export const resetProspectFlow = () => ({ type: RESET_PROSPECT_FLOW });

const fetchProspectFlows = (params: Record<string, number> = {}) =>
  new Promise((resolve, reject) => {
    axios
      .get(`${REACT_APP_QWEALTH_API}/defaultFlow`, { params })
      .then(resp => resolve(responseListMapper<ProspectFlow>(resp)))
      .catch(e => reject(e));
  });

export const loadProspectFlows =
  (params: Record<string, number> = {}) =>
  (dispatch: Dispatch) => {
    return fetchProspectFlows(params)
      .then(prospectFlows => dispatch(putProspectFlows(prospectFlows)))
      .catch(errorHandler(dispatch, 'Failed to load prospect flows'));
  };

const fetchSingleProspectFlow = (
  axiosInstance,
  prospectFlowId: string,
): Promise<ProspectFlow> => {
  return new Promise((resolve, reject) => {
    axiosInstance
      .get(`${REACT_APP_QWEALTH_API}/defaultFlow/${prospectFlowId}`)
      .then(resp => resolve(responseEntityMapper<ProspectFlow>(resp)))
      .catch(e => reject(e));
  });
};

export const loadSingleProspectFlow = (prospectFlowId: string) => (dispatch: Dispatch) =>
  fetchSingleProspectFlow(axios, prospectFlowId)
    .then(prospectFlow => {
      dispatch(putActiveProspectFlow(prospectFlow));
    })
    .catch(errorHandler(dispatch, 'Failed to load prospect flow'));

export const addProspectFlow = (prospectFlow: ProspectFlow) => dispatch => {
  const prospectFlowData = omit(prospectFlow, ['stage']);
  return axios
    .post(`${REACT_APP_QWEALTH_API}/defaultFlow`, prospectFlowData)
    .then(response => responseEntityMapper<ProspectFlow>(response))
    .then((response: ProspectFlow) => {
      dispatch(putNewlyCreatedProspectFlow(response));
      dispatch(putActiveProspectFlow(response));
      prospectFlow.stage &&
        prospectFlow.stage.forEach(prospectStage => {
          const newProspectStage = { ...prospectStage, prospectFlowID: response.id as number };
          dispatch(addProspectStage(newProspectStage));
        });
      return Promise.resolve(response);
    })
    .catch(e => {
      errorHandler(dispatch)(e);
      return Promise.reject(null);
    });
};

export const updateProspectFlow = (prospectFlow: ProspectFlow, id: string) => (dispatch: AppThunkDispatch) =>
  axios
    .patch(`${REACT_APP_QWEALTH_API}/defaultFlow/${id}`, prospectFlow)
    .then(responseEntityMapper)
    .then(prospectFlow => {
      dispatch(putActiveProspectFlow(prospectFlow));
    })
    .catch(errorHandler(dispatch, 'Failed to update prospect flow'));

export const addProspectStage = (prospectStage: ProspectStage) => (dispatch: AppThunkDispatch) => {
  const stageData = omit(prospectStage, ['id', 'task']);
  return axios
    .post(`${REACT_APP_QWEALTH_API}/defaultStage`, stageData)
    .then(response => responseEntityMapper<ProspectStage>(response))
    .then((response: ProspectStage) => {
      dispatch(putStageOnActiveProspectFlow(response));
      prospectStage.task &&
        prospectStage.task.forEach(prospectTask => {
          dispatch(addProspectTask(prospectTask, response.id as number));
        });
      return Promise.resolve(response);
    })
    .catch(e => {
      errorHandler(dispatch)(e);
      return Promise.reject(null);
    });
};

export const updateProspectStage = (prospectStage: ProspectStage, id: number) => (dispatch: AppThunkDispatch) =>
  axios
    .patch(`${REACT_APP_QWEALTH_API}/defaultStage/${id}`, prospectStage)
    .then(responseEntityMapper)
    .then(prospectStage => {
      dispatch(putStageOnActiveProspectFlow(prospectStage));
    })
    .catch(errorHandler(dispatch, 'Failed to update prospect stage'));

export const deleteProspectStage = (id: number) => dispatch =>
  axios
    .delete(`${REACT_APP_QWEALTH_API}/defaultStage/${id}`)
    .then(() => dispatch(deleteStageFromActiveProspectFlow(id)))
    .catch(errorHandler(dispatch, 'Failed to delete prospect stage'));

export const addProspectTask =
  (prospectTask: ProspectTask, prospectStageId: number) => (dispatch: AppThunkDispatch) => {
    const taskData: ProspectTask = omit(prospectTask, ['id']);
    taskData.prospectStageID = prospectStageId;
    return axios
      .post(`${REACT_APP_QWEALTH_API}/defaultTask`, taskData)
      .then(response => responseEntityMapper<ProspectTask>(response))
      .then((prospectTask: ProspectTask) => {
        dispatch(putTaskOnActiveProspectFlowStage(prospectTask, prospectStageId));
        return Promise.resolve(prospectTask);
      })
      .catch(e => {
        errorHandler(dispatch)(e);
        return Promise.reject(null);
      });
  };

export const updateProspectTaskRequest = (prospectTask: ProspectTask, id: number) =>
  new Promise((resolve, reject) => {
    axios
      .patch(`${REACT_APP_QWEALTH_API}/defaultTask/${id}`, prospectTask)
      .then(resp => resolve(responseEntityMapper<ProspectTask>(resp)))
      .catch(e => reject(e));
  });

export const updateProspectTask =
  (prospectTask: ProspectTask, id: number, prospectStageId: number) => dispatch =>
    updateProspectTaskRequest(prospectTask, id)
      .then(prospectTask => {
        dispatch(putTaskOnActiveProspectFlowStage(prospectTask, prospectStageId));
      })
      .catch(errorHandler(dispatch, 'Failed to update prospect task'));

export const deleteProspectTask = (id: number, prospectStageId: number) => dispatch =>
  axios
    .delete(`${REACT_APP_QWEALTH_API}/defaultTask/${id}`)
    .then(() => dispatch(deleteTaskFromActiveProspectFlowStage(id, prospectStageId)))
    .catch(errorHandler(dispatch, 'Failed to delete prospect task'));

export const createPartnerCopy = (prospectFlow: ProspectFlow) => dispatch => {
  const prospectFlowData = omit(prospectFlow, ['id']);
  return dispatch(addProspectFlow(prospectFlowData));
};
